import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { getProject, getProjects, loadContainer, moveProject } from '../Store/Project';
import { ContextMenu } from '../Models/ContextMenu';
import { MoveBoardContextMenuParam } from'../Models/Requests/MoveBoardContextMenuParam';
import { AiOutlineClose } from "react-icons/ai";
//import { Card } from '../Models/Card';
//import { Label, EmptyLabel } from '../Models/Label';
//import { IColor, colors } from '../Models/Requests/Colors';
//import { FaRegEdit } from "react-icons/fa";
//import { IoIosArrowBack } from "react-icons/io";
import { MdClose } from "react-icons/md";
//import { LabelToCard } from '../Models/LabelToCard';
//import PopupContextMenu from './PopupContext';
//import { PopupContextParam } from '../Models/Requests/PopupContextParam';

interface Props {
    params: MoveBoardContextMenuParam | undefined;    
}

function MoveBoardContextMenu(props: Props) {

    const dispatch = useAppDispatch();
    const projectState = useAppSelector(getProject);
    const [containerId, setContainerId] = useState(0);
    const [moveBoardContextMenu, setMoveBoardContextMenu] = useState<ContextMenu>();
    //const [popupParams, setPopupParams] = useState<PopupContextParam>();
    //const [newLabelWindow, setNewLabelWindow] = useState<boolean>(false);
    //const [color, setColor] = useState<string>(C_COLOR);
    //const [font, setFont] = useState<string>(C_FONT);
    //const [title, setTitle] = useState<string>(C_TITLE);
    //const [labelBeingEdited, setLabelBeingEdited] = useState<number>(0);
    //const [labelSelected, setLabelSelected] = useState<Array<boolean>>([]);
    //const [searchText, setSearchText] = useState<string>("");
    //const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        if (projectState.status === "projectMoved") {
            /* reload projects */
            dispatch(getProjects());
            dispatch(loadContainer(containerId)); 
            closeContextMenu();
        }
    }, [projectState.status]);

    useEffect(() => {
        if (props.params) {
            let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
            if ((moveBoardContextMenu && moveBoardContextMenu.id !== props.params.projectId) || !moveBoardContextMenu) {

                //let menuWidth = window.outerWidth / 5;
                //let menuWidth = 300;
                //let menuHeight = window.outerHeight * .7;

                //let numberOfLabels = projectState.labels.length;
                //let menuHeight = 145 + 50 * numberOfLabels;

                let menuXStart = props.params.event.clientX + 15;
                let menuYStart = props.params.event.clientY - 15;
                //alert(menuXStart + " : " + menuWidth + " : " + window.outerWidth);
                if (menuXStart > window.outerWidth) {
                    menuXStart = window.outerWidth - 10;
                }
                /*
                if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }
                */
                contextMenu.id = props.params.projectId;
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                //contextMenu.width = menuWidth;
                //contextMenu.height = menuHeight;
            }
            setMoveBoardContextMenu(contextMenu);
           // refreshLabelSelections();

        }
    }, [props.params]);

    const closeContextMenu = (e?: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        setMoveBoardContextMenu(contextMenu);
    }

    const handleMoveProject = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (props.params && props.params.projectId && containerId > 0) {
            dispatch(moveProject({
                projectId: props.params.projectId,
                containerId: containerId
            }));
        }
    }

    const sContextMenu: React.CSSProperties = {
        top: (moveBoardContextMenu) ? moveBoardContextMenu.y : 0,
        left: (moveBoardContextMenu) ? moveBoardContextMenu.x : 0,
        //width: (moveBoardContextMenu) ? moveBoardContextMenu.width : 0,
        height: "auto",
        paddingBottom: "15px",
    }

    const loadedContainer = projectState.containers.find(c => c.loaded);
    const containers = projectState.containers.filter(c => c.id !== loadedContainer?.id).sort(
        (a,b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));

    const mappedContainers = containers.map((container, index) => {
        return (
            <option value={container.id}>{container.name}</option>
        );
    });

    const handleContainerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setContainerId(parseInt(e.currentTarget.value));
    }

    return (
        <>
        {moveBoardContextMenu && moveBoardContextMenu.id > 0 &&
            <div onClick={e => closeContextMenu(e)} className="popupContainer">
                <div className="contextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation()}}>
                    <h1 style={{ marginBottom: "0px" }}>Move Board to Container</h1>
                    <div className="divClose">
                        <Link to="" onClick={(e) => closeContextMenu(e)}>
                            <AiOutlineClose color="#555" size="13" />
                        </Link>
                    </div>
                    <div style={{ textAlign: "center", paddingTop: "10px" }}>
                        <select style={{ padding: "5px", border: "1px solid #efefef", marginRight: "10px" }}
                            value={containerId}
                            onChange={(e) => handleContainerChange(e)}>
                            <option value={0}>Select Container</option>
                            {mappedContainers}
                        </select>
                        <Link to="" onClick={(e) => handleMoveProject(e)} className="btn-orange">
                            Move
                        </Link>  
                    </div>
                </div>
            </div>
        }
        </>
    );
}

export default MoveBoardContextMenu;