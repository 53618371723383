
export interface IColor {
    hex: string,
    font: string,
    sort: number,
}

export const colors: Array<IColor> = [
    { hex: "#F5B7B1", sort: 1, font: "#000" }, { hex: "#D2B4DE", sort: 2, font: "#000"  }, { hex: "#AED6F1", sort: 3, font: "#000"  }, { hex: "#A2D9CE", sort: 4, font: "#000" }, 
    { hex: "#EC7063", sort: 5, font: "#fff" }, { hex: "#A569BD", sort: 6, font: "#fff"  }, { hex: "#5DADE2", sort: 7, font: "#fff"  }, { hex: "#45B39D", sort: 8, font: "#fff" },
    { hex: "#A93226", sort: 9, font: "#fff" }, { hex: "#7D3C98", sort: 10, font: "#fff" }, { hex: "#2E86C1", sort: 11, font: "#fff" }, { hex: "#138D75", sort: 12, font: "#fff" },
    { hex: "#F9E79F", sort: 13, font: "#000" }, { hex: "#F5CBA7", sort: 14, font: "#000"  }, { hex: "#AEB6BF", sort: 15, font: "#000"  }, { hex: "#ABEBC6", sort: 16, font: "#000" },
    { hex: "#F4D03F", sort: 17, font: "#fff" }, { hex: "#EB984E", sort: 18, font: "#fff" }, { hex: "#5D6D7E", sort: 19, font: "#fff"}, { hex: "#58D68D", sort: 20, font: "#fff" },
    { hex: "#D4AC0D", sort: 21, font: "#fff" }, { hex: "#CA6F1E", sort: 22, font: "#fff"}, { hex: "#2E4053", sort: 23, font: "#fff"}, { hex: "#28B463", sort: 24, font: "#fff" },

]