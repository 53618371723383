import React, { CSSProperties } from 'react';
import { useEffect, useState, createElement } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { ContextMenu } from '../Models/ContextMenu';
//import { ContainerContextParam } from'../Models/Requests/ContainerContextParam';
import { AnalyticsContextParam } from '../Models/Requests/AnalyticsContextParam';
import { AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";
import { FiFolderPlus } from 'react-icons/fi';
import { FaRegTrashAlt, FaPencilAlt } from 'react-icons/fa';
import { getProject } from "../Store/Project";
//import { Container } from "../Models/Container";
import { IconContext, IconType } from "react-icons";
import { MdArrowLeft, MdArrowRight, MdFilterList } from "react-icons/md";
import { BsSortUp } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
//import AnalyticsContextMenu from './AnalyticsContextMenu';
//import DynamicFaIcon from './DynamicFaIcon';
//import PopupContextMenu from './PopupContext';
//import { PopupContextParam } from '../Models/Requests/PopupContextParam';

interface Props {
    params: AnalyticsContextParam | undefined;    
}

function AnalyticsContext(props: Props) {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const projectState = useAppSelector(getProject);
    //const [containerToDelete, setContainerToDelete] = useState<number>(0);
    const [analyticsContext, setAnalyticsContext] = useState<ContextMenu>();
    //const [analyticsContextParams, setAnalyticsContextParams] = useState<AnalyticsContextParam>();
    //const [popupParams, setPopupParams] = useState<PopupContextParam>();
    //const [showContainerForm, setShowContainerForm] = useState<boolean>(false);
  /*
    useEffect(() => {
        if (projectState.status === "containerLoaded") {
            dispatch(unloadProject());
        } else if (projectState.status === "projectUnloaded") {
            closeMenu();
            dispatch(setStatus("idle"));
        }
    }, [projectState.status]);
*/
    useEffect(() => {
        let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        if (props.params) {
            //let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
            if ((analyticsContext && analyticsContext.id !== props.params.id) || !analyticsContext) {
          
                let menuWidth = window.outerWidth * .9;
                let menuHeight = window.outerHeight * .8;
                let menuXStart = window.outerWidth * .5;
                let menuYStart = 20;

                if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }

                contextMenu.id = props.params.id;
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                contextMenu.width = menuWidth;
                contextMenu.height = menuHeight;
            }
        } 
        setAnalyticsContext(contextMenu);
        //setAnalyticsContextParams(undefined);
    }, [props.params]);


    const closeContextMenu = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        closeMenu();
    }

    const closeMenu = () => {
        let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        setAnalyticsContext(contextMenu);
        if (props.params) {
            props.params.parentCloseHandler();
        }
    }


    //let project = projectState.projects.find(p => p.loaded);
    //let container = projectState.containers.find(c => c.loaded);
 
    let green = "#abebc6";
    let yellow = "#f9e79f";
    let orange = "#edbb99";
    let red = "#e6b0aa ";

    const sContextMenu: React.CSSProperties = {
        position: "relative",
        top: (analyticsContext) ? analyticsContext.y : 0,
        width: (analyticsContext) ? analyticsContext.width : 0,
        //height: (analyticsContext) ? analyticsContext.height : 0,
    }
    /*
    let weekCounter:number [][] = new Array(10).fill(0).map(() => 
      new Array(12).fill(0)
    );*/
    let teamMembers = 10;
    let m1 = 0;
    let m2 = 0;
    let m3 = 0;
    let weekCounter: number [] = new Array(12).fill(0);
    let teamMemberTotalCapacity: number [] = new Array(12).fill(0);
   /* weekCounter[0] = 0;
    weekCounter[1] = 0;
    weekCounter[2] = 0;
    weekCounter[3] = 0;
    weekCounter[4] = 0;
    weekCounter[5] = 0;
    weekCounter[6] = 0;
    weekCounter[7] = 0;
    weekCounter[8] = 0;
    weekCounter[9] = 0;
    weekCounter[10] = 0;
    weekCounter[11] = 0;*/

    let teamMemberRows = Array.from(Array(teamMembers), (e, i) => {
        let teamMemberCols = Array.from(Array(3), (e1, i1) => {

            let cap1 = Math. floor(Math. random() * (i1===0 ? 100 : i1===1 ? 70 : 80)) + 1; /* make 100 typically */
            let cap2 = Math. floor(Math. random() * (i1===0 ? 80 : i1===1 ? 100 : 70)) + 1;
            let cap3 = Math. floor(Math. random() * (i1===0 ? 70 : i1===1 ? 80 : 100)) + 1;
            let cap4 = Math. floor(Math. random() * 100) + 1;
            teamMemberTotalCapacity[i] += cap1 + cap2 + cap3 + cap4;
            
            // 0, 1, 2
            if (i1 === 0) {
                weekCounter[0] += cap1;
                weekCounter[1] += cap2;
                weekCounter[2] += cap3;
                weekCounter[3] += cap4;
                m1 += cap1 + cap2 + cap3 + cap4;
            } else if (i1 === 1) {
                weekCounter[4] += cap1;
                weekCounter[5] += cap2;
                weekCounter[6] += cap3;
                weekCounter[7] += cap4;
                m2 += cap1 + cap2 + cap3 + cap4;
            }else {
                weekCounter[8] += cap1;
                weekCounter[9] += cap2;
                weekCounter[10] += cap3;
                weekCounter[11] += cap4;
                m3 += cap1 + cap2 + cap3 + cap4;
            }
            
            let cap1Color = (cap1 <= 40) ? yellow : (cap1 > 40 && cap1 <= 70) ? green : (cap1 > 70 && cap1 <= 80) ? orange : red;
            let cap2Color = (cap2 <= 40) ? yellow : (cap2 > 40 && cap2 <= 70) ? green : (cap2 > 70 && cap2 <= 80) ? orange : red;
            let cap3Color = (cap3 <= 40) ? yellow : (cap3 > 40 && cap3 <= 70) ? green : (cap3 > 70 && cap3 <= 80) ? orange : red;
            let cap4Color = (cap4 <= 40) ? yellow : (cap4 > 40 && cap4 <= 70) ? green : (cap4 > 70 && cap4 <= 80) ? orange : red;

            let style1 : CSSProperties = {
                fontSize: "11pt",
                backgroundColor: cap1Color,
                borderLeft: "1px solid #fff",
                borderTop: "1px solid #fff"
            }

            let style2 : CSSProperties = {
                fontSize: "11pt",
                backgroundColor: cap2Color,
                borderLeft: "1px solid #fff",
                borderTop: "1px solid #fff"
            }

            let style3 : CSSProperties = {
                fontSize: "11pt",
                backgroundColor: cap3Color,
                borderLeft: "1px solid #fff",
                borderTop: "1px solid #fff"
            }

            let style4 : CSSProperties = {
                fontSize: "11pt",
                backgroundColor: cap4Color,
                borderLeft: "1px solid #fff",
                borderTop: "1px solid #fff"
            }

            return (
                <div className="col-3 text-center">
                    <div className="row g-0">
                        <div className="col-3 p-2" style={style1}>
                            {cap1}
                        </div>
                        <div className="col-3 p-2" style={style2}>
                            {cap2}
                        </div>
                        <div className="col-3 p-2"  style={style3}>
                            {cap3}
                        </div>
                        <div className="col-3 p-2"  style={style4}>
                            {cap4}
                        </div>
                    </div>
                </div>
            );
        })   
        
        return (
            <div className="row g-0" style={{ color: "#555" }}>
                <div className="col-3 text-start">
                    <div className="row g-0" style={{ marginTop: "10px" }}>
                        <div className="col-1">
                            <span style={{ position: "relative", top: "-3px", fontSize: "10pt", color: "gray", marginRight: "10px" }}>
                                &gt; 
                            </span>
                        </div>
                        <div className="col-1" style={{ position: "relative", top: "-3px" }}>
                            <IconContext.Provider value={{ size: "25", color: "#000", className: "avatarIcon" }}>
                                <FaUserCircle />
                            </IconContext.Provider>
                        </div>
                        <div className="col-10">
                            <div style={{ width: "80%", margin: "0px auto", backgroundColor: "rgb(240, 243, 244)", borderRadius: "50px" }}>
                                <span  style={{ display: "inline-block",
                                    width: `${teamMemberTotalCapacity[i]/1200*100}%`, backgroundColor: "#d7dbdd", borderRadius: "50px" }}>
                                    &nbsp;
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {teamMemberCols}
            </div>
        );
    });

    m1 = Math.round(m1/4000*100);
    m2 = Math.round(m2/4000*100);
    m3 = Math.round(m3/4000*100);
    let m1Color = (m1 <= 40) ? yellow : (m1 > 40 && m1 <= 70) ? green : (m1 > 70 && m1 <= 80) ? orange : red;
    let m2Color = (m2 <= 40) ? yellow : (m2 > 40 && m2 <= 70) ? green : (m2 > 70 && m2 <= 80) ? orange : red;
    let m3Color = (m3 <= 40) ? yellow : (m3 > 40 && m3 <= 70) ? green : (m3 > 70 && m3 <= 80) ? orange : red;
    //console.log(weekCounter);
  
    return (
        <>
        {analyticsContext && analyticsContext.id > 0 &&
            <div onClick={e => closeMenu()} className="windowContainer">
                <div className="cardContextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
                    {/*
                    <div className="divClose">
                        <Link to="" onClick={(e) => closeContextMenu(e)}>
                            <AiOutlineClose color="#555" size="13" />
                        </Link>
                    </div>
                    */}
                    <div className="sharingContext" style={{ margin: "auto 0px", textAlign: "center" }}
                        onClick={(e) => { e.stopPropagation(); }}>
                        <div style={{ width: "100%", margin: "0px auto" }}>
                     
                            <div className="row g-0" style={{ color: "#000" }}>
                                <div className="col-4 text-start">
                                    <select style={{ padding: "5px", fontSize: "12pt", color: "#efefef", backgroundColor: "#000" }}>
                                       <option>All Boards</option>
                                    </select>&nbsp;&nbsp;
                                    <select style={{ padding: "5px", fontSize: "12pt", color: "#efefef", backgroundColor: "#000" }}>
                                       <option>Capacity Summary</option>
                                    </select>
                                </div>
                                <div className="col-4 text-center">
                                     <MdArrowLeft size={40} />
                                     This Quarter
                                     <MdArrowRight size={40} />
                                </div>
                                <div className="col-4 text-end">
                                    <div className="ms-auto" style={{ 
                                        borderRadius: "5px", 
                                        backgroundColor: "#000",
                                        color: "#fff",
                                        width: "fit-content",
                                        padding: "5px"
                                    }}>
                                        <div style={{
                                            display: "inline-block",
                                            width: "50px",
                                            textAlign: "center",
                                         }}>
                                            Wk
                                        </div>
                                        <div style={{
                                            display: "inline-block",
                                            width: "50px",
                                            textAlign: "center",
                                         }}>
                                            Mo
                                        </div>
                                        <div style={{
                                            display: "inline-block",
                                            width: "50px",
                                            textAlign: "center",
                                            backgroundColor: "#fff",
                                            color: "#000",
                                            borderRadius: "5px"
                                         }}>
                                            Qtr
                                        </div>
                                        <div style={{
                                            display: "inline-block",
                                            width: "50px",
                                            textAlign: "center",
                                         }}>
                                            Yr
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* header row */}
                            <div className="row g-0" style={{ color: "#555" }}>
                                <div className="col-3 text-start">
                                    <div className="row" style={{ padding: "5px" }}>
                                        <div className="col-12" style={{ fontSize: "10pt", marginTop: "10px" }}>
                                        </div>
                                    </div>
                                    <div style={{ padding: "5px", fontSize: "10pt" }}>
                                        <MdFilterList size={18} /> Filter&nbsp;&nbsp;&nbsp;&nbsp;
                                        <BsSortUp size={18} /> Sort
                                    </div> 
                                    <div className="row g-0">
                                        <div className="col-12">
                                            <span style={{ paddingLeft: "5px", color: "gray", fontSize: "11pt" }}>
                                                {teamMembers} members
                                            </span>
                                        </div>  
                                    </div>
                                </div>
                                <div className="col-3 text-center">
                                    <div className="analyticsMonth">Month 1</div>
                                    <div style={{ backgroundColor: "#f0f3f4", borderRadius: "50px", marginLeft: "40px", marginRight: "40px" }}>
                                        <div style={{ width: `${m1}%`, backgroundColor: `${m1Color}`, borderRadius: "50px", fontSize: "11pt" }}>
                                            {m1}
                                        </div>
                                    </div>
                                    <div className="row g-0">
                                        <div className="col-3" style={{ height: "41px", marginTop: "10px", backgroundColor: "#f0f3f4", borderLeft: "1px solid #fff" }}>
                                            <div style={{  position: "relative", top: `${100-(weekCounter[0]/(teamMembers*100)*100)}%`,
                                                height: `${weekCounter[0]/(teamMembers*100)*100}%`, backgroundColor: "#d7dbdd" }}>
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="col-3" style={{ height: "41px", marginTop: "10px", backgroundColor: "#f0f3f4", borderLeft: "1px solid #fff" }}>
                                            <div style={{  position: "relative", top: `${100-(weekCounter[1]/(teamMembers*100)*100)}%`,
                                                height: `${weekCounter[1]/(teamMembers*100)*100}%`, backgroundColor: "#d7dbdd" }}>
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="col-3" style={{ height: "41px", marginTop: "10px", backgroundColor: "#f0f3f4", borderLeft: "1px solid #fff" }}>
                                            <div style={{  position: "relative", top: `${100-(weekCounter[2]/(teamMembers*100)*100)}%`,
                                                height: `${weekCounter[2]/(teamMembers*100)*100}%`, backgroundColor: "#d7dbdd" }}>
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="col-3" style={{ height: "41px", marginTop: "10px", backgroundColor: "#f0f3f4", borderLeft: "1px solid #fff" }}>
                                            <div style={{  position: "relative", top: `${100-(weekCounter[3]/(teamMembers*100)*100)}%`,
                                                height: `${weekCounter[3]/(teamMembers*100)*100}%`, backgroundColor: "#d7dbdd" }}>
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 text-center">
                                    <div className="analyticsMonth">Month 2</div>
                                    <div style={{ backgroundColor: "#f0f3f4", borderRadius: "50px", marginLeft: "40px", marginRight: "40px" }}>
                                        <div style={{ width: `${m2}%`, backgroundColor: `${m2Color}`, borderRadius: "50px", fontSize: "11pt" }}>
                                            {m2}
                                        </div>
                                    </div>
                                    <div className="row g-0">
                                        <div className="col-3" style={{ height: "41px", marginTop: "10px", backgroundColor: "#f0f3f4", borderLeft: "1px solid #fff" }}>
                                            <div style={{  position: "relative", top: `${100-(weekCounter[4]/(teamMembers*100)*100)}%`,
                                                height: `${weekCounter[4]/(teamMembers*100)*100}%`, backgroundColor: "#d7dbdd" }}>
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="col-3" style={{ height: "41px", marginTop: "10px", backgroundColor: "#f0f3f4", borderLeft: "1px solid #fff" }}>
                                            <div style={{  position: "relative", top: `${100-(weekCounter[5]/(teamMembers*100)*100)}%`,
                                                height: `${weekCounter[5]/(teamMembers*100)*100}%`, backgroundColor: "#d7dbdd" }}>
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="col-3" style={{ height: "41px", marginTop: "10px", backgroundColor: "#f0f3f4", borderLeft: "1px solid #fff" }}>
                                            <div style={{  position: "relative", top: `${100-(weekCounter[6]/(teamMembers*100)*100)}%`,
                                                height: `${weekCounter[6]/(teamMembers*100)*100}%`, backgroundColor: "#d7dbdd" }}>
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="col-3" style={{ height: "41px", marginTop: "10px", backgroundColor: "#f0f3f4", borderLeft: "1px solid #fff" }}>
                                            <div style={{  position: "relative", top: `${100-(weekCounter[7]/(teamMembers*100)*100)}%`,
                                                height: `${weekCounter[7]/(teamMembers*100)*100}%`, backgroundColor: "#d7dbdd" }}>
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3 text-center">
                                    <div className="analyticsMonth">Month 3</div>
                                    <div style={{ backgroundColor: "#f0f3f4", borderRadius: "50px", marginLeft: "40px", marginRight: "40px" }}>
                                        <div style={{ width: `${m3}%`, backgroundColor: `${m3Color}`, borderRadius: "50px", fontSize: "11pt" }}>
                                            {m3}
                                        </div>
                                    </div>
                                    <div className="row g-0">
                                        <div className="col-3" style={{ height: "41px", marginTop: "10px", backgroundColor: "#f0f3f4", borderLeft: "1px solid #fff" }}>
                                            <div style={{  position: "relative", top: `${100-(weekCounter[8]/(teamMembers*100)*100)}%`,
                                                height: `${weekCounter[8]/(teamMembers*100)*100}%`, backgroundColor: "#d7dbdd" }}>
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="col-3" style={{ height: "41px", marginTop: "10px", backgroundColor: "#f0f3f4", borderLeft: "1px solid #fff" }}>
                                            <div style={{  position: "relative", top: `${100-(weekCounter[9]/(teamMembers*100)*100)}%`,
                                                height: `${weekCounter[9]/(teamMembers*100)*100}%`, backgroundColor: "#d7dbdd" }}>
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="col-3" style={{ height: "41px", marginTop: "10px", backgroundColor: "#f0f3f4", borderLeft: "1px solid #fff" }}>
                                            <div style={{  position: "relative", top: `${100-(weekCounter[10]/(teamMembers*100)*100)}%`,
                                                height: `${weekCounter[10]/(teamMembers*100)*100}%`, backgroundColor: "#d7dbdd" }}>
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className="col-3" style={{ height: "41px", marginTop: "10px", backgroundColor: "#f0f3f4", borderLeft: "1px solid #fff" }}>
                                            <div style={{  position: "relative", top: `${100-(weekCounter[11]/(teamMembers*100)*100)}%`,
                                                height: `${weekCounter[11]/(teamMembers*100)*100}%`, backgroundColor: "#d7dbdd" }}>
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* detail rows */}
                            {teamMemberRows}
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    );
}

export default AnalyticsContext;