import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
//import { toast } from 'react-toastify';
import { useAppSelector, useAppDispatch } from '../../Core/hooks';
import { getUser, setCurrentPage } from '../../Store/Auth';
import { getProject, addList, setLists, addCard, saveList, setCards, saveCard, saveListObject, copyCard } from '../../Store/Project';
import { getSettings, saveSidebarSetting } from '../../Store/Settings';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/Redirect';
import Navigation from '../../Components/Navigation';
import Sidebar from '../../Components/Sidebar';
import { FaChevronCircleLeft, FaChevronCircleRight, FaCopy, FaPlusCircle, FaEllipsisV } from "react-icons/fa";
//import { createListenerEntry } from '@reduxjs/toolkit/dist/listenerMiddleware';
import { Card } from'../../Models/Card';
import ListContextMenu from '../../Components/ListContextMenu';
import CardContextMenu from '../../Components/CardContext';
import CardContext from '../../Components/CardContext';
import { ListContextMenuParam } from '../../Models/Requests/ListContexMenuParam';
import { CardContextParam } from '../../Models/Requests/CardContextParam';
import { BsThreeDots } from "react-icons/bs";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
//import ProjectCard from '../../Components/ProjectCard'; 
import { ContextMenu } from '../../Models/ContextMenu';
import { MdOutlineControlPointDuplicate } from "react-icons/md";
import { HiDotsVertical } from "react-icons/hi";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { MdLabel } from "react-icons/md";
import { LabelContextMenuParam } from '../../Models/Requests/LabelContextMenuParam';
import LabelContextMenu from '../../Components/LabelContextMenu';
import Avatar from "../../Components/Avatar";
//import Registration from '../../Modals/Registration';

function MemberHome() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useAppSelector(getUser);
    const projectState = useAppSelector(getProject);
    const settings = useAppSelector(getSettings);
    const toastId = React.useRef(0);
    const [newList, setNewList] = useState("");
    const [newCardTitles, setNewCardTitles] = useState<Array<string>>([]);
    const [listNames, setListNames] = useState<Array<string>>([]);
    const [listNamesInEditMode, setListNamesInEditMode] = useState<Array<boolean>>([]);
    const [listContextMenu, setListContextMenu] = useState<ContextMenu>();
    //const [showSidebar, setShowSidebar] = useState(false);
    const [listContextMenuParams, setListContextMenuParams] = useState<ListContextMenuParam>();
    const [cardContextParams, setCardContextParams] = useState<CardContextParam>();
    //const [showCardCopyButton, setShowCardCopyButton] = useState<Array<boolean>>([]);
    const [showAddListField, setShowAddListField] = useState<Array<boolean>>([]);
    const [hideLabelText, setHidelabelText] = useState<boolean>(false);
    const [labelContextMenuParams, setLabelContextMenuParams] = useState<LabelContextMenuParam>();
    //const [listEvent, setListEvent] = useState();
    
    useEffect(() => {
        dispatch(setCurrentPage("home"));
    }, []);

    useEffect(() => {
        let project = projectState.projects.find(p => p.loaded);
        if (project) {
            setCardContextParams({ projectId: project.id, cardId: 0 });
            setListContextMenuParams({ projectId: project.id, listId: 0 });
        }
    }, [projectState.projects])

    useEffect(() => {

        setCardContextParams(undefined); /* close card window on project refresh */
        
        if (projectState.lists.length > 0) {
            
            let tempListNames = [...listNames];
            projectState.lists.forEach((l, i) => {
                tempListNames[i] = l.name;
            });

            setListNames(tempListNames);

            if (projectState.status === "listAdded") {
                /* hide all add list inputs */
                setShowAddListField([]);
            }

        }
    },[projectState.lists]);

    const toggleSidebar = (e: React.MouseEvent<HTMLAnchorElement>, show: boolean) => {
        e.preventDefault();
        dispatch(saveSidebarSetting(show));
    };

    const handleNewListChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewList(e.target.value);
    }

    const createNewList = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (['Enter'].indexOf(e.key) !== -1 && newList !== "") {
            e.preventDefault();
            e.stopPropagation();
            //console.log(index);
            if (project) {
                dispatch(addList({ projectId: project.id, name: newList, index: index}));
                setNewList("");
                /* hide all add list inputs */
                //setShowAddListField([]);
            }
        }
    }

    const handleNewCardTitleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let cardTitles = [...newCardTitles];
        cardTitles[index] = e.target.value;
        setNewCardTitles(cardTitles);
    }

    const createNewCard = (e: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>, index: number, listId: number) => {
        let cardTitles = [...newCardTitles];
        if (cardTitles[index] !== "") {
            if ((e.type === "keydown" && ['Enter'].indexOf((e as React.KeyboardEvent<HTMLInputElement>).key) !== -1) ||
                e.type === "blur") {

                e.preventDefault();
                e.stopPropagation();

                dispatch(addCard({ title: newCardTitles[index], listId: listId}));           
                cardTitles[index] = "";
                setNewCardTitles(cardTitles);
            }
        }
    }

    const handleListNameChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        let tempListNames = [...listNames];
        tempListNames[index] = e.target.value;
        setListNames(tempListNames);
    }

    const updateListName = (e: React.KeyboardEvent<HTMLInputElement>, index: number, listId: number) => {
        let tempListNames = [...listNames];
        if (['Enter'].indexOf(e.key) !== -1 && tempListNames[index] !== "") {

            e.preventDefault();
            e.stopPropagation();

            let tempListNamesInEditMode = [...listNamesInEditMode];
            tempListNamesInEditMode[index] = false;
 
            setListNamesInEditMode(tempListNamesInEditMode);
            dispatch(saveList({ id: listId, name: tempListNames[index] }));           
            //tempListNames[index] = "";
            //setNewCardTitles(listNames);
        }
    }

    const updateListNameOnBlur = (e: React.FocusEvent<HTMLInputElement>, index: number, listId: number) => {
        let tempListNames = [...listNames];
        //if (['Enter'].indexOf(e.key) !== -1 && tempListNames[index] !== "") {

            e.preventDefault();
            //e.stopPropagation();

            let tempListNamesInEditMode = [...listNamesInEditMode];
            tempListNamesInEditMode[index] = false;
            setListNamesInEditMode(tempListNamesInEditMode);
            dispatch(saveList({ id: listId, name: tempListNames[index] }));           
            //tempListNames[index] = "";
            //setNewCardTitles(listNames);
       // }
    }

    const toggleEditListName = (index: number) => {
        let tempListNamesInEditMode = [...listNamesInEditMode];
        tempListNamesInEditMode[index] = !tempListNamesInEditMode[index];
        setListNamesInEditMode(tempListNamesInEditMode);
    }

    const toggleListContextMenu = (e: React.MouseEvent<HTMLAnchorElement>, listId: number) => {
        e.preventDefault();
        let project = projectState.projects.find(p => p.loaded);
        if (project) {
            setListContextMenuParams({ projectId: project.id, listId: listId, event: e }); 
        }  
    };

    const toggleCardContext = (e: React.MouseEvent<HTMLDivElement>, cardId: number) => {
        e.preventDefault();
        let project = projectState.projects.find(p => p.loaded);
        if (project) {
            setCardContextParams({ projectId: project.id, cardId: cardId, event: e });   
        }
    };
/*
    const toggleCardCopyButton = (visible: boolean, index: number) => {
        let hoverStatuses = [...showCardCopyButton];
        hoverStatuses[index] = visible;
        setShowCardCopyButton(hoverStatuses);
    }
*/
    const handleCopyCard = (e: React.MouseEvent<HTMLDivElement>, card: Card) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(copyCard(card));
    }

    const toggleAddListField = (e: React.MouseEvent<HTMLAnchorElement>, index: number) => {
        e.preventDefault();
        e.stopPropagation();
        let temp = [...showAddListField];
        temp[index] = !temp[index];
        setShowAddListField(temp);
    }

    const onDragEnd = (result: any) => {

        let cardId = parseInt(result.draggableId);
        let newIndex = parseInt(result.destination.index);
        let oldIndex = parseInt(result.source.index);
        let newListId = parseInt(result.destination.droppableId);
        let oldListId = parseInt(result.source.droppableId);
        let cardIdsToUpdate: Array<number> = [];

        let cardsInOldList: Array<Card> = [];
        let cardsInNewList: Array<Card> = [];
        let cardsToUpdate: Array<Card> = [];

        let card = projectState.cards.find(c => c.id === cardId);
        //let cardIndex = projectState.cards.findIndex(c => c.id === cardId);

        /* Reshuffle the cards in the list the card is being dragged into */
        cardsInNewList = projectState.cards.filter(c => c.listId === newListId).sort(({ index :a }, { index :b }) => a-b );
        if (card) {
            if (oldListId === newListId) {
                cardsInNewList.splice(oldIndex, 1);
            }
            cardsInNewList.splice(newIndex, 0, card);
            let sortedCardsInList = cardsInNewList.map((c, i) => {
                return {...c, index: i, listId: newListId };
            });

            sortedCardsInList.forEach( c => {
                cardsToUpdate.push(c);
            }); 
        }

        if (oldListId !== newListId) {
            cardsInOldList = projectState.cards.filter(c => c.listId === oldListId).sort(({ index :a }, { index :b }) => a-b );
            if (card) {
                cardsInOldList.splice(oldIndex, 1);
                let sortedCardsInList = cardsInOldList.map((c, i) => {
                    return {...c, index: i };
                });
                sortedCardsInList.forEach( c => {
                    cardsToUpdate.push(c);
                });
            }
        }

        /* Update frontend */
        let cards = projectState.cards.map(card => {
            let cardUpdated = cardsToUpdate.find(c => c.id === card.id);
            if (cardUpdated) {
                return cardUpdated;
            }
            return card;
        })
        cards.sort(({ index :a }, { index :b }) => a-b );
        dispatch(setCards(cards)); // update frontend

        /* update the backend */
        cardsToUpdate.forEach(card => {
            dispatch(saveCard(card));
        });        
    }

    const moveList = (id: number, adjustment: number) => {

        let listIdsToUpdate: Array<number> = [];
        let listIndex = projectState.lists.findIndex(l => l.id === id);
        
        if (listIndex !== -1) {
            let moveToIndex = listIndex + adjustment;
            let lists = projectState.lists.map((list, index) => {
                if(index === listIndex) {
                    listIdsToUpdate.push(list.id);
                    return { ...list, index: moveToIndex };
                } else if (index === moveToIndex) {
                    listIdsToUpdate.push(list.id);
                    return { ...list, index: listIndex };
                }
                return list;
            });

            lists.sort(({ index :a }, { index :b }) => a-b );
            dispatch(setLists(lists)); /* update frontend */
            lists.forEach(list => {
                let listNeedsUpdated = listIdsToUpdate.findIndex(l => l === list.id);
                if (listNeedsUpdated !== -1) {
                    dispatch(saveListObject(list));
                }
            });
        }
    }

    const handleHideLabelText = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setHidelabelText(!hideLabelText);
    }

    const toggleLabelContextMenu = (e: React.MouseEvent<HTMLDivElement>, cardId: number) => {
        e.preventDefault();
        e.stopPropagation();
        setLabelContextMenuParams({ cardId: cardId, event: e });   
    };

    let fullScreen = (settings.showProjects) ? "col-10" : "";
    let project = projectState.projects.find(p => p.loaded);
    let projectCardCount = 0;
    let projectLists = projectState.lists.filter(l => l.projectId === project?.id);
    if (projectLists) {
        projectLists.forEach(l => {
            let listCards = projectState.cards.filter(c => c.listId === l.id);
            projectCardCount += listCards.length;
        });
    }

    let lists = projectState.lists.map((list, index) => {
        /* CARDS */
        let cards: Array<Card> = (projectState.searchText === "") ?
                projectState.cards.filter(c => c.listId === list.id)
            :
                projectState.cards.filter(c => c.listId === list.id && 
                    (c.title.includes(projectState.searchText) || 
                     c.description.includes(projectState.searchText)
                    ));

        let formattedCards = cards.map((c, index) => {
            let cardOwner = projectState.projectMembers.find(m => m.userId === c.ownerId);
            let labelsToCards = projectState.labelsToCards.filter(lc => lc.cardId === c.id);
            return (
                <Draggable key={c.id} draggableId={c.id.toString()} index={index}>
                    {(provided, snapshot) => (
                        <div
                            onClick={(e) => toggleCardContext(e, c.id)}
                            //onMouseOver={e => toggleCardCopyButton(true, c.id)}
                            //onMouseOut={e => toggleCardCopyButton(false, c.id)}
                            className="activeCard"
                            key={index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <div className="cardNotHover">
                                {project?.permission?.toString() !== "observer" &&
                                <>
                                    <div className="copyCardIcon" onClick={e => handleCopyCard(e, c)}>
                                        <FaCopy size="14" color="gray" />
                                    </div>
                                    <div className="addCardLabelIcon" onClick={(e) => toggleLabelContextMenu(e, c.id)}>
                                       <MdLabel size="14" color="gray" />
                                   </div>
                                </>
                                }
                                {/*
                                {showCardCopyButton[c.id] &&
                                    <div className="copyCardIcon" onClick={e => handleCopyCard(e, c)}>
                                        <FaCopy size="14" color="gray" />
                                    </div>
                                }
                                */}

                                {labelsToCards.map(lc => {
                                    let label = projectState.labels.find(l => l.id === lc.labelId);
                                    let style = {
                                        backgroundColor: label?.backgroundColor,
                                        color: label?.fontColor
                                    }
                                    return (
                                    <div key={"mh-"+lc.id} className="labelBadgeSmall mb-1" style={style} onClick={(e) => handleHideLabelText(e)}>
                                        {!hideLabelText &&
                                            <>
                                            {label?.title === "" ?
                                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            :
                                                <span>{label?.title}</span>
                                            }
                                            </>
                                        }
                                    </div>
                                    );
                                })}
                                {labelsToCards.length > 0 &&
                                    <div style={{clear: "both"}}></div>
                                }
                                <div style={{ lineHeight:1.3, whiteSpace: "normal" }}>
                                    {c.title}
                                </div>
                                {c.ticketId > 0 &&
                                    <div className="cfTicket">
                                        <Avatar size="xxsmall" projectMember={cardOwner} inline={true} />
                                        <span style={{paddingRight: "3px" }}>&nbsp;</span>
                                        <span>
                                            {format(new Date(c.modified),"eee L/d").toUpperCase()}
                                            &nbsp;
                                            H{c.ticketId}
                                            &nbsp;
                                            {c.status}
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>
                    )}
                </Draggable>
            );
        });
        // 2 6 2 2 default
        let cCenterLeft = "";
        let cRight = "";

        let cLeft = (index !== 0) ? "col-2" : "";

        if (index === 0 && index === projectState.lists.length - 1) {
            cCenterLeft = "col-10";
        } else if (index !== 0 && index !== projectState.lists.length - 1) {
            cCenterLeft = "ps-0 col-7";
        } else if (index === 0) {
            cCenterLeft = "col-9";
        } else if (index === projectState.lists.length - 1) {
            cCenterLeft = "p-0 col-8";
        }
        
        //let cCenterRight = (index === projectState.lists.length - 1) ? "col-2" : "p-0 col-1";
        //let cCenterRight = (index !== 0) ? "col-2" : "col-1";
        if (index === 0 && index === projectState.lists.length - 1) {
            cRight = "col-2";
        }else if (index === projectState.lists.length - 1) {
            cRight = "col-1";
        } else if (index === 0) {
            cRight = "col-3";
        } else {
            cRight = "col-3";
        }

        //let cRight = (index === projectState.lists.length - 1) ? "" : "col-2";

        return (
            <>
            {index === 0 && 
                <>
                    {showAddListField[index] &&
                        <input type="text" className="newList ms-3" placeholder="+ add list"
                            value={newList}
                            onChange={(e) => handleNewListChange(e)} 
                            onKeyDown={(e) => createNewList(e, index)}
                            style={{ marginRight: "0px" }}
                        />
                    }
                    {project?.permission?.toString() !== "observer" ?
                        <div className="listAddButton">
                            <span>
                                <Link to="" onClick={e => toggleAddListField(e, index)}>
                                    <FaEllipsisV color="#efefef" size="20" />
                                </Link>
                            </span>
                        </div>
                    :
                        <div className="listAddButton" style={{ width: "16px" }}></div>
                    }
                </>
            }
            <div className="listBackground" key={"list-" + index}>
                {!listNamesInEditMode[index] ?
                    <div className="row">
                        <div className={cLeft}>
                            {index > 0 && project?.permission?.toString() !== "observer" &&
                                <Link to="" onClick={(e) => {
                                    e.preventDefault();
                                    moveList(list.id, -1)}
                                }>
                                    <span className="iconHover">
                                        <FaChevronCircleLeft size="20" />
                                    </span>
                                </Link>
                            }
                        </div>
                        {project?.permission?.toString() !== "observer" ?
                            <div className={`${cCenterLeft} text-wrap`} onClick={() => toggleEditListName(index)}>
                                {listNames[index]} {/* <br />index: {list.index} */}
                            </div>
                        :
                            <div className="col-12 text-wrap">
                                {listNames[index]}
                            </div>
                        }
                        {/*
                        <div className={cCenterRight}>
                            {project?.permission?.toString() !== "observer" &&
                                <Link to="" onClick={(e) => toggleListContextMenu(e, list.id)}>
                                    <BsThreeDots />
                                </Link>
                            }
                        </div>*/}
                        <div className={cRight}>
                            {project?.permission?.toString() !== "observer" &&
                                <>
                                    <Link to="" onClick={(e) => toggleListContextMenu(e, list.id)}>
                                        <BsThreeDots />
                                    </Link>
                                    {index < projectState.lists.length - 1 && 
                                        <>
                                            &nbsp;&nbsp;
                                            <Link to="" onClick={(e) => {
                                                    e.preventDefault();
                                                    moveList(list.id, 1)}
                                                }>
                                                <span className="iconHover">
                                                    <FaChevronCircleRight size="20" />
                                                </span>
                                            </Link>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                :
                    <div>
                        <input className="changeListName" type="text"
                            value={listNames[index]}
                            autoFocus
                            onChange={(e) => handleListNameChange(e, index)} 
                            onKeyDown={(e) => updateListName(e, index, list.id)}
                            onBlur={(e) => updateListNameOnBlur(e, index, list.id)}
                        />             
                    </div>
                }
                
                <Droppable droppableId={(list.id) ? list.id.toString() : ""}>
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {formattedCards}
                            {formattedCards.length === 0 && projectCardCount > 0 &&
                                <div className="cardPlaceholder">
                                    drop card here
                                </div>
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
               {projectState.searchText === "" &&
                <div>
                    <input className="addCard" type="text" placeholder="+ add card"
                        value={(newCardTitles[index]) ? newCardTitles[index] : ""}
                        onChange={(e) => handleNewCardTitleChange(e, index)} 
                        onKeyDown={(e) => createNewCard(e, index, list.id)}
                        onBlur={(e) => createNewCard(e, index, list.id)}
                    />
                </div>
                }
            </div>
            <>
                {project?.permission?.toString() !== "observer" ?
                    <div className="listAddButton">
                        <span>
                            <Link to="" onClick={e => toggleAddListField(e, index + 1)}>
                                <FaEllipsisV color="#efefef" size="20" />
                            </Link>
                        </span>
                    </div>
                :
                    <div className="listAddButton" style={{ width: "20px" }}></div>
                }
                {showAddListField[index+1] &&
                    <input type="text" className="newList" placeholder="+ add list"
                        value={newList}
                        onChange={(e) => handleNewListChange(e)} 
                        onKeyDown={(e) => createNewList(e, index + 1)}
                    />
                }
            </>
        </>
        )
    });

    return (
        <>
            <Redirect />
            <Navigation />
            {projectState.projectSwitchingStatus ?
                <div className="row g-0">
                    <div className="col-2">
                        <Sidebar visibilityHandler={toggleSidebar} />
                    </div>
                    <div className="col-10">
                        <div style={{ margin: "50px auto", textAlign: "center" }}>
                            <div style={{ color: "#555", fontWeight: "bold", fontSize: "16pt"}}>
                                Loading...
                            </div>
                        </div>
                    </div>
                </div>
            :
                <>
                {projectState.containers.length > 0 ?
                    <>
                        {!settings.showProjects &&
                            <Link to="" className="showSidebarButton" onClick={(e) => toggleSidebar(e, true)}>
                                <span className="adjustArrow">
                                    <FaChevronCircleRight color="#000" size="18" />
                                </span>
                            </Link>
                        }
                        <div className="row g-0">
                            {settings.showProjects &&
                                <div className="col-2">
                                    <Sidebar visibilityHandler={toggleSidebar} />
                                </div>
                            }
                            <div className={`${fullScreen}`} style={{ paddingTop: "20px" }}>
                                {project !== undefined ?
                                    <div className="listBody">
                                        <DragDropContext onDragEnd={(e) => onDragEnd(e)}>
                                            {lists}
                                        </DragDropContext>
                                        {lists.length === 0 &&
                                            <input type="text" className="newList ms-3" placeholder="+ add list"
                                                value={newList}
                                                onChange={(e) => handleNewListChange(e)} 
                                                onKeyDown={(e) => createNewList(e, 0)}
                                            />
                                        }
                                    </div>
                                :
                                    <div style={{ 
                                        textAlign: "center", 
                                        width: "100%", 
                                        paddingTop: "100px",
                                        fontSize: "14pt",
                                        fontWeight: "400",
                                    }}>
                                        {/* This only shows if active project is trashed. It will unload it and no project will be loaded */}
                                        <div><img src='images/herdr-logo-cat-only.png' style={{ width: "60px", marginBottom: "10px" }} alt="Select project" /></div>
                                        <div>Please select a board for herding</div>
                                    </div>
                                }
                            </div>
                        </div>
                        <ListContextMenu params={listContextMenuParams} />
                        <CardContextMenu params={cardContextParams} />
                    </>
                :
                    <>
                        {projectState.status === "idle" ?
                            <div style={{ padding: "100px", textAlign: "center" }}>
                                <div>
                                    <img src='images/herdr-logo-cat-only.png' style={{ width: "60px", marginBottom: "10px" }} alt="Let's get started" />
                                </div>
                                <h2>Congrats, now let's herd those beautiful cats!</h2>
                                <div>
                                    Get started by using the icons located top right to create your first container and 
                                    board.
                                </div>
                            </div> 
                        :
                        <div style={{ margin: "50px auto", textAlign: "center" }}>
                            {/*<div>
                                <img src='../images/loading-cat.gif' style={{ width: "75px", opacity: ".75" }} />
                            </div>*/}
                            <div style={{ color: "#555", fontWeight: "bold", fontSize: "16pt"}}>
                                Loading...
                            </div>
                        </div>
                        }
                    </>
                }
                </>
            }
            <LabelContextMenu params={labelContextMenuParams} />
        </>
    );
}

export default MemberHome;