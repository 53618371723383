import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { getProject, changeCardStatus } from '../Store/Project';
import { ContextMenu } from '../Models/ContextMenu';
import { ChangeCardStatusContextMenuParams } from '../Models/Requests/ChangeCardStatusContextMenuParams';
import { AiOutlineClose } from "react-icons/ai";

interface Props {
    params: ChangeCardStatusContextMenuParams | undefined;    
}

function ChangeCardStatusContextMenu(props: Props) {

    const dispatch = useAppDispatch();
    const projectState = useAppSelector(getProject);
    const [status, setStatus] = useState("");
    const [changeCardStatusContextMenu, setChangeCardStatusContextMenu] = useState<ContextMenu>();
/*
    useEffect(() => {
        if (projectState.status === "cardStatusChanged") {
            closeContextMenu();
        }
    }, [projectState.status]);
*/
    useEffect(() => {
        if (props.params) {
            let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
            if ((changeCardStatusContextMenu && changeCardStatusContextMenu.id !== props.params.cardId) || !changeCardStatusContextMenu) {

                //let menuWidth = window.outerWidth / 5;
                //let menuWidth = 300;
                //let menuHeight = window.outerHeight * .7;

                //let numberOfLabels = projectState.labels.length;
                //let menuHeight = 145 + 50 * numberOfLabels;

                let menuXStart = props.params.event.clientX + 15;
                let menuYStart = props.params.event.clientY - 15;
                //alert(menuXStart + " : " + menuWidth + " : " + window.outerWidth);
                if (menuXStart > window.outerWidth) {
                    menuXStart = window.outerWidth - 10;
                }
                /*
                if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }
                */
                contextMenu.id = props.params.cardId;
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                //contextMenu.width = menuWidth;
                //contextMenu.height = menuHeight;
            }
            setChangeCardStatusContextMenu(contextMenu);
           // refreshLabelSelections();
        }
    }, [props.params]);

    const closeContextMenu = (e?: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        setChangeCardStatusContextMenu(contextMenu);
    }

    const handleChangeCardStatus = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (props.params && props.params.cardId && status !== "") {
            dispatch(changeCardStatus({
                status: status,
                cardId: props.params.cardId,
            }));
            closeContextMenu();
        }
    }

    const sContextMenu: React.CSSProperties = {
        top: (changeCardStatusContextMenu) ? changeCardStatusContextMenu.y : 0,
        left: (changeCardStatusContextMenu) ? changeCardStatusContextMenu.x : 0,
        //width: (moveBoardContextMenu) ? moveBoardContextMenu.width : 0,
        height: "auto",
        paddingBottom: "15px",
    }

    const card = projectState.cards.find(c => c.id === props.params?.cardId);
    let statuses = ["Open", "Started", "Completed"];
    const mappedStatuses = statuses.map((status, index) => {
        if (status !== card?.status) {
            return (
                <option value={status}>{status}</option>
            );
        }
    });

    const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setStatus(e.currentTarget.value);
    }

    return (
        <>
        {changeCardStatusContextMenu && changeCardStatusContextMenu.id > 0 &&
            <div onClick={e => closeContextMenu(e)} className="popupContainer">
                <div className="contextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation()}}>
                    <h1 style={{ marginBottom: "0px" }}>Change Card Status</h1>
                    <div className="divClose">
                        <Link to="" onClick={(e) => closeContextMenu(e)}>
                            <AiOutlineClose color="#555" size="13" />
                        </Link>
                    </div>
                    <div style={{ textAlign: "center", paddingTop: "10px" }}>
                        <select style={{ padding: "5px", border: "1px solid #efefef", marginRight: "10px" }}
                            value={status}
                            onChange={(e) => handleStatusChange(e)}>
                            <option value={0}>Select New Status</option>
                            {mappedStatuses}
                        </select>
                        <Link to="" onClick={(e) => handleChangeCardStatus(e)} className="btn-orange">
                            Save
                        </Link>  
                    </div>
                </div>
            </div>
        }
        </>
    );
}

export default ChangeCardStatusContextMenu;