import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../Core/hooks';
import SubmitButton from '../Controls/SubmitButton';
import { toast } from 'react-toastify';
import { getProject, setSearchText } from '../../Store/Project';
import { getSettings, saveSidebarSetting } from '../../Store/Settings';
import { FiSearch } from "react-icons/fi";

function Search() {

    const projectState = useAppSelector(getProject);
    const dispatch = useAppDispatch();
 
    const searchCards = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setSearchText(e.currentTarget.value));
    }

    let searchStyle: React.CSSProperties = {
        display: "inline-block",
        position: "relative",
        left: "24px",
        color: "#F2F3F4",
    };

    return (
        <div className="component me-2">
            {projectState.searchText === "" &&
                <div style={searchStyle}>
                    <FiSearch />
                </div>
            }
            <input 
                className="search-project" 
                type="text" 
                placeholder="     search"
                value={projectState.searchText}
                onChange={(e) => searchCards(e)}
            />
        </div>
    );
}

export default Search;