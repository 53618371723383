import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

function Footer() {

    const currentYear = format(new Date(), 'y');

    return (
        <>
            <div className="subfooter">
                <br/>
                <Link to="/team">Our Team</Link>
                <br/>
            </div>
            <footer className="text-center pb-3 pt-3">
                <strong>&copy; {currentYear} Burdette Thomas Company LLC. All rights reserved.</strong>
                <Link to="/privacy">
                    Privacy Policy
                </Link>
                <Link to="/terms">
                    Terms of Service
                </Link>
            </footer>
        </>
    );
}

export default Footer;