import { IBaseModel } from './IBaseModel';

export interface Card extends IBaseModel {
    title: string;
    listId: number;
    index: number;
    description: string;
    ticketId: number;
    ownerId: number;
    status: string;
    estimatedWork: number;
    actualWork: number;
}

export const EmptyCard = {
    id: 0,
    created: new Date(),
    modified: new Date(),
    active: false,
    title: "",
    listId: 0,
    index: 0,
    description: "",
    ticketId: 0,
    ownerId: 0,
    status: "",
    estimatedWork: 0,
    actualWork: 0
}