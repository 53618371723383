import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { ContextMenu } from '../Models/ContextMenu';
import { SharingContextParam } from'../Models/Requests/SharingContextParam';
import { AiOutlineClose } from "react-icons/ai";
//import { logout } from '../Store/Auth';
//import { getSettings, saveAvatar, removeAvatar } from "../Store/Settings";
import { getProject, getProjects, saveSharing, deleteSharing,
    deleteContainerSharing, 
    shareViaEmail, getProjectMembers, removeMemberFromProject,
    changeProjectMemberPermission, saveContainerSharing, getContainerMembers,
    getContainers, shareContainerViaEmail, removeMemberFromContainer,
    changeContainerMemberPermission
} from "../Store/Project";
//import { FaUserCircle } from "react-icons/fa";
import { IconContext } from "react-icons";
import { FiLink } from "react-icons/fi";
//import { FaLink } from "react-icons/fa";
//import Resizer from "react-image-file-resizer";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
//import { Project } from "../Models/Project";
import { shareType } from '../Helpers/types';
import { isValidEmail } from "../Helpers/isValidEmail";
import { ProjectMember } from "../Models/Requests/ProjectMember";
import Avatar from "../Components/Avatar";

interface Props {
    params: SharingContextParam | undefined;    
}

function SharingContext(props: Props) {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const projectState = useAppSelector(getProject);
    //const avatarUpload = useRef(null);
    const [sharingContext, setSharingContext] = useState<ContextMenu>();
    /* top email share dropdown */
    const [dropDownClass, setDropDownClass] = useState<string>("dropDown");
    const [showingDropDown, setShowingDropDown] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string>("Member");
    /* share via link dropdown */
    const [shareLinkDropDownClass, setShareLinkDropDownClass] = useState<string>("dropDown");
    const [showingShareLinkDropDown, setShareLinkShowingDropDown] = useState<boolean>(false);
    /* members lists */
    //const [memberDropDownClass, setMemberDropDownClass] = useState<string>("dropDown");
    const [showingMemberDropDown, setShowingMemberDropDown] = useState<Array<boolean>>([]);
    const [shareType, setShareType] = useState<string>("board");

    const [shareLinkSelectedOption, setSelectedShareLinkOption] = useState<string>("Member");
   
    /*const [shareLinkSelectedOption, setSelectedShareLinkOption] = useState<string>(
        (props.params?.shareLinkPermission !== undefined && props.params?.shareLinkPermission !== "") ? props.params!.shareLinkPermission.charAt(0).toUpperCase() + props.params.shareLinkPermission.slice(1) : "Member");
   */
        /* action messages */
    const [showingLinkCopied, setShowingLinkCopied] = useState<boolean>(false);
    const [showingLinkCreated, setShowingLinkCreated] = useState<boolean>(false);
    const [showingLinkDeleted, setShowingLinkDeleted] = useState<boolean>(false);
    const [showingInviteSent, setShowingInviteSent] = useState<boolean>(false);
    const [showingEmailInviteBox, setShowingEmailInviteBox] = useState<boolean>(false);
    const [showingEmailInviteBoxEmail, setShowingEmailInviteBoxEmail] = useState<boolean>(false);
    const [emailInviteBoxValue, setEmailInviteBoxValue] = useState<string>("");
    const [showingEmailShareDescription, setShowingEmailShareDescription] = useState<boolean>(false);
    //const [showingSelectedEmailsBox, setShowingSelectedEmailsBox] = useState<boolean>(false);
    const [emailShareDescription, setEmailShareDescription] = useState<string>("");
    const [selectedEmails, setSelectedEmails] = useState<Array<string>>([]);
    const [removedMyself, setRemovedMyself] = useState<boolean>(false);
  
    useEffect(() => {
        if (props.params) {
            let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
            if ((sharingContext && sharingContext.id !== props.params.id) || !sharingContext) {
                
                /* refresh project members in case their statuses have changed, such as 
                   acceptnging an invite */
                let project = projectState.projects.find(p => p.loaded);
                if (project) {   
                    dispatch(getProjectMembers(project.id));
                    if (shareType === "board") {
                        setSelectedShareLinkOption(project.shareLinkPermission);
                    }
                }

                /* refresh container members in case their statuses have changed, such as acceptnging an invite */
                let container = projectState.containers.find(c => c.loaded);
                if (container) {   
                    dispatch(getContainerMembers(container.id));
                    if (shareType !== "board") {
                        setSelectedShareLinkOption(container.shareLinkPermission);
                    }
                }

                //setSelectedShareLinkOption((props.params?.shareLinkPermission !== undefined && props.params?.shareLinkPermission !== "") ? props.params!.shareLinkPermission.charAt(0).toUpperCase() + props.params.shareLinkPermission.slice(1) : "Member");

                let menuWidth = window.outerWidth * .4;
                let menuHeight = window.outerHeight * .65;
                let menuXStart = window.outerWidth * .30;
                let menuYStart = 20;

                if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }

                contextMenu.id = props.params.id;
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                contextMenu.width = menuWidth;
                contextMenu.height = menuHeight;
            }
            setSharingContext(contextMenu);
        }
    }, [props.params]);

    useEffect(() => {
        if (projectState.status === "shareLinkSaved" || projectState.status === "shareLinkDeleted") {
            dispatch(getProjects());
            dispatch(getContainers());
            if (projectState.status === "shareLinkDeleted") {
                setSelectedShareLinkOption("Member");
            }
        }
        if (projectState.status === "sharedViaEmail" || projectState.status === "memberRemoved" || 
            projectState.status === "memberChanged") {
            if (shareType === "board") {    
                let project = projectState.projects.find(p => p.loaded);
                if (project) {
                    if (removedMyself) {
                        closeMenu();
                        dispatch(getProjects());
                    } else {
                        dispatch(getProjectMembers(project.id));
                    }
                }
            } else {
                let container = projectState.containers.find(c => c.loaded);
                if (container) {
                    if (removedMyself) {
                        closeMenu();
                        dispatch(getContainers());
                    } else {
                        dispatch(getContainerMembers(container.id));
                    }
                }
            }
        }
    }, [projectState.status]);

    const closeContextMenu = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        closeMenu();
    }

    const closeMenu = () => {
        resetState();
        let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        setSharingContext(contextMenu);
        if (props.params) {
            props.params.parentCloseHandler();
        }
    }

    const resetState = () => {
        dehighlightDropdown();
        dehighlightShareLinkDropdown();
        setEmailInviteBoxValue("");
        setShowingEmailInviteBox(false);
        setShowingEmailInviteBoxEmail(false);
        setShowingEmailShareDescription(false);
        //setShowingSelectedEmailsBox(false);
        setSelectedEmails([]);
    }

    const toggleDropDownHighlight = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if(!showingDropDown) {
            highlightDropdown();
        } else {
            dehighlightDropdown();
        }
    }

    const highlightDropdown = () => {
        setDropDownClass("dropDownSelected");
        setShowingDropDown(true);
        setShareLinkDropDownClass("dropDown");
        setShareLinkShowingDropDown(false);
        setShowingMemberDropDown([]);
    }

    const dehighlightDropdown = () => {
        setDropDownClass("dropDown");
        setShowingDropDown(false);
        setShareLinkDropDownClass("dropDown");
        setShareLinkShowingDropDown(false);
        //setMemberDropDownClass("dropDown");
        setShowingMemberDropDown([]);
    }

    const selectOption = (e: React.MouseEvent<HTMLDivElement>, option: string) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedOption(option);
        dehighlightDropdown();
    }

    const toggleShareLinkDropDownHighlight = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if(!showingShareLinkDropDown) {
            highlightShareLinkDropdown();
        } else {
            dehighlightShareLinkDropdown();
        }
    }

    const highlightShareLinkDropdown = () => {
        setShareLinkDropDownClass("dropDownSelected");
        setShareLinkShowingDropDown(true);
        setDropDownClass("dropDown");
        setShowingDropDown(false);
        setShowingMemberDropDown([]);
    }

    const dehighlightShareLinkDropdown = () => {
        setShareLinkDropDownClass("dropDown");
        setShareLinkShowingDropDown(false);
        setDropDownClass("dropDown");
        setShowingDropDown(false);
        setShowingMemberDropDown([]);
    }

    const toggleMemberDropDownHighlight = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
        e.preventDefault();
        e.stopPropagation();

        setShareLinkDropDownClass("dropDown");
        setShareLinkShowingDropDown(false);
        setDropDownClass("dropDown");
        setShowingDropDown(false); 
       
        if(!showingMemberDropDown[index] || showingMemberDropDown[index] === false) {
            highlightMemberDropdown(index); //console.log(showingMemberDropDown);
        } else {
            dehighlightMemberDropdown(index);
        }
    }

    const highlightMemberDropdown = (index: number) => {

        setShareLinkDropDownClass("dropDown");
        setShareLinkShowingDropDown(false);
        setDropDownClass("dropDown");
        setShowingDropDown(false);
        //setMemberDropDownClass("dropDownSelected");
        //console.log(showingMemberDropDown);
        let tempShowingMemberDropDown = [...showingMemberDropDown];
        tempShowingMemberDropDown[index] = true;
        showingMemberDropDown.forEach((m, i) => {
            //console.log(showingMemberDropDown);
            if (i !== index) {
                tempShowingMemberDropDown[i] = false;
            }
        });
        //tempShowingMemberDropDown[index] = true;
        setShowingMemberDropDown(tempShowingMemberDropDown);
    }

    const dehighlightMemberDropdown = (index: number) => {
        //setMemberDropDownClass("dropDown");
        let tempShowingMemberDropDown = [...showingMemberDropDown];
        //showingMemberDropDown.forEach((m, i) => {
        //    tempShowingMemberDropDown[i] = (i === index) ? false ;
        //}); 
        tempShowingMemberDropDown[index] = false;
        setShowingMemberDropDown(tempShowingMemberDropDown);
    }

    const selectShareLinkOption = (e: React.MouseEvent<HTMLDivElement>, option: string) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedShareLinkOption(option);
        dehighlightDropdown();

        if (shareType === "board") {
            let project = projectState.projects.find(p => p.loaded);
            if (project) {
                dispatch(saveSharing({
                    id: project.id,
                    shareLinkPermission: option.toLowerCase() as shareType
                }));
            }
        } else {
            let container = projectState.containers.find(c => c.loaded);
            if (container) {
                dispatch(saveContainerSharing({
                    id: container.id,
                    shareLinkPermission: option.toLowerCase() as shareType
                }));
            }
        }
    }

    const createLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (shareType === "board") {
            let project = projectState.projects.find(p => p.loaded);
            if (project) {
                dispatch(saveSharing({
                    id: project.id,
                    shareLinkPermission: "member"
                }));
                setShowingLinkCreated(true);
                setSelectedShareLinkOption("Member");
                setTimeout(() => {
                    setShowingLinkCreated(false)
                }, 1000);
            }
        } else {
            let container = projectState.containers.find(c => c.loaded);
            if (container) {
                dispatch(saveContainerSharing({
                    id: container.id,
                    shareLinkPermission: "member"
                }));
                setShowingLinkCreated(true);
                setSelectedShareLinkOption("Member");
                setTimeout(() => {
                    setShowingLinkCreated(false)
                }, 1000);
            }
        }
    }

    const deleteShareLink = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        dehighlightDropdown();

        if (shareType === "board") {
            let project = projectState.projects.find(p => p.loaded);
            if (project) {
                dispatch(deleteSharing(project.id));
                //setShareLinkDropDownClass("Member");
                setShowingLinkDeleted(true);
                setTimeout(() => {
                    setShowingLinkDeleted(false)
                }, 1000);
            }
        } else {
            let container = projectState.containers.find(c => c.loaded);
            if (container) {
                dispatch(deleteContainerSharing(container.id));
                setShowingLinkDeleted(true);
                setTimeout(() => {
                    setShowingLinkDeleted(false)
                }, 1000);
            }
        }
    }

    const copyLink = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (shareType === "board") {
            let project = projectState.projects.find(p => p.loaded);
            if (project) {
                navigator.clipboard.writeText(window.location.origin + "/project/invite/" + project.id + "/" + project.shareLink);
                setShowingLinkCopied(true);
                setTimeout(() => {
                    setShowingLinkCopied(false)
                }, 1000);
            }
        } else {
            let container = projectState.containers.find(c => c.loaded);
            if (container) {
                navigator.clipboard.writeText(window.location.origin + "/container/invite/" + container.id + "/" + container.shareLink);
                setShowingLinkCopied(true);
                setTimeout(() => {
                    setShowingLinkCopied(false)
                }, 1000);
            }
        }
    }

    const handleShowingEmailInviteBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setEmailInviteBoxValue(e.currentTarget.value);
    }

    const handleShowingEmailInviteBoxKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();

        /* If alreadu showing a valid email and the user presses the ENTER
           key, add the email */
        if(showingEmailInviteBoxEmail) {
            if (['Enter'].indexOf(e.key) !== -1) {
                selectEmail();
                return;
            }
        }

        if(e.currentTarget.value !== "") {
            setShowingEmailInviteBox(true);
            setShowingEmailInviteBoxEmail(false);
            if(isValidEmail(e.currentTarget.value)) {
                setShowingEmailInviteBoxEmail(true);
                //alert("Congrats, we now have an email!");
            }   
        } else {
            setShowingEmailInviteBox(false);
            setShowingEmailInviteBoxEmail(false);
        }
    }

    const handleSelectEmail = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        selectEmail();
    }

    const selectEmail = () => {

        //alert("Email address added!");
        setShowingEmailShareDescription(true);
        setShowingEmailInviteBox(false);
        setShowingEmailInviteBoxEmail(false);

        let projectMembers = projectState.projectMembers.filter(m => m.active);
        let containerMembers = projectState.containerMembers.filter(c => c.active);

        let emails = [...selectedEmails];
        if(!emails.includes(emailInviteBoxValue)) {
            emails.push(emailInviteBoxValue);
            setSelectedEmails(emails);
            setEmailInviteBoxValue("");
            if (sharingContext !== undefined && 
                (projectMembers.length > 0 || containerMembers.length > 0)
            ) {
                let contextMenu = { ...sharingContext };
                contextMenu.height =  window.outerHeight * .80;
                setSharingContext(contextMenu);
            }
        }

    }

    const handleChangeEmailShareDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setEmailShareDescription(e.currentTarget.value);
    }

    const removeSelectedEmail = (e: React.MouseEvent<HTMLDivElement>, i: number) => {
        e.preventDefault();
        e.stopPropagation();
        let emails = [...selectedEmails];
        emails.splice(i,1);
        if (sharingContext !== undefined && emails.length === 0) {
            let contextMenu = { ...sharingContext };
            contextMenu.height =  window.outerHeight * .65;
            setSharingContext(contextMenu);
            setShowingEmailShareDescription(false);
        }
        setSelectedEmails(emails);
    }

    const handleShareViaEmail = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if(selectedEmails.length > 0) {
            if (shareType === "board") {
                let project = projectState.projects.find(p => p.loaded);
                if (project) {
                    dispatch(shareViaEmail({
                        type: selectedOption.toLowerCase() as shareType,
                        emailBody: emailShareDescription,
                        emails: selectedEmails,
                        projectId: project.id,
                    }));
                    /* show success, reset screen */
                    resetState();
                    setShowingInviteSent(true);
                    setTimeout(() => {
                        setShowingInviteSent(false)
                    }, 1000);
                }
            } else {
                let container = projectState.containers.find(c => c.loaded);
                if (container) {
                    dispatch(shareContainerViaEmail({
                        type: selectedOption.toLowerCase() as shareType,
                        emailBody: emailShareDescription,
                        emails: selectedEmails,
                        containerId: container.id,
                    }));
                    /* show success, reset screen */
                    resetState();
                    setShowingInviteSent(true);
                    setTimeout(() => {
                        setShowingInviteSent(false)
                    }, 1000);
                }
            }
        }
    }

    const deleteMemberFromBoard = (e: React.MouseEvent<HTMLDivElement>, email: string, me: boolean) => {
        e.preventDefault();
        e.stopPropagation();
        if (shareType === "board") {
            let project = projectState.projects.find(p => p.loaded);
            if (project) {
                setRemovedMyself(me);
                dispatch(removeMemberFromProject({ id: project.id, email: email}));
                setShowingMemberDropDown([]);
            }
        } else {
            let container = projectState.containers.find(c => c.loaded);
            if (container) {
                setRemovedMyself(me);
                dispatch(removeMemberFromContainer({ id: container.id, email: email}));
                setShowingMemberDropDown([]);
            }
        }
    }

    const updateMembersPermission = (e: React.MouseEvent<HTMLDivElement>, permission: string, email: string) => {
        e.preventDefault();
        e.stopPropagation();
        if  (shareType === "board") {
            let project = projectState.projects.find(p => p.loaded);
            if (project) {
                dispatch(changeProjectMemberPermission({ id: project.id, email: email, permission: permission }));
                setShowingMemberDropDown([]);
            }
        } else {
            let container = projectState.containers.find(c => c.loaded);
            if (container) {
                dispatch(changeContainerMemberPermission({ id: container.id, email: email, permission: permission }));
                setShowingMemberDropDown([]);
            }          
        }
    }

    const handleTypeRadio = (e: React.ChangeEvent<HTMLInputElement>, shareType: string) => {
        //e.preventDefault();
        //e.stopPropagation();
        setShareType(shareType);
        if (shareType === "board") {
            let project = projectState.projects.find(p => p.loaded);
            if (project) {
                setSelectedShareLinkOption(project.shareLinkPermission);
            }
        } else {
            let container = projectState.containers.find(c => c.loaded);
            if (container) {
                setSelectedShareLinkOption(container.shareLinkPermission);
            }
        }
        
        /*
        if (shareType === "board") { 
            dispatch(getProjects());
        } else {
            dispatch(getContainers());
        }
            */
    }

    //let topAdj = 0;
    let project = projectState.projects.find(p => p.loaded);
    let container = projectState.containers.find(c => c.loaded);

    let projectMembers = projectState.projectMembers.filter(m => m.active);
    let containerMembers = projectState.containerMembers.filter(c => c.active);

    let members: Array<ProjectMember> = (shareType === "board") ? projectMembers : containerMembers;

    const sContextMenu: React.CSSProperties = {
        position: "relative",
        top: (sharingContext) ? sharingContext.y : 0,
        //right: (sharingContext) ? sharingContext.x : 0,
        //left: (settingsContext) ? settingsContext.x : 0,
        width: (sharingContext) ? sharingContext.width : 0,
        height: (sharingContext) ? sharingContext.height : 0,
    }
    
    const memberSelected = (selectedOption === "Member") ? "selected": "";
    const observerSelected = (selectedOption === "Observer") ? "selected": "";
    const adminSelected = (selectedOption === "Admin") ? "selected": "";

    const shareLinkMemberSelected = (shareLinkSelectedOption === "Member") ? "selected": "";
    const shareLinkObserverSelected = (shareLinkSelectedOption === "Observer") ? "selected": "";
    const shareLinkAdminSelected = (shareLinkSelectedOption === "Admin") ? "selected": "";

    return (
        <>
        {sharingContext && sharingContext.id > 0 &&
            <div onClick={e => closeMenu()} className="windowContainer">
                <div className="cardContextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation(); dehighlightDropdown(); }}>
                    <div className="divClose">
                        <Link to="" onClick={(e) => closeContextMenu(e)}>
                            <AiOutlineClose color="#555" size="13" />
                        </Link>
                    </div>
                    <div className="sharingContext" style={{ margin: "auto 0px", textAlign: "center" }}
                        onClick={(e) => { e.stopPropagation(); dehighlightDropdown(); }}>
                        <div style={{ width: "96%", margin: "0px auto" }}>
                            {showingLinkCopied &&
                                <div className="sharingLinkCopied">
                                    <IconContext.Provider value={{ size: "20", className: "icon" }}>
                                        <IoIosCheckmarkCircleOutline />
                                    </IconContext.Provider>  
                                    <span className="ps-2">
                                        Link copied to clipboard
                                    </span>
                                </div>
                            }
                            {showingLinkCreated &&
                                <div className="sharingLinkCopied">
                                    <IconContext.Provider value={{ size: "20", className: "icon" }}>
                                        <IoIosCheckmarkCircleOutline />
                                    </IconContext.Provider>  
                                    <span className="ps-2">
                                        Share Link Created
                                    </span>
                                </div>
                            }
                            {showingLinkDeleted &&
                                <div className="sharingLinkCopied">
                                    <IconContext.Provider value={{ size: "20", className: "icon" }}>
                                        <IoIosCheckmarkCircleOutline />
                                    </IconContext.Provider>  
                                    <span className="ps-2">
                                        Share Link Deleted
                                    </span>
                                </div>
                            }
                            {showingInviteSent &&
                                <div className="sharingLinkCopied">
                                    <IconContext.Provider value={{ size: "20", className: "icon" }}>
                                        <IoIosCheckmarkCircleOutline />
                                    </IconContext.Provider>  
                                    <span className="ps-2">
                                        Invite(s) sent
                                    </span>
                                </div>
                            }
                            <h1 style={{ fontSize: "16pt", paddingTop: "10px", textAlign: "left", marginBottom: "20px" }}>
                                Share &nbsp;&nbsp;
                                <input type="radio" name="shareType" value="board" onChange={(e) => handleTypeRadio(e, "board")} checked={shareType === "board"} /> Board &nbsp;&nbsp;
                                <input type="radio" name="shareType" value="container" onChange={(e) => handleTypeRadio(e, "container")} checked={shareType === "container"}  /> Container
                            </h1>
                            <div style={{ display:"inline-block", width: "60%", marginRight: "2%", verticalAlign: "top" }}>
                                <div>
                                    {selectedEmails.length > 0 ?
                                        <div className="mimicInput text-start">
                                            {selectedEmails.map((e, i) => {
                                                return (
                                                <div className="emailButton">
                                                    <span className="pe-2">{e}</span>
                                                    <div onClick={(e) => removeSelectedEmail(e,i)} 
                                                        style={{ display: "inline-block", cursor: "pointer"  }}>
                                                        <AiOutlineClose size={13} />
                                                    </div>
                                                </div>
                                                )
                                            })}
                                            <input type="text"   
                                                style={{ 
                                                    display: "inline-block",
                                                    border: "0",
                                                }} 
                                                onKeyUp={(e) => handleShowingEmailInviteBoxKeyUp(e)}
                                                onChange={(e) => handleShowingEmailInviteBoxChange(e)}
                                                value={emailInviteBoxValue}
                                            />
                                        </div>
                                    :
                                        <input type="text" placeholder="Email address" style={{ width:"100%", height: "40px" }} 
                                            onKeyUp={(e) => handleShowingEmailInviteBoxKeyUp(e)}
                                            onChange={(e) => handleShowingEmailInviteBoxChange(e)}
                                            value={emailInviteBoxValue}
                                        />
                                    }
                                </div>
                                {showingEmailInviteBox &&
                                    <div className="emailInviteBox">
                                        {showingEmailInviteBoxEmail ?
                                            <div className="emailInviteBoxInner" onClick={(e) => handleSelectEmail(e)}>
                                                <div className="row g-0 align-items-center">
                                                    <div className="col-2">
                                                        <IconContext.Provider value={{ size: "35", color: "#000", className: "avatarIcon" }}>
                                                            <FaUserCircle />
                                                        </IconContext.Provider>
                                                    </div>
                                                    <div className="col-10 text-start ps-1">
                                                        {emailInviteBoxValue}
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                            <div style={{ padding: "10px 15px" }}>
                                                Add their email address to invite them
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            <div onClick={(e) => toggleDropDownHighlight(e)} 
                                style={{ position: "relative", display:"inline-block", width: "22%", marginRight: "2%", verticalAlign: "top" }}>
                                <div className={dropDownClass}>
                                    <div>
                                        <span>
                                            {selectedOption}    
                                        </span>
                                        <IconContext.Provider value={{ size: "20", className: "dropDownIcon" }}>
                                            <MdKeyboardArrowDown />
                                        </IconContext.Provider>                                
                                    </div>
                                </div>
                                {showingDropDown === true &&
                                    <div className="dropDownOptionsContainer">
                                        <div className={`dropDownOption ps-2 pt-2 pb-1 ${memberSelected}`} onClick={(e) => selectOption(e, "Member")}>
                                            <div className={`dropDownOptionsTitle`}>Member</div>
                                            {shareType === "board" ?
                                                <div className={`dropDownOptionsDescription`}>Board members can view and edit cards, lists, and some board settings.</div>
                                            :
                                                <div className={`dropDownOptionsDescription`}>Container members can view and edit the container's boards, cards, lists, and some settings.</div>
                                            }
                                        </div>
                                        <div className={`dropDownOption ps-2 pt-1 pb-1 ${observerSelected}`} onClick={(e) => selectOption(e, "Observer")}>
                                            <div className={`dropDownOptionsTitle`}>Observer</div>
                                            <div className={`dropDownOptionsDescription`}>{shareType} observers can view and comment.</div>
                                        </div>
                                        <div className={`dropDownOption ps-2 pt-1 pb-3 ${adminSelected}`} onClick={(e) => selectOption(e, "Admin")}>
                                            <div className={`dropDownOptionsTitle`}>Admin</div>
                                            <div className={`dropDownOptionsDescription`}>Admins can have full rights, including managing {shareType} access.</div>
                                        </div>
                                    </div>
                                } 
                            </div>
                            <div style={{ display:"inline-block", width: "14%", verticalAlign: "top", position: "relative", top: "7px" }}>
                                <Link to="" onClick={(e) => handleShareViaEmail(e)} className="btn-orange" style={{ width: "100%", paddingTop: "10px", paddingBottom: "10px" }}>
                                    Share
                                </Link> 
                            </div>
                            {showingEmailShareDescription &&
                                <div className="row gx-0" style= {{ width: "100%", paddingTop: "7px" }}>
                                    <textarea style={{ width:"100%" }} 
                                        value={emailShareDescription}
                                        onChange={(e) => handleChangeEmailShareDescription(e)}
                                        placeholder="Join me on Herdr and let's work together!" />
                                </div>
                            }
                            <div className="row gx-0" style= {{ width: "100%", paddingTop: "7px" }}>
                                <div className="col-1" style={{ marginTop: "10px" }}>
                                    <div  style={{ width: "30px", marginTop: "7px", borderRadius: "5px", backgroundColor: "#efefef", padding: "5px" }}>
                                        <div className="col-4">
                                            <FiLink size={16} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6" style={{ marginTop: "10px", textAlign:"left" }}>
                                    <span style={{ fontSize: "11pt", fontWeight: "500" }}>
                                        Share this {shareType} with a link
                                    </span>
                                    <br /> 
                                    {(project?.shareLink !== undefined && project?.shareLink !== "" && shareType === "board") || 
                                     (container?.shareLink !== undefined && container?.shareLink !== "" && shareType === "container") ?
                                        <Link to="" 
                                            onClick={(e) => copyLink(e)}
                                            style= {{ position: "relative", top: "-5px", fontSize: "10pt", color: "#007AFF", fontWeight: "500" }}>
                                            Copy Link
                                        </Link>
                                    :
                                        <Link to="" 
                                            onClick={(e) => createLink(e)}
                                            style= {{ position: "relative", top: "-5px", fontSize: "10pt", color: "#007AFF", fontWeight: "500" }}>
                                            Create Link
                                        </Link>
                                    }
                                </div>
                                {((project?.shareLink !== undefined && project?.shareLink !== "" && shareType === "board") ||
                                 (container?.shareLink !== undefined && container?.shareLink !== "" && shareType === "container")) &&
                                    <div className="col-5" style={{ marginTop: "10px", textAlign:"right" }}>
                                        <div onClick={(e) => toggleShareLinkDropDownHighlight(e)} 
                                            style={{ display:"inline-block", width: "106px" }}>
                                            <div className={shareLinkDropDownClass}>
                                                <div>
                                                    <span>
                                                        {shareLinkSelectedOption}    
                                                    </span>
                                                    <IconContext.Provider value={{ size: "20", className: "dropDownIcon" }}>
                                                        <MdKeyboardArrowDown />
                                                    </IconContext.Provider>                                 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <>
                            {showingShareLinkDropDown === true &&
                                <div className="shareLinkDropDownOptionsContainer" >
                                    <div className={`dropDownOption ps-2 pt-2 pb-1 ${shareLinkMemberSelected}`} onClick={(e) => selectShareLinkOption(e, "Member")}>
                                        <div className={`dropDownOptionsTitle`}>Can join as member</div>
                                        {shareType === "board" ?
                                            <div className={`dropDownOptionsDescription`}>Board members can view and edit cards, lists, and some board settings.</div>
                                        :
                                            <div className={`dropDownOptionsDescription`}>Container members can view and edit the container's boards, cards, lists, and some settings.</div>
                                        }
                                    </div>
                                    <div className={`dropDownOption ps-2 pt-1 pb-1 ${shareLinkObserverSelected}`} onClick={(e) => selectShareLinkOption(e, "Observer")}>
                                        <div className={`dropDownOptionsTitle`}>Can join as observer</div>
                                        <div className={`dropDownOptionsDescription`}>{shareType} observers can view and comment.</div>
                                    </div>
                                    <div className={`dropDownOption ps-2 pt-1 pb-3 ${shareLinkAdminSelected}`} onClick={(e) => selectShareLinkOption(e, "Admin")}>
                                        <div className={`dropDownOptionsTitle`}>Can join as admin</div>
                                        <div className={`dropDownOptionsDescription`}>Admins can have full rights, including managing {shareType} access.</div>
                                    </div>
                                    <div className={`dropDownOption ps-2 pt-1 pb-3`} onClick={(e) => deleteShareLink(e)}>
                                        <div className={`dropDownOptionsTitle`}>Delete link</div>
                                        <div className={`dropDownOptionsDescription`}>The existing {shareType} share link will no longer work.</div>
                                    </div>
                                </div>
                            }
                            </>{/*position: "relative", */}
                            <div style={{ height: "44vh" }}>
                                <div style={{ height: "100%", overflowY: "auto", marginTop: "15px" }}>
                                {members.map((p, i) => {
                                    
                                    //topAdj = (i === 0) ? 60 : topAdj + 62;
                                    let dropDownContainerStyle = { left: "25%", top: "24%" };
                                    //let dropDownContainerStyle = { top: topAdj + "px" }
                                    //let style = (i % 2 === 0) ? { borderRadius: "5px", paddingTop: "15px", paddingBottom: "15px", backgroundColor: "#efefef" } : { paddingTop: "15px", paddingBottom: "15px", backgroundColor: "#fff" };
                                    let memberPermissionOption = p.memberType?.charAt(0).toUpperCase() + p.memberType!.slice(1);
                                    let memberListDropDownClass = (showingMemberDropDown[i] === true) ? "dropDownSelected" : "dropDown";
                                    const memberMemberSelected = (memberPermissionOption === "Member") ? "selected": "";
                                    const memberObserverSelected = (memberPermissionOption === "Observer") ? "selected": "";
                                    const memberAdminSelected = (memberPermissionOption === "Admin") ? "selected": "";

                                    return (
                                        <div key={i} className="row gx-0 w-100" style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                            <div className="col-1 d-flex align-items-center text-start">
                                            {p.status === "invited" && p.firstName === "" && p.lastName === "" ?
                                                    <IconContext.Provider value={{ className: "imgSmallAvatar" }}>
                                                        <FaUserCircle />
                                                    </IconContext.Provider>  
                                                :
                                                    <Avatar size="small" projectMember={p} />
                                            }
                                            </div>
                                            <div className="col-7 text-start ps-1">
                                                {p.status === "invited" && p.firstName === "" && p.lastName === "" ? 
                                                    <div style={{ fontSize:"11pt", fontWeight: "500", fontStyle: "italic" }}>
                                                        Pending Invite
                                                    </div>
                                                :
                                                    <div style={{ fontSize:"11pt", fontWeight: "500" }}>
                                                        {p.me ?
                                                            <b>{p.firstName} {p.lastName} (you)</b>
                                                        :
                                                            <>{p.firstName} {p.lastName}</>
                                                        }
                                                    </div>
                                                }
                                                <div style={{ color: "gray", fontSize: "10pt", fontWeight: "bold" }}>
                                                    {p.email} &bull; {p.memberType}
                                                </div>
                                            </div>
                                            <div className="col-4 text-end">
                                                {memberPermissionOption === "Owner" ?
                                                    <div style={{ display:"inline-block", width: "115px", textAlign: "center", 
                                                        fontWeight: "bold", fontStyle: "italic" }}>
                                                        {memberPermissionOption}    
                                                    </div>
                                                :
                                                    <div onClick={(e) => toggleMemberDropDownHighlight(e, i)} 
                                                        style={{ display:"inline-block", width: "115px" }}>
                                                        <div className={`pe-1 ${memberListDropDownClass}`}>
                                                            <div>
                                                                <span>
                                                                    {memberPermissionOption}    
                                                                </span>
                                                                <IconContext.Provider value={{ size: "20", className: "dropDownIcon" }}>
                                                                    <MdKeyboardArrowDown />
                                                                </IconContext.Provider>                                 
                                                            </div>
                                                        </div>
                                                        {showingMemberDropDown[i] === true &&
                                                            <div className="dropDownOptionsContainer" style={dropDownContainerStyle}>
                                                                <div className={`dropDownOption ps-2 pt-2 pb-1 ${memberMemberSelected}`} onClick={(e) => updateMembersPermission(e, "member", p.email)}>
                                                                    <div className={`dropDownOptionsTitle`}>Member</div>
                                                                    {shareType === "board" ?
                                                                        <div className={`dropDownOptionsDescription`}>Board members can view and edit cards, lists, and some board settings.</div>
                                                                    :
                                                                        <div className={`dropDownOptionsDescription`}>Container members can view and edit the container's boards, cards, lists, and some settings.</div>
                                                                    }
                                                                </div>
                                                                <div className={`dropDownOption ps-2 pt-1 pb-1 ${memberObserverSelected}`} onClick={(e) => updateMembersPermission(e, "observer", p.email)}>
                                                                    <div className={`dropDownOptionsTitle`}>Observer</div>
                                                                    <div className={`dropDownOptionsDescription`}>{shareType} observers can view and comment.</div>
                                                                </div>
                                                                <div className={`dropDownOption ps-2 pt-1 pb-3 ${memberAdminSelected}`} onClick={(e) => updateMembersPermission(e, "admin", p.email)}>
                                                                    <div className={`dropDownOptionsTitle`}>Admin</div>
                                                                    <div className={`dropDownOptionsDescription`}>Admins can have full rights, including managing {shareType} access.</div>
                                                                </div>
                                                                <div className={`dropDownOption ps-2 pt-1 pb-3`} onClick={(e) => deleteMemberFromBoard(e, p.email, p.me)}>
                                                                    <div className={`dropDownOptionsTitle`}>Remove from {shareType}</div>
                                                                    <div className={`dropDownOptionsDescription`}>Remove the member or invite from the {shareType}.</div>
                                                                </div>
                                                            </div>
                                                        } 
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    );
}

export default SharingContext;