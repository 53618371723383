import React from "react";
import * as Icons from "react-icons/fa";
// @ts-ignore
const DynamicFaIcon = ({ name, size, color }) => {
  let iconName = (name === "") ? "FaFolder" : name; 
  // @ts-ignore
  const IconComponent = Icons[iconName];
  return <IconComponent size={size} color={color} />;
};

export default DynamicFaIcon;