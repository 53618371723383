import { IBaseModel } from './IBaseModel';

export interface Label extends IBaseModel {
    id: number;
    projectId: number;
    title: string;
    backgroundColor: string;
    fontColor: string;
}

export const EmptyLabel = {
    id: 0,
    projectId: 0,
    created: new Date(),
    modified: new Date(),
    active: false,
    title: "",
    backgroundColor: "",
    fontColor: "",
}