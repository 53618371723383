import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

//import Footer from './Components/Footer';
//import Login from './Pages/Public/Login';
import Home from './Pages/Public/Home';
import MemberHome from './Pages/Private/MemberHome';
import Privacy from './Pages/Public/Privacy';
import Terms from './Pages/Public/Terms';
import Products from './Pages/Public/Products';
import WhyHerdr from './Pages/Public/WhyHerdr';
import Team from './Pages/Public/Team';
import Pricing from './Pages/Public/Pricing';
import ProjectInvite from './Pages/Public/ProjectInvite';
import ContainerInvite from './Pages/Public/ContainerInvite';
import NotFound404 from './Pages/Public/NotFound404';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { refreshAccessToken, getUser } from './Store/Auth';
import { getProject, getProjects, getLists, getCards, getLabels, getLabelsToCards, 
  getInvitesToProjects, getProjectMembers, getComments , getContainers, 
  getContainerMembers
} from './Store/Project';
import { getUserSettings, heartBeat } from './Store/Settings';
import { useAppSelector, useAppDispatch } from './Core/hooks';

interface ComponentProps {
  children ?: React.ReactNode;
}

type Props = ComponentProps;

function App(props: Props) {
  
  const dispatch = useAppDispatch();
  const userState = useAppSelector(getUser);
  const projectState = useAppSelector(getProject);

  useEffect (() => {
    dispatch(refreshAccessToken());
    /* hearbeat to stay logged in */
    window.setInterval(() => {
      dispatch(heartBeat());
    }, 60000);
  },[]);

  useEffect (() => {
    if (userState.accessToken !== "" && projectState.projects.length === 0) {
      dispatch(getUserSettings());
      dispatch(getContainers());
    }
  }, [userState.accessToken]);

  useEffect (() => {
    if (projectState.status === "containersFetched") {
      dispatch(getProjects());
    }
  }, [projectState.status]);

  useEffect (() => {
    if (projectState.status === "inviteAccepted") {
      dispatch(getProjects());
      dispatch(getContainers());
    }
  }, [projectState.status]);

  useEffect (() => {
    if (projectState.projects.length > 0) {
      let loadedProject = projectState.projects.find(p => p.loaded);
      if (loadedProject) {
        dispatch(getComments(loadedProject.id));
        dispatch(getProjectMembers(loadedProject.id));
        dispatch(getInvitesToProjects(loadedProject.id));
        dispatch(getLabelsToCards(loadedProject.id));
        dispatch(getLabels(loadedProject.id));
        dispatch(getCards(loadedProject.id));
        dispatch(getLists(loadedProject.id));
      }
    }
  }, [projectState.projects]);

  useEffect (() => {
    if (projectState.containers.length > 0) {
      let loadedContainer = projectState.containers.find(c => c.loaded);
      if (loadedContainer) {
        dispatch(getContainerMembers(loadedContainer.id));
      }
    }
  }, [projectState.containers]);

  useEffect (() => {
    if (projectState.lists.length > 0) {
      let loadedProject = projectState.projects.find(p => p.loaded);
      if (loadedProject) {
        dispatch(getLists(loadedProject.id));
      }
    }
  }, [projectState.projects]);


  return (
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
        />
        {/*<Navigation />*/}  
        <Routes>
          <Route path="/">
            <Route path=":ac" element={<Home />} />
            <Route path="" element={<Home />} />
          </Route>
          <Route path="/project/invite/">
            <Route path=":project/:link" element={<ProjectInvite />} />
          </Route>
          <Route path="/container/invite/">
            <Route path=":container/:link" element={<ContainerInvite />} />
          </Route>
          <Route path='/members' element={<MemberHome />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/products' element={<Products />} />
          <Route path='/why-herdr' element={<WhyHerdr />} />
          <Route path='/team' element={<Team />} />
          <Route path='/pricing' element={<Pricing />} />

          {/*
          <Route path='/' element={<Login/>} />       
          <Route path='/admin' element={<Admin/>} />
          <Route path='/inventory' element={<Inventory/>} />
          <Route path='/loads' element={<Loads/>} />
          <Route path='/load/:loadId/:scheduleDate' element={<Receipt />} />          
          <Route path='/process' element={<Process/>} />
          <Route path='/reports' element={<Reports/>} />
          */}
          <Route path='*' element={<NotFound404 />} />
        </Routes>
      </Router>
  );
}

export default App;
