import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector, useAppDispatch } from '../../Core/hooks';
import { getUser, login, setLoggingIn, setCurrentPage } from '../../Store/Auth';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';
import Registration from '../../Modals/Registration';
import Login from '../../Modals/Login';

function Home() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useAppSelector(getUser);
    const toastId = React.useRef(0);
    const [showLogin, setShowLogin] = useState(false);
    const [showRegistration, setShowRegistration] = useState(false);
    const [activationCode, setActivationCode] = useState<string | null>(null);
    const [recoveryCode, setRecoveryCode] = useState<string | null>(null);
    const [queryParams] = useSearchParams();
    // Get a specific query parameter
    

    useEffect(() => {
        dispatch(setCurrentPage("home"));
        setActivationCode(queryParams.get('ac'));
        setRecoveryCode(queryParams.get('ar'));
    }, []);

    useEffect(()=>{
        if (activationCode) {
            setShowLogin(true);
        }
    },[activationCode]);

    useEffect(() => {
        if (user.accessToken !== "" && user.status !== "loading"){
            //dispatch(setLoggingIn(false));
            //navigate('/members/login');
            navigate('/members');
        }
    }, [user]);

    const toggleLogin = (visible: boolean) => {
        setShowRegistration(false);
        setShowLogin(visible);
    }
 
    const toggleRegistration = (visible: boolean) => {
        setShowLogin(false);
        setShowRegistration(visible);
    }

    const showRegistrationModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setShowRegistration(true);
    }

    return (
    <>
        <Navigation />
        <div className="text-center">

            <div style={{ marginTop: "60px", marginBottom: "20px", fontSize: "14pt", fontWeight: "bold", color: "gray" }}>
                Herd Your Resources with Ease
            </div>

            <h1 className="h1Home" style={{ marginBottom: "20px" }}>
                Get the Most Out of Your Team
            </h1>
            
            <p className="pHome">
                See who's cruising and who's drowning in tasks. Herdr.io makes it easy to spot the sweet spot between "just enough" and "too much" work. 
                Keep your team balanced and your projects on track—without anyone burning out.
            </p>

            <p style={{ marginTop: "20px" }}>
                <img className="imageHome" src='images/capacity-grid-2.png' alt='Project kanban board for managing tasks' 
                    style={{ padding: "10px", borderRadius: "5px", border: "1px solid #efefef" }} />
            </p>

            <p className="mt-4 pt-4 buttonHome">
                <Link to="/" className="orange-signup-button" onClick={(e) => showRegistrationModal(e)}>
                    Master Stress-Free Work
                </Link>
            </p>

            <h2 className="h1Home" style={{ marginBottom: "20px", marginTop: "50px" }}>
                Group Boards with Ease
            </h2>

            <p className="pHome">
                Herdr provides AI-driven roadmap, product, project, action plan, and resource management. 
                Simply share access and AI will do the rest as your teams go about managing and completing work in an agile manner.
            </p>

            <p style={{ marginTop: "20px" }}>
                <img className="imageHome" src='images/herdr-board-splash-3.png' alt='Project kanban board for managing tasks' 
                    style={{ padding: "10px", borderRadius: "5px", border: "1px solid #efefef" }} />
            </p>

            <h2 className="h1Home" style={{ marginBottom: "20px", marginTop: "50px" }}>
                Easily Manage Lists and Cards
            </h2>

            <p className="pHome">
                Keep your to-do lists and tasks in check without losing your sanity! Herdr.io lets you 
                effortlessly juggle lists and cards, moving things around as easily as you shuffle through 
                your morning coffee options. Stay organized, stay on track, and keep everything flowing 
                smoothly-no sweat required.
            </p>

            <p style={{ marginTop: "20px" }}>
                <img className="imageHome" src='images/herdr-board-splash-2.png' alt='Project kanban board for managing tasks' 
                    style={{ padding: "10px", borderRadius: "5px", border: "1px solid #efefef" }} />
            </p>
{/*
            <p className="pHomeImage">
               <img src='images/herdr-board-splash.png' alt='Project kanban board for managing tasks' 
                style={{ width: "100%", borderRadius: "5px" }} />
            </p>
*/}

{/*
            <h2 className="pHome" style={{ fontWeight: "bold", fontSize: "16pt" }}>
                Why is Herdr free to use?
            </h2>
           
            <p className="pHome">
                Herdr is <strong>100% free-to-use as we continue to refine and enhance our product</strong>. 
                Our commitment to accessibility means that for the foreseeable future, you can enjoy our simple, 
                intuitive board with lists and cards without any cost. Herdr is currently best suited for task 
                management on computers or tablets due to its lack of mobile optimization.
            </p>
 */}
            <p className="mt-4 pt-4">
                <Link to="/" className="orange-signup-button" onClick={(e) => showRegistrationModal(e)}>
                    Sign up for FREE
                </Link>
            </p>
            <br/><br/>
        </div>
        <Login visible={showLogin} visibleHandler={toggleLogin} activationCode={activationCode} recoveryCode={recoveryCode} />
        <Registration visible={showRegistration} visibleHandler={toggleRegistration} />
        <Footer />
    </>
    );
}

export default Home;