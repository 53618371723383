import React from 'react';
import { useEffect, useState, createElement } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { ContextMenu } from '../Models/ContextMenu';
import { ContainerContextParam } from'../Models/Requests/ContainerContextParam';
import { AddContainerContextParam } from '../Models/Requests/AddContainerContextParam';
import { AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";
import { FiFolderPlus } from 'react-icons/fi';
import { FaRegTrashAlt, FaPencilAlt } from 'react-icons/fa';
import { getProject, loadContainer, unloadProject, setStatus, deleteContainer, setContainers } from "../Store/Project";
import { Container } from "../Models/Container";
import { IconContext, IconType } from "react-icons";
import AddContainerContextMenu from './AddContainerContextMenu';
import DynamicFaIcon from './DynamicFaIcon';
import PopupContextMenu from './PopupContext';
import { PopupContextParam } from '../Models/Requests/PopupContextParam';

interface Props {
    params: ContainerContextParam | undefined;    
}

function ContainerContext(props: Props) {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const projectState = useAppSelector(getProject);
    //const [containerToDelete, setContainerToDelete] = useState<number>(0);
    const [containerContext, setContainerContext] = useState<ContextMenu>();
    const [addContainerContextParams, setAddContainerContextParams] = useState<AddContainerContextParam>();
    const [popupParams, setPopupParams] = useState<PopupContextParam>();
    //const [showContainerForm, setShowContainerForm] = useState<boolean>(false);
  
    useEffect(() => {
        if (projectState.status === "containerLoaded") {
            dispatch(unloadProject());
        } else if (projectState.status === "projectUnloaded") {
            closeMenu();
            dispatch(setStatus("idle"));
        }
    }, [projectState.status]);

    useEffect(() => {
        let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        if (props.params) {
            //let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
            if ((containerContext && containerContext.id !== props.params.id) || !containerContext) {
                /*
                let menuWidth = window.outerWidth * .4;
                let menuHeight = window.outerHeight * .65;
                let menuXStart = window.outerWidth * .30;
                let menuYStart = 20;

                if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }
*/
                contextMenu.id = props.params.id;
                /*
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                contextMenu.width = menuWidth;
                contextMenu.height = menuHeight;
                */
            }
            //setContainerContext(contextMenu);
            //setAddContainerContextParams(undefined);
        } 
        setContainerContext(contextMenu);
        setAddContainerContextParams(undefined);
    }, [props.params]);


    const closeContextMenu = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        closeMenu();
    }

    const closeMenu = () => {
        //resetState(); 
        let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        setContainerContext(contextMenu);
        //console.log(contextMenu);
        /*
        if (props.params) {
            props.params.parentCloseHandler();
        }*/
    }

    const closeAddContainerWindow = (e: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLAnchorElement>) => {
        setAddContainerContextParams(undefined);
    }

    const showAddContainerForm = (e: React.MouseEvent<HTMLDivElement>) => {
        //setShowContainerForm(true);
        setAddContainerContextParams({ id: 1, event: e, closeHandler: closeAddContainerWindow, editing: false })
    
    }

    const handleLoadContainer = (e: React.MouseEvent<HTMLDivElement>, container: Container) => {
        if (container.loaded) {
            /* already loaded, just close the window so projects don't get unloaded */
            closeMenu();
        } else {
            dispatch(loadContainer(container.id));
        }
    }

    const resetState = () => {
    }

    const trashContainer = (e: React.MouseEvent<HTMLDivElement>, c: Container) => {
        e.stopPropagation();
        e.preventDefault();
        //setContainerToDelete(c.id);
        setPopupParams({
            id: c.id,
            event: e,
            buttonClass: "btnDelete",
            buttonText: "Delete",
            closeHandler: closeTrashContainerPopup,
            buttonHandlerWithId: trashContainerConfirmed,
            message: "This will remove the container and all boards within it. There is no undo.",
            headingText: "Delete Container",
            backButton: false,
        });
    }

    const closeTrashContainerPopup = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        //setContainerToDelete(0);
        /* Key here is to zero-out label */
        setPopupParams({
            id: 0,
            event: e,
            buttonClass: "",
            buttonText: "",
            closeHandler: closeTrashContainerPopup,
            buttonHandlerWithId: trashContainerConfirmed,
            message: "",
            headingText: "",
            backButton: false,
        });
    }

    const trashContainerConfirmed = (e: React.MouseEvent<HTMLDivElement>, id: number) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(deleteContainer(id));  
        /* delete on front end */
        const containers: Array<Container> = [...projectState.containers];
        const index = projectState.containers.findIndex((i) => {
            return i.id === id;
        });
        containers.splice(index, 1);
        dispatch(setContainers(containers));
        
        setPopupParams({
            id: 0,
            // @ts-ignore:next-line
            event: null,
            buttonClass: "",
            buttonText: "",
            closeHandler: closeTrashContainerPopup,
            buttonHandlerWithId: trashContainerConfirmed,
            message: "",
            headingText: "",
            backButton: false,
        });
    }

    const editContainer = (e: React.MouseEvent<HTMLDivElement>, c: Container) => {
        e.stopPropagation();
        e.preventDefault();
        setAddContainerContextParams({ id: c.id, event: e, closeHandler: closeAddContainerWindow, editing: true })
        //setContainerToDelete(c.id);
        /*
        setPopupParams({
            id: c.id,
            event: e,
            buttonClass: "btnDelete",
            buttonText: "Delete",
            closeHandler: closeTrashContainerPopup,
            buttonHandlerWithId: trashContainerConfirmed,
            message: "This will remove the container and all boards within it. There is no undo.",
            headingText: "Delete Container",
            backButton: false,
        });
        */
    }
 
    let project = projectState.projects.find(p => p.loaded);

    const sContextMenu: React.CSSProperties = {
        position: "relative",
        width: "100%",
        height: "100%",
        borderRadius:"0",
        color: "#fff",
        //top: (containerContext) ?  containerContext.y : 0,
        //right: (sharingContext) ? sharingContext.x : 0,
        //left: (settingsContext) ? settingsContext.x : 0,
        //width: (sharingContext) ? sharingContext.width : 0,
        //height: (sharingContext) ? sharingContext.height : 0,
    }

    const formattedContainers = projectState.containers.map((c, i) => {
        let name = (c.name.length > 30) ? c.name.substring(0,29).toUpperCase() : c.name.toUpperCase();
        let iconRowStyle: React.CSSProperties = (c.loaded) ? { backgroundColor: "#BA4A00", height: "15vh" } : { height: "15vh" };
        return (
            <>
                {i === 0 &&
                    <div className="col-2" style={{ marginTop: "24px" }} onClick={(e) => showAddContainerForm(e)}>
                        <div className="row iconRow align-items-center" style={{ backgroundColor: "gray", height: "15vh" }}>
                            <div className="col-2">
                                <div className="containerSplashButton" style={{ paddingLeft: "5px" }}>
                                    <IconContext.Provider value={{ color: "#fff", size: "25" }}>
                                        <FiFolderPlus size="25" />
                                    </IconContext.Provider>
                                </div>
                            </div>
                            <div className="col-10">
                                <div style={{ paddingLeft: "10px", fontSize: "10pt", color: "#efefef" }}>NEW CONTAINER</div>
                            </div>
                        </div>
                    </div>
                }
                <div className="col-2" style={{ marginTop: "24px" }} onClick={(e) => handleLoadContainer(e, c)}>
                    <div className="row iconRow align-items-center" style={iconRowStyle}>
                        <div className="col-2">
                            <div className="containerSplashButton" style={{ paddingLeft: "5px" }}>
                                <IconContext.Provider value={{ color: "#fff", size: "25" }}>
                                    <DynamicFaIcon name={c.icon} size="25" color="#fff"  />
                                </IconContext.Provider>
                            </div>
                        </div>
                        <div className="col-7">
                            <div style={{ paddingLeft: "10px", fontSize: "10pt", color: "#efefef" }}>
                                {name}
                            </div>
                        </div>
                        <div className="col-3">
                            {(c?.permission?.toString() === "admin" || c?.permission?.toString() === "owner") &&
                                <div className="trashContainer" onClick={(e) => editContainer(e, c)}>
                                    <FaPencilAlt color="#fff" size="14" />
                                </div>
                            }
                            {c?.permission?.toString() === "owner" &&
                                <div className="trashContainer" onClick={(e) => trashContainer(e, c)}>
                                    <FaRegTrashAlt color="#fff" size="14" />
                                </div>
                            }
                        </div>
                        {/*c.permission?.toString() !== "owner" &&
                            <div className="sharedContainer">
                                Shared With You
                            </div>
                       */}
                    </div>
                </div>
            </>
        );
    })
    
    return (
        <>
        {containerContext !== undefined && containerContext.id > 0 &&
            <div onClick={e => closeMenu()} className="addContainerWindow">
                <div className="containerContextMenu" style={sContextMenu} onClick={e => { 
                    e.preventDefault();
                    e.stopPropagation(); 
                }}>
                    {/*
                    <div className="divClose">
                        <Link to="" onClick={(e) => closeContextMenu(e)}>
                            <AiOutlineClose color="#fff" size="20" />
                        </Link>
                    </div>
                    */}

                        
                    {projectState.containers.length === 0 ?  
                         <div className="sharingContext" style={{ margin: "auto 0px", textAlign: "center" }}
                              onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
                            <div style={{ width: "96%", margin: "0px auto" }}>  
                                <div className="addContainer" onClick={(e) => showAddContainerForm(e)}>
                                    <IconContext.Provider value={{ color: "#fff", size: "80" }}>
                                        <AiOutlinePlusCircle />
                                    </IconContext.Provider>
                                </div>
                                <h1 style={{ margin: "20px auto 0px auto", width: "400px" }}>
                                    Add Container
                                </h1>
                                <div style={{ margin: "20px auto 0px auto", width: "400px", textAlign: "justify" }}>
                                    Containers are like folders. They allow you to group like boards, such as school, work, 
                                    and play. To create boards, you must first create a container. Click the orange button 
                                    to get started
                                    
                                </div>
                            </div>
                        </div>
                    :
                        <div className="sharingContext" style={{ marginTop: "20px" }}
                              onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
                            <div style={{ width: "90%", margin: "0px auto" }}> 
                                <div className="row">
                                    {formattedContainers}
                                </div>
                            </div>
                        </div> 
                    }
                       
                  
                </div>
                <AddContainerContextMenu params={addContainerContextParams}  />
            </div>
        }
        <PopupContextMenu params={popupParams} />
        </>
    );
}

export default ContainerContext;