import { useRef, useEffect } from 'react';
declare const google: any;

interface Props {
    loginHandler: (response: google.accounts.id.CredentialResponse) => void;
    buttonText?: string;
}

const GoogleSSO = (props: Props) => {

    const g_sso = useRef(null);

    useEffect(() => {
        if (g_sso.current) {
            google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                callback: (res: any, error: any) => {
                    props.loginHandler(res);
                },
            });
            google.accounts.id.renderButton(g_sso.current, {
                type: "standard",
                theme: "outline", 
                size: "large",
                text: props.buttonText
            });
        }
    }, [g_sso.current]);

    return <div className="googleButton" ref={g_sso} />;
}


export default GoogleSSO 