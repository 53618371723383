import ReactModal from 'react-modal';
import React, { useState, useEffect, useRef } from 'react';
//import { USER_REGISTRATION_MODAL_SETTINGS } from '../Helpers/Constants';
import { AiOutlineClose } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { isValidEmail } from '../Helpers/isValidEmail';
//import { addEditUserModeType } from '../Helpers/types';
//import { User } from '../Models/User';
import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { register, socialLogin, getUser } from '../Store/Auth';
import { toast } from 'react-toastify';
import GoogleSSO from '../Components/GoogleSso';
import { decodeToken } from '../Helpers/DecodeToken';
import { acceptProjectInvite, acceptProjectInvitePublic,
    acceptContainerInvite, acceptContainerInvitePublic
 } from '../Store/Project';

interface Props {
    visible: boolean;
    visibleHandler: (visible: boolean) => void;
    projectId?: number | null;
    containerId?: number | null;
    link?: string | null;
}

function Registration(props: Props) {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const toastId = useRef(0);
    const [email, setEmail] = useState("");
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [password, setPassword] = useState("");
    //const [user, setUser] = useState<User | any | null>(null);
    const user = useAppSelector(getUser);

    useEffect(() => {
        /* don't use id 1 because login component does */
        if (toastId.current === 2) {
            if (user.accessToken !== "" && user.status === "idle") {
                /* Social registration, no activation needed */
                if(props.projectId && props.link) {
                    dispatch(acceptProjectInvite({ projectId: props.projectId, link: props.link }));
                }
                if(props.containerId && props.link) {
                    dispatch(acceptContainerInvite({ containerId: props.containerId, link: props.link }));
                }
                 navigate('/members');
            } else if (user.accessToken === "" && user.status === "idle") {
                /* Stand alone registration, activation needed */
                if(props.projectId && props.link) {
                    dispatch(acceptProjectInvitePublic({ projectId: props.projectId, link: props.link, email: email }));
                }
                if(props.containerId && props.link) {
                    dispatch(acceptContainerInvitePublic({ containerId: props.containerId, link: props.link, email: email }));
                }
            }else if (user.status === "failed") {
                toast.error(user.errorMessage);
            }
        }
    }, [user.status]);

    function processGoogleLogin(response: google.accounts.id.CredentialResponse){
        const payload = decodeToken(response.credential as string);
        //console.log(payload);
        if (payload) {
            toastId.current = 2;
            dispatch(socialLogin({ 
                email: payload.email, 
                firstName: payload.given_name,
                lastName: payload.family_name, 
                platform: "google",
                method: "registration",
                token: response.credential,
                picture: payload.picture,
            }));
        } else {
            toast.error("Social registration failed");
        }
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }

    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
    }

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);
    }

    const handleRegistration = (e: React.FormEvent<HTMLFormElement>) => {
        
        e.preventDefault();
        let email = e.currentTarget.email.value;
        let password = e.currentTarget.password.value;
        let firstName = e.currentTarget.firstName.value;
        let lastName = e.currentTarget.lastName.value;

        if (firstName === "") {
            toast.error("First name is required");
        } else if (lastName === "") {
            toast.error("Last name is required");
        } else if (!isValidEmail(email)) {
            toast.error("Email is required");
        } else if (password === "") {
            toast.error("Password is required");
        } else {
            toastId.current = 2;
            dispatch(register({
                email: email,
                password: password,
                firstName: firstName,
                lastName: lastName,
            }));
        }
    }

    const closeWindow = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        toastId.current = 0;
        props.visibleHandler(false) 
    }

    return (
        <ReactModal className="registerLoginModal" isOpen={props.visible} ariaHideApp={false}>
            <div className="modalHeader">
                <div className="text-end pe-3">
                    <Link to="/" onClick={(e) => { closeWindow(e)}}>
                        <AiOutlineClose size={20} />
                    </Link>
                </div>
            </div>

            <div className="row g-0 modalBody">
                <div className="col-12 ps-3 pe-3">
                    {user.status === "idle" && user.accessToken === "" && toastId.current === 2 ?
                        <div className="registration-success">
                            <strong>Thank you for registering to herd your cats</strong><br/><br/>
                            <p>
                            Please check your email for an account activation
                            link. You can close this window, click the link in your email, and you're all set to login 
                            and start taking names and kicking butt.
                            </p>
                        </div>
                     :
                        <React.Fragment>
                            <div className="row align-items-center ms-0 me-0">
                                <div className="col-12 text-center mb-3">
                                    <div>
                                        <img className="cat-only-75" src='/images/herdr-logo-cat-only.png' alt="herdr.io" />
                                    </div>
                                    <div style={{fontSize: "16pt"}}>Sign up to continue</div>
                                </div>
                            </div>
                            <GoogleSSO buttonText="signup_with" loginHandler={processGoogleLogin} />
                            <div className="row mb-2 mt-2 text-center ms-0 me-0">
                                <div className="col-12">
                                    OR
                                </div>
                            </div>
                            <form onSubmit={(e) => handleRegistration(e)} className="full-width-button">
                                <div className="pt-2">
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={firstName ?? ""}
                                        onChange={(e) => handleFirstNameChange(e)}
                                        placeholder="First Name" 
                                        className="form-control" />
                                </div>
                                <div className="pt-2">
                                    <input
                                        type="text"
                                        name="lastName"
                                        value={lastName ?? ""}
                                        onChange={(e) => handleLastNameChange(e)}
                                        placeholder="Last Name" 
                                        className="form-control" />
                                </div>
                                <div className="pt-2">
                                    <input
                                        type="text"
                                        name="email"
                                        value={email ?? ""}
                                        onChange={(e) => handleEmailChange(e)}
                                        placeholder="Email Address" 
                                        className="form-control" />
                                </div>
                                <div className="pt-2">
                                    <input
                                        type="password"
                                        name="password"
                                        value={password ?? ""}
                                        onChange={(e) => handlePasswordChange(e)}
                                        placeholder="Password"
                                        className="form-control" />
                                </div>
                                <div className="pt-2">
                                {/* <input type="hidden" name="id" value={user?.id ?? 0} /> */}
                                    <input className="orange-signup-button" type="submit" value="Sign up" />
                                </div>
                            </form>
                        </React.Fragment>
                    }
                </div>
            </div>
        </ReactModal>
    );
}

export default Registration;