import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { getUser } from '../Store/Auth';
import { getProject, deleteProject, setProjects, saveProject } from '../Store/Project';
import { Project } from '../Models/Project';
import { FaChevronCircleLeft, FaRegTrashAlt } from "react-icons/fa";
import { RiFolderSharedLine } from "react-icons/ri";
import MoveBoardContextMenu from './MoveBoardContextMenu';
import { MoveBoardContextMenuParam } from '../Models/Requests/MoveBoardContextMenuParam';

interface Props {
    visibilityHandler: (e: React.MouseEvent<HTMLAnchorElement>, show: boolean) => void
}

function Sidebar(props: Props) {

    const dispatch = useAppDispatch();
    //const navigate = useNavigate();
    //const user = useAppSelector(getUser);
    const projectState = useAppSelector(getProject);
    const [moveBoardContextMenuParams, setMoveBoardContextMenuParams] = useState<MoveBoardContextMenuParam>();
    //const toastId = React.useRef(0);
    //const [showRegistration, setShowRegistration] = useState(false);
    
    useEffect(() => {
    }, []);

    const trashProject = (e: React.MouseEvent<HTMLAnchorElement>, id: number) => {
        
        e.preventDefault();
        dispatch(deleteProject(id)); /* delete on backend */

        /* delete on front end */
        const projects: Array<Project> = [...projectState.projects];
        const index = projectState.projects.findIndex((i) => {
            return i.id === id;
        });
        projects.splice(index, 1);

        dispatch(setProjects(projects));
    }

    const loadProject = (id: number, name: string, loaded: boolean, mode: string) => {
        dispatch(saveProject({id: id, name: name, loaded: true, mode: mode, event: "switch"}));
        let projects = projectState.projects.map(p => {
            let project = {...p};
            if (p.id === id) {
                project.name = name;
                project.loaded = true;
            } else {
                project.loaded = false;
            }
            return project;
        });
        //console.log(projects);
        dispatch(setProjects(projects));
    }

    const toggleMoveProject = (e: React.MouseEvent<HTMLAnchorElement>, id: number) => {
        e.preventDefault();
        e.stopPropagation();
        setMoveBoardContextMenuParams({ projectId: id, event: e });   
    };
    
    /* Loaded container */
    let container = projectState.containers.find(c => c.loaded);
    /* loaded and owned projects */
    //let ownedProjects = projectState.projects.filter(p => p.permission?.toString() === "owner" && p.containerId === container?.id);
    let containerProjects = projectState.projects.filter(p => p.containerId === container?.id);

    /* shared projects should be boards that were shared individually, not through a container, so we only show
       boards that don't have a container match on the frontend because the user doesn't have access to the container 
     */
    let sharedProjects = projectState.projects.filter(p => p.permission?.toString() !== "owner" && p.containerId !== container?.id
     && projectState.containers.filter(c => c.id === p.containerId).length === 0 
    );

    let mOwnedProjects = containerProjects.map((project: Project, p: number) => {
        let loadedClass = (project.loaded) ? "loaded" : "notLoaded";
        return (
            <div className="row" key={p}>
                <div  style={{ cursor: "pointer" }} className={`col-8 ${loadedClass}`} onClick={() => loadProject(project.id, project.name, project.loaded, project.mode)}>
                    <span className="pe-2" style={{ cursor: "pointer" }}>{project.name}</span>
                </div>
                <div className="col-4">
                    {project.permission?.toString() === "owner" &&
                        <>
                            <Link to="" onClick={(e) => toggleMoveProject(e, project.id)} style={{ paddingRight: "10px" }}>
                                <RiFolderSharedLine color="gray" size="16" />
                            </Link>
                            <Link to="" onClick={(e) => trashProject(e, project.id)}>
                                <FaRegTrashAlt color="gray" size="12" />
                            </Link>    
                        </>
                    }
                </div>
            </div>
        )
    });

    let mSharedProjects = sharedProjects.map((project: Project, p: number) => {
        let loadedClass = (project.loaded) ? "loaded" : "notLoaded";
        return (
            <div className="row" key={p}>
                <div  style={{ cursor: "pointer" }} className={`col-10 ${loadedClass}`} onClick={() => loadProject(project.id, project.name, project.loaded, project.mode)}>
                    <span className="pe-2" style={{ cursor: "pointer" }}>
                        {project.name}
                    </span>
                </div>
                <div className="col-2">
                    {project.permission?.toString() === "owner" &&
                        <Link to="" onClick={(e) => trashProject(e, project.id)}>
                            <FaRegTrashAlt color="gray" size="12" />
                        </Link>
                    }
                </div>
            </div>
        )
    });

    return (
        <>        
            <div className="sidebar">
                <div style={{ width: "100%", textAlign: "right" }}>
                    <Link to="" onClick={(e) => props.visibilityHandler(e, false)}>
                        <FaChevronCircleLeft color="#fff" size="16" />
                    </Link>
                </div>
                <h2 className="category">{container?.name}</h2>
                {mOwnedProjects.length > 0 ?
                    <>{mOwnedProjects}</>
                :
                    <div><i>no boards</i></div>
                }
                {mSharedProjects.length > 0 &&
                    <>
                        <h2 className="subCategory mt-2">Boards Shared With Me</h2>
                        {mSharedProjects}
                    </>
                }
            </div>
            <MoveBoardContextMenu params={moveBoardContextMenuParams} />
        </>
    );
}

export default Sidebar;