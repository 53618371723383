import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { getProject, changeCardWork } from '../Store/Project';
import { ContextMenu } from '../Models/ContextMenu';
import { ChangeCardWorkContextMenuParams } from '../Models/Requests/ChangeCardWorkContextMenuParams';
import { AiOutlineClose } from "react-icons/ai";

interface Props {
    params: ChangeCardWorkContextMenuParams | undefined;    
}

function ChangeCardWorkContextMenu(props: Props) {

    const dispatch = useAppDispatch();
    const projectState = useAppSelector(getProject);
    const [status, setStatus] = useState("");
    const [changeCardWorkContextMenu, setChangeCardWorkContextMenu] = useState<ContextMenu>();
    const [weeks, setWeeks] = useState("");
    const [days, setDays] = useState("");
    const [hours, setHours] = useState("");
    const [minutes, setMinutes] = useState("");
    const [totalWorkInMinutes, setTotalWorkInMinutes] = useState(0);
/*
    useEffect(() => {
        if (projectState.status === "cardStatusChanged") {
            closeContextMenu();
        }
    }, [projectState.status]);
*/
    useEffect(() => {
        if (props.params) {
            let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
            if ((changeCardWorkContextMenu && changeCardWorkContextMenu.id !== props.params.cardId) || !changeCardWorkContextMenu) {

                //let menuWidth = window.outerWidth / 5;
                let menuWidth = 300;
                //let menuHeight = window.outerHeight * .7;

                //let numberOfLabels = projectState.labels.length;
                //let menuHeight = 145 + 50 * numberOfLabels;

                let menuXStart = props.params.event.clientX + 15;
                let menuYStart = props.params.event.clientY - 15;
                //alert(menuXStart + " : " + menuWidth + " : " + window.outerWidth);
                if (menuXStart > window.outerWidth) {
                    menuXStart = window.outerWidth - 10;
                }
                /*
                if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }
                */
                contextMenu.id = props.params.cardId;
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                contextMenu.width = menuWidth;
                //contextMenu.height = menuHeight;
                const card = projectState.cards.find(c => c.id === props.params?.cardId);
                if (card) {
                    //let timeLeft = 0;
                    let workInMinutes = (props.params.workType.toLowerCase() === "actual") ? card.actualWork : card.estimatedWork;
                    let weeks = Math.floor(workInMinutes / 2400);
                    //timeLeft =     
                    let days = Math.floor((workInMinutes - (weeks * 2400)) / 480);
                    let hours = Math.floor((workInMinutes - (weeks * 2400) - (days * 480)) / 60);
                    let minutes = Math.floor(workInMinutes - (weeks * 2400) - (days * 480) - (hours * 60));
                    
                    if (weeks > 0) {
                        setWeeks(weeks.toString());
                    }
                    if (days > 0) {
                        setDays(days.toString());
                    }
                    if (hours > 0) {
                        setHours(hours.toString());
                    }
                    if (minutes > 0) {
                        setMinutes(minutes.toString());
                    }
                }
            }
            setChangeCardWorkContextMenu(contextMenu);
           // refreshLabelSelections();
        }
    }, [props.params]);

    const closeContextMenu = (e?: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setMinutes("");
        setHours("");
        setDays("");
        setWeeks("");
        let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        setChangeCardWorkContextMenu(contextMenu);
    }

    const handleSaveCardWork = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();

        let totalMinutes = 0;

        if (weeks !== "") {
            totalMinutes += parseInt(weeks) * 40 * 60;
        }
        if (days !== "") {
            totalMinutes += parseInt(days) * 8 * 60;
        }
        if (hours !== "") {
            totalMinutes += parseInt(hours) * 60;
        }
        if (minutes !== "") {
            totalMinutes += parseInt(minutes);
        }

        //alert(totalMinutes);
        if (props.params?.workType) {
            dispatch(changeCardWork({
                workType: props.params.workType, 
                work: totalMinutes,
                cardId: props.params.cardId
            }));
            closeContextMenu();
        }
        /*
        if (props.params && props.params.cardId && status !== "") {
            dispatch(changeCardStatus({
                status: status,
                cardId: props.params.cardId,
            }));
            closeContextMenu();
        }*/
    }

    const handleWorkChange = (e: React.ChangeEvent<HTMLInputElement>, measurement: string) => {
        //e.preventDefault();
        //e.stopPropagation();
        let value = e.currentTarget.value;
        //let totalWorkTimeInMinutes = 0;

        if (measurement === "weeks") {
            if(isNaN(parseInt(value))){
                setWeeks("");
            } else {
                setWeeks(value);
            }
        } else if (measurement === "days") {
            if(isNaN(parseInt(value))){
                setDays("");
            } else {
                setDays(value);
            }
        } else if (measurement === "hours") {
            if(isNaN(parseInt(value))){
                setHours("");
            } else {
                setHours(value);
            }
        } else if (measurement === "minutes") {
            if(isNaN(parseInt(value))){
                setMinutes("");
            } else {
                setMinutes(value);
            }
        }

        //setTotalWorkInMinutes(totalWorkTimeInMinutes);
    }

    const sContextMenu: React.CSSProperties = {
        top: (changeCardWorkContextMenu) ? changeCardWorkContextMenu.y : 0,
        left: (changeCardWorkContextMenu) ? changeCardWorkContextMenu.x : 0,
        width: (changeCardWorkContextMenu) ? changeCardWorkContextMenu.width : 0,
        height: "auto",
        paddingBottom: "15px",
    }

    const card = projectState.cards.find(c => c.id === props.params?.cardId);
    let statuses = ["Open", "Started", "Completed"];
    const mappedStatuses = statuses.map((status, index) => {
        if (status !== card?.status) {
            return (
                <option value={status}>{status}</option>
            );
        }
    });

    return (
        <>
        {changeCardWorkContextMenu && changeCardWorkContextMenu.id > 0 &&
            <div onClick={e => closeContextMenu(e)} className="popupContainer">
                <div className="contextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation()}}>
                    <h1 style={{ marginBottom: "0px" }}>Change {props.params?.workType} Work</h1>
                    <div className="divClose">
                        <Link to="" onClick={(e) => closeContextMenu(e)}>
                            <AiOutlineClose color="#555" size="13" />
                        </Link>
                    </div>
                    <div style={{ textAlign: "center", paddingTop: "10px" }}>
                        <div>
                            <input type="text" className="workInput" value={weeks} onChange={(e) => handleWorkChange(e, "weeks")} />
                            <span className="workSpan">W</span>
                            <input type="text" className="workInput" value={days} onChange={(e) => handleWorkChange(e, "days")} />
                            <span className="workSpan">D</span>
                            <input type="text" className="workInput" value={hours} onChange={(e) => handleWorkChange(e, "hours")} />
                            <span className="workSpan">H</span>
                            <input type="text" className="workInput" value={minutes} onChange={(e) => handleWorkChange(e, "minutes")} />
                            <span className="workSpan">M</span>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <Link to="" onClick={(e) => handleSaveCardWork(e)} className="btn-orange">
                                Save
                            </Link>  
                        </div>
                        {/*
                        <select style={{ padding: "5px", border: "1px solid #efefef", marginRight: "10px" }}
                            value={status}
                            onChange={(e) => handleStatusChange(e)}>
                            <option value={0}>Select New Status</option>
                            {mappedStatuses}
                        </select>
                        */}

                    </div>
                </div>
            </div>
        }
        </>
    );
}

export default ChangeCardWorkContextMenu;