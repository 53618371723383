
function NotFound404() {
    return (
        <>
            <div className="notFound">
                <h1>Whoops!</h1>
                <div>Sorry, we don't have a page for that</div>
            </div>
        </>
    );
}

export default NotFound404;