import ReactModal from 'react-modal';
import React, { useState, useEffect, useRef } from 'react';
//import { USER_REGISTRATION_MODAL_SETTINGS } from '../Helpers/Constants';
import { AiOutlineClose } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { isValidEmail } from '../Helpers/isValidEmail';
//import { addEditUserModeType } from '../Helpers/types';
//import { User } from '../Models/User';
import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { 
    resendActivationLink, activateUser, login, getUser, socialLogin, 
    resetActivationNeeded, sendRecoveryLink, resetPassword
} from '../Store/Auth';
import { acceptProjectInvite, acceptContainerInvite } from "../Store/Project";
import { toast } from 'react-toastify';
import GoogleSSO from '../Components/GoogleSso';
import { decodeToken } from '../Helpers/DecodeToken';

interface Props {
    visible: boolean;
    visibleHandler: (visible: boolean) => void;
    activationCode?: string | null; 
    recoveryCode?: string | null;
    projectId?: number | null;
    containerId?: number | null;
    link?: string | null;
}

function Login(props: Props) {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const toastId = useRef(0);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [recoverFormIsVisible, setRecoverFormIsVisible] = useState(false);
    const [changePasswordFormIsVisible, setChangePasswordFormIsVisible] = useState(false);
    const user = useAppSelector(getUser);


    useEffect(() => {
        if(props.recoveryCode != null) {
            setChangePasswordFormIsVisible(true);
        }
    }, [props.recoveryCode]);
    
    useEffect(() => {
        if (props.activationCode != null) {
            dispatch(activateUser(props.activationCode));
        }
    }, [dispatch, props.activationCode]);

    useEffect(() => {
        if (toastId.current === 1) {
            if (user.accessToken !== "" && user.status === "idle") {
                props.visibleHandler(false);
                if(props.projectId && props.link) {
                    dispatch(acceptProjectInvite({ projectId: props.projectId, link: props.link }));
                }
                if(props.containerId && props.link) {
                    dispatch(acceptContainerInvite({ containerId: props.containerId, link: props.link }));
                }
                navigate('/members');
           }else if (user.status === "failed") {
               toast.error(user.errorMessage);
           }
        } else if (toastId.current === 3 && user.accessToken === "" && user.status === "idle") {
            toast.success("A recovery link has been sent to your email");
        } else if (toastId.current === 4 && user.accessToken === "" && user.status === "idle") {
            toast.success("Your password has been successfully changed. You can now login.");
        }
    }, [user.status]);

    function processGoogleLogin(response: google.accounts.id.CredentialResponse){
        const payload = decodeToken(response.credential as string);
        if (payload) {
            toastId.current = 1;
            dispatch(socialLogin({ 
                email: payload.email, 
                firstName: payload.given_name, 
                lastName: payload.family_name, 
                platform: "google",
                method: "login",
                token: response.credential,
                picture: payload.picture,
            }));
        } else {
            toast.error("Social login failed");
        }
    }

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    }

    const handlePassword2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword2(e.target.value);
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    }

    const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
        
        e.preventDefault();
        let email = e.currentTarget.email.value;
        let password = e.currentTarget.password.value;

        if (!isValidEmail(email)) {
            toast.error("Please enter a valid email address");
        } else if (password === "") {
            toast.error("Please enter a password");
        } else {
            toastId.current = 1;
            dispatch(login({
                email: email,
                password: password
            }));
        }
    }

    const resendActivation = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (email !== "") {
            dispatch(resendActivationLink(email));
            dispatch(resetActivationNeeded(false));
            toast.success("Activation link resent");
        } else {
            toast.error("Please enter your email so we can resend the code");
        }
    }

    const sendRecovery = (e: React.FormEvent<HTMLFormElement>) => {
        
        e.preventDefault();
        let email = e.currentTarget.email.value;

        if (!isValidEmail(email)) {
            toast.error("Please enter a valid email address");
        } else {
            toastId.current = 3;
            dispatch(sendRecoveryLink(email));
        }
    }

    const handlePasswordRecovery = (e: React.FormEvent<HTMLFormElement>) => {
        
        e.preventDefault();
        let password1 = e.currentTarget.password.value;
        let password2 = e.currentTarget.password2.value;
        let recoveryCode = e.currentTarget.recoveryCode.value;

        if (password1 !== password2 || password1 === "" || password2 === "") {
            toast.error("The passwords don't match");
        } else {
            toastId.current = 4;
            dispatch(resetPassword({password: password1, recoveryCode: recoveryCode}));
        }
    }

    const toggleRecoverForm = (show: boolean) => {
        setRecoverFormIsVisible(show);
    }

    const togglePasswordChangeForm = (show: boolean) => {
        setChangePasswordFormIsVisible(show);
    }

    return (
        <>
            <ReactModal className="registerLoginModal" isOpen={props.visible} ariaHideApp={false}>
                <div className="modalHeader">
                    <div className="text-end pe-3">
                        <Link to="/" onClick={(e) => { e.preventDefault(); dispatch(resetActivationNeeded(false)); props.visibleHandler(false) }}>
                            <AiOutlineClose size={20} />
                        </Link>
                    </div>
                </div>

                <div className="row g-0 modalBody">
                    <div className="col-12 ps-3 pe-3">
                        <React.Fragment>
                            <div className="row align-items-center ms-0 me-0">
                                <div className="col-12 text-center mb-3">
                                    <div>
                                        <img className="cat-only-75" src='/images/herdr-logo-cat-only.png' alt="herdr.io" />
                                    </div>
                                    <div style={{fontSize: "16pt"}}>Log in to continue</div>
                                </div>
                            </div>

                            {props.activationCode != null ?                           
                                <div className="success">
                                    Your account has been activated. Log in to get started using herdr
                                </div>
                            :
                                <>
                                    <GoogleSSO buttonText="signin_with" loginHandler={processGoogleLogin} />
                                    <div className="row mb-2 mt-2 text-center ms-0 me-0">
                                        <div className="col-12">
                                            OR
                                        </div>
                                    </div>
                                </>
                            }

                            {user.activationNeeded &&                         
                                <div className="success">
                                    Click <Link to="" onClick={(e) => resendActivation(e)}>here</Link> to 
                                    resend your activation code to your email
                                </div>
                            }

                            <form onSubmit={(e) => handleLogin(e)} className="full-width-button">
                                <div className="pt-2">
                                    <input
                                        id="email"
                                        type="text"
                                        name="email"
                                        value={email ?? ""}
                                        onChange={(e) => handleEmailChange(e)}
                                        placeholder="Email Address" 
                                        className="form-control" />
                                </div>
                                <div className="pt-2">
                                    <input
                                        type="password"
                                        name="password"
                                        value={password ?? ""}
                                        onChange={(e) => handlePasswordChange(e)}
                                        placeholder="Password"
                                        className="form-control" />
                                </div>
                                <div className="pt-2">
                                    <input className="orange-signup-button" type="submit" value="Login" />
                                </div>
                            </form>
                            <div style={{ margin: "10px auto", textAlign: "center" }}>
                                <Link style={{ color: "#555", fontWeight: "normal", fontSize: "11pt", textDecoration: "none" }}
                                    to="" onClick={() => { props.visibleHandler(false); toggleRecoverForm(true)} }>
                                    Can't log in?
                                </Link>
                            </div>
                        </React.Fragment>
                    </div>
                </div>
            </ReactModal>
            <ReactModal className="registerLoginModal" isOpen={recoverFormIsVisible} ariaHideApp={false}>
                <div className="modalHeader">
                    <div className="text-end pe-3">
                        <Link to="/" onClick={(e) => { 
                            e.preventDefault(); 
                            dispatch(resetActivationNeeded(false)); 
                            props.visibleHandler(false); 
                            toggleRecoverForm(false) 
                        }}>
                            <AiOutlineClose size={20} />
                        </Link>
                    </div>
                </div>

                <div className="row g-0 modalBody">
                    <div className="col-12 ps-3 pe-3">
                        <React.Fragment>
                            <div className="row align-items-center ms-0 me-0">
                                <div className="col-12 text-center mb-3">
                                    <div>
                                        <img className="cat-only-75" src='/images/herdr-logo-cat-only.png' alt="herdr.io" />
                                    </div>
                                    <div style={{fontSize: "16pt"}}>Can't log in?</div>
                                    <div style={{fontSize: "12pt", fontWeight: "normal"}}>We'll send a recovery link to</div>
                                </div>
                            </div>

                            <form onSubmit={(e) => sendRecovery(e)} className="full-width-button">
                                <div className="pt-2">
                                    <input
                                        id="email"
                                        type="text"
                                        name="email"
                                        value={email ?? ""}
                                        onChange={(e) => handleEmailChange(e)}
                                        placeholder="Email Address" 
                                        className="form-control" />
                                </div>
                                <div className="pt-2">
                                    <input className="orange-signup-button" type="submit" value="Send Recovery Link" />
                                </div>
                            </form>
                            <div style={{ margin: "10px auto", textAlign: "center" }}>
                                <Link style={{ color: "#555", fontWeight: "normal", fontSize: "11pt", textDecoration: "none" }}
                                    to="" onClick={() =>  { props.visibleHandler(true); toggleRecoverForm(false)} }>
                                    Return to login
                                </Link>
                            </div>
                        </React.Fragment>
                    </div>
                </div>
            </ReactModal>
            <ReactModal className="registerLoginModal" isOpen={changePasswordFormIsVisible} ariaHideApp={false}>
                <div className="modalHeader">
                    <div className="text-end pe-3">
                        <Link to="/" onClick={(e) => {
                           e.preventDefault(); 
                           dispatch(resetActivationNeeded(false)); 
                           props.visibleHandler(false); 
                           togglePasswordChangeForm(false) 
                        }}>
                            <AiOutlineClose size={20} />
                        </Link>
                    </div>
                </div>

                <div className="row g-0 modalBody">
                    <div className="col-12 ps-3 pe-3">
                        <React.Fragment>
                            <div className="row align-items-center ms-0 me-0">
                                <div className="col-12 text-center mb-3">
                                    <div>
                                        <img className="cat-only-75" src='/images/herdr-logo-cat-only.png' alt="herdr.io" />
                                    </div>
                                    <div style={{fontSize: "16pt"}}>Reset Your Password</div>
                                </div>
                            </div>

                            <form onSubmit={(e) => handlePasswordRecovery(e)} className="full-width-button">
                                <div className="pt-2">
                                    <input
                                        type="password"
                                        name="password"
                                        value={password ?? ""}
                                        onChange={(e) => handlePasswordChange(e)}
                                        placeholder="New Password"
                                        className="form-control" />
                                </div>
                                <div className="pt-2">
                                    <input
                                        type="password"
                                        name="password2"
                                        value={password2 ?? ""}
                                        onChange={(e) => handlePassword2Change(e)}
                                        placeholder="Repeat New Password"
                                        className="form-control" />
                                </div>
                                <div className="pt-2">
                                    <input type='hidden' name='recoveryCode' value={props.recoveryCode as string} />
                                    <input className="orange-signup-button" type="submit" value="Change Password" />
                                </div>
                            </form>
                        </React.Fragment>
                    </div>
                </div>
            </ReactModal>
        </>
    );
}

export default Login;