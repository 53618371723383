import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { ContextMenu } from '../Models/ContextMenu';
import { SettingsContextParam } from'../Models/Requests/SettingsContextParam';
import { AiOutlineClose } from "react-icons/ai";
import { logout } from '../Store/Auth';
import { getSettings, saveAvatar, removeAvatar } from "../Store/Settings";
import { getProject } from "../Store/Project";
import { FaUserCircle } from "react-icons/fa";
import { IconContext } from "react-icons";
import Resizer from "react-image-file-resizer";
import Avatar from "../Components/Avatar";
import { relative } from 'path';

interface Props {
    params: SettingsContextParam | undefined;    
}

function SettingsContext(props: Props) {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const settingsState = useAppSelector(getSettings);
    const projectState = useAppSelector(getProject);
    const avatarUpload = useRef(null);
    const [settingsContext, setSettingsContext] = useState<ContextMenu>();
  
    useEffect(() => {
        if (props.params) {
            let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
            if ((settingsContext && settingsContext.id !== props.params.id) || !settingsContext) {

                let menuWidth = 300;
                // let menuWidth = window.outerWidth * .6;
                let menuHeight = window.outerHeight * .80;
                let menuXStart = 20;
                //let menuXStart = window.outerWidth * .2;
                let menuYStart = 100;

                if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }

                contextMenu.id = props.params.id;
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                contextMenu.width = menuWidth;
                contextMenu.height = menuHeight;
            }
            setSettingsContext(contextMenu);
        }
    }, [props.params]);

    const closeContextMenu = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        closeMenu();
    }

    const closeMenu = () => {
        let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        setSettingsContext(contextMenu);
        //setEditingTitle(false);
        //setEditingDescription(false);
        //setLabelContextMenuParams(undefined);
        //setMoveCardContextMenuParams(undefined);
    }

    const logUserOut = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        dispatch(logout());
        navigate('/');
    }

    const popChangeAvatar = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if(avatarUpload.current !== null) {
            //alert("You clicked to change your avatar");
            (avatarUpload.current as HTMLInputElement).click();
        }
        //alert("Hey, you changed your avatar!");
    }

    const captureAvatarUpload = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
            let file = e.currentTarget.files[0];
            console.log(e.currentTarget.files[0]);
            try {
                Resizer.imageFileResizer(
                 file,
                  300,
                  300,
                  "JPEG",
                  100,
                  0,
                  (uri) => {
                    dispatch(saveAvatar(uri as string));
                    //console.log(uri);
                    //this.setState({ newImage: uri });
                  },
                  "base64",
                  200,
                  200
                );
              } catch (err) {
                //console.log(err);
              }
        }
    }

    const handleRemoveAvatar = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(removeAvatar());
    }

    const sContextMenu: React.CSSProperties = {
        position: "relative",
        top: (settingsContext) ? settingsContext.y : 0,
        right: (settingsContext) ? settingsContext.x : 0,
        //left: (settingsContext) ? settingsContext.x : 0,
        width: (settingsContext) ? settingsContext.width : 0,
        //height: (settingsContext) ? settingsContext.height : 0,
    }

    let currentMember = projectState.projectMembers.find(m => m.me);

    return (
        <>
        {settingsContext && settingsContext.id > 0 &&
            <div onClick={e => closeMenu()} className="windowContainer">
                <div className="cardContextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
                    <div className="divClose">
                        <Link to="" onClick={(e) => closeContextMenu(e)}>
                            <AiOutlineClose color="#555" size="13" />
                        </Link>
                    </div>
                    <div>
                        <div style={{ width: "150px", margin: "20px auto" }}>
                            <input type='file' ref={avatarUpload} style={{ display: "none" }} 
                                onClick={(e) => { e.stopPropagation();  }} 
                                onChangeCapture={(e) => captureAvatarUpload(e)}
                            />
                            <div className="divAvatar">
                                <Link to="" onClick={(e) => popChangeAvatar(e)}>
                                   <Avatar size="large" projectMember={currentMember} />
                                </Link>
                                <div className="divAvatarHover">
                                    Change your avatar
                                </div>
                            </div>

                            {settingsState.avatar !== "" && settingsState.avatar !== undefined &&
                                <div style={{ textAlign: "center" }}>
                                    <Link to="" onClick={(e) => handleRemoveAvatar(e)}
                                        style={{ textDecoration: "underline", fontSize: "9pt" }}>
                                        Remove
                                    </Link>
                                </div>
                            }

                            {/*
                            <div style={{ textAlign: "center" }}>
                                <Link className="settingsLink" to="" onClick={(e) => popChangeAvatar(e)} style={{ textDecoration: "underline", paddingRight: "10px" }}>
                                    edit
                                </Link>
                            </div>*/}
                            <hr style={{ border: "1px solid gray" }} />
                            <div>
                                <Link className="settingsLink" to="" onClick={(e) => logUserOut(e)}>
                                    Sign out
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    );
}

export default SettingsContext;