import React, { useState, useEffect } from 'react';
import { useAppSelector } from '../../Core/hooks';
import { Link } from 'react-router-dom';
import { getUser } from '../../Store/Auth';

interface Props {
    text: string,
    className: string;
    visibility: boolean;
}

function Button(props: Props) {

    const style = {
        display: (props.visibility) ? "" : "none",
    };

    return (
        <input type="submit" className={props.className} style={style} value={props.text} />
    );
}

export default Button;