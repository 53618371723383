import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Core/store';
//import { Credentials } from '../Models/Requests/Credentials';
import { axiosPublic, axiosPrivate } from '../Core/axios';
import { Project } from '../Models/Project';
import { Container } from '../Models/Container';
import { List } from '../Models/List';
import { Card } from '../Models/Card';
import { Label } from '../Models/Label';
import { MComment } from '../Models/MComment';
import { LabelToCard } from '../Models/LabelToCard';
import { InviteToProject } from '../Models/InviteToProject';
import { InviteToContainer } from '../Models/InviteToContainer';
import { ProjectMember } from '../Models/Requests/ProjectMember';
//import { ContainerMember } from '../Models/Requests/ContainerMember';
import { projectStatus } from '../Helpers/types';
//import { decodeToken } from '../Helpers/DecodeToken';
import { SaveProjectParams } from '../Models/Requests/SaveProjectParams';
import { SaveSharingParams } from '../Models/Requests/SaveSharingParams';
import { NewListParams } from '../Models/Requests/NewListParams';
import { NewCardParams } from '../Models/Requests/NewCardParams';
import { SaveListParams } from '../Models/Requests/SaveListParams';
import { ShareViaEmailParam } from '../Models/Requests/ShareViaEmailParam';
import { ShareContainerViaEmailParam } from '../Models/Requests/ShareContainerViaEmailParam';
import { RemoveProjectMemberParams } from '../Models/Requests/RemoveProjectMemberParam';
import { ChangeProjectMemberParams } from '../Models/Requests/ChangeProjectMemberParam';
import { GetProjectMemberPublicParam } from '../Models/Requests/GetProjectMemberPublicParam';
import { GetProjectPublicParam } from '../Models/Requests/GetProjectPublicParam';
import { GetContainerMemberPublicParam } from '../Models/Requests/GetContainerMemberPublicParam';
import { GetContainerPublicParam } from '../Models/Requests/GetContainerPublicParam';
import { ProjectInviteParams } from '../Models/Requests/ProjectInviteParams';
import { ProjectInvitePublicParams } from '../Models/Requests/ProjectInvitePublicParams';
import { ContainerInviteParams } from '../Models/Requests/ContainerInviteParams';
import { ContainerInvitePublicParams } from '../Models/Requests/ContainerInvitePublicParams';
import { AddContainerParams } from '../Models/Requests/AddContainerParams';
import { SaveContainerParams } from '../Models/Requests/SaveContainerParams';
import { MoveProjectParams } from '../Models/Requests/MoveProjectParams';
import { AddProjectParams } from '../Models/Requests/AddProjectParams';
import { ChangeCardOwnerParams } from '../Models/Requests/ChangeCardOwnerParams';
import { ChangeCardStatusParams } from '../Models/Requests/ChangeCardStatusParams';
import { ChangeCardWorkParams } from '../Models/Requests/ChangeCardWorkParams';

export interface ProjectState {
  containers: Array<Container>;
  projects: Array<Project>;
  lists: Array<List>;
  cards: Array<Card>;
  comments: Array<MComment>;
  labels: Array<Label>;
  projectMembers: Array<ProjectMember>;
  containerMembers: Array<ProjectMember>;
  labelsToCards: Array<LabelToCard>;
  invitesToProjects: Array<InviteToProject>;
  invitesToContainers: Array<InviteToContainer>;
  status: projectStatus;
  projectSwitchingStatus: boolean;
  errorMessage: string;
  searchText: string;
  //savedComment: SavedComment;

  //showProjects: boolean;
}

const initialState: ProjectState = {
  containers: [],
  projects: [],
  lists: [],
  cards: [],
  comments: [],
  labels: [],
  projectMembers: [],
  containerMembers: [],
  labelsToCards: [],
  invitesToProjects: [],
  invitesToContainers: [],
  status: "unset",
  projectSwitchingStatus: false,
  errorMessage: "",
  searchText: "",
  //savedComment: EmptySavedComment,
};

export const addProject = createAsyncThunk(
  'api/project/add',
  async (params: AddProjectParams) => {    
    return await axiosPrivate.post('api/project',
      JSON.stringify({
        name: params.name,
        containerId: params.containerId
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const saveProject = createAsyncThunk(
  'api/project/save',
  async (param: SaveProjectParams) => {    
    return await axiosPrivate.put('api/project',
      JSON.stringify({
        id: param.id,
        name: param.name,
        loaded: param.loaded,
        mode: param.mode
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const moveProject = createAsyncThunk(
  'api/project/move',
  async (param: MoveProjectParams) => {    
    return await axiosPrivate.put('api/project/move',
      JSON.stringify({
        projectId: param.projectId,
        containerId: param.containerId
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const addContainer = createAsyncThunk(
  'api/project/container',
  async (param: AddContainerParams) => {    
    return await axiosPrivate.post('api/project/container',
      JSON.stringify(param),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const saveContainer = createAsyncThunk(
  'api/project/container/save',
  async (param: SaveContainerParams) => {    
    return await axiosPrivate.post('api/project/container/save',
      JSON.stringify(param),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const saveSharing = createAsyncThunk(
  'api/project/sharing',
  async (param: SaveSharingParams) => {    
    return await axiosPrivate.put('api/project/sharing',
      JSON.stringify({
        id: param.id,
        //shareLink: param.shareLink,
        shareLinkPermission: param.shareLinkPermission,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const saveContainerSharing = createAsyncThunk(
  'api/project/container/sharing',
  async (param: SaveSharingParams) => {    
    return await axiosPrivate.put('api/project/container/sharing',
      JSON.stringify({
        id: param.id,
        //shareLink: param.shareLink,
        shareLinkPermission: param.shareLinkPermission,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const deleteSharing = createAsyncThunk(
  'api/project/sharing/delete',
  async (id: number) => {    
    return await axiosPrivate.delete('api/project/sharing/' + id,
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const deleteContainerSharing = createAsyncThunk(
  'api/project/container/sharing/delete',
  async (id: number) => {    
    return await axiosPrivate.delete('api/project/container/sharing/' + id,
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const removeMemberFromProject = createAsyncThunk(
  'api/project/member',
  async (params: RemoveProjectMemberParams) => {    
    return await axiosPrivate.put('api/project/member',
      JSON.stringify({
        projectId: params.id,
        email: params.email,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const removeMemberFromContainer = createAsyncThunk(
  'api/project/container/member',
  async (params: RemoveProjectMemberParams) => {    
    return await axiosPrivate.put('api/project/container/member',
      JSON.stringify({
        containerId: params.id,
        email: params.email,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const changeProjectMemberPermission = createAsyncThunk(
  'api/project/member/update',
  async (params: ChangeProjectMemberParams) => {    
    return await axiosPrivate.put('api/project/member/update',
      JSON.stringify({
        projectId: params.id,
        email: params.email,
        permission: params.permission,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const changeContainerMemberPermission = createAsyncThunk(
  'api/project/container/member/update',
  async (params: ChangeProjectMemberParams) => {    
    return await axiosPrivate.put('api/project/container/member/update',
      JSON.stringify({
        containerId: params.id,
        email: params.email,
        permission: params.permission,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const shareViaEmail = createAsyncThunk(
  'api/project/share/email',
  async (param: ShareViaEmailParam) => {    
    return await axiosPrivate.post('api/project/share/email',
      JSON.stringify({
        type: param.type,
        emailBody: param.emailBody,
        emails: param.emails,
        projectId: param.projectId,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const shareContainerViaEmail = createAsyncThunk(
  'api/project/container/share/email',
  async (param: ShareContainerViaEmailParam) => {    
    return await axiosPrivate.post('api/project/container/share/email',
      JSON.stringify({
        type: param.type,
        emailBody: param.emailBody,
        emails: param.emails,
        containerId: param.containerId,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const saveList = createAsyncThunk(
  'api/project/list/save',
  async (param: SaveListParams) => {    
    return await axiosPrivate.put('api/project/list',
      JSON.stringify({
        id: param.id,
        name: param.name
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

/* Using any in the update routes so I can have the flexibility of 
   passing some or all fields of the object for updating depending
   on what which fields we're updating.
*/
/*
export const saveCard = createAsyncThunk(
  'api/project/card',
  async (param: any) => {    
    return await axiosPrivate.put('api/project/card',
      JSON.stringify({
        id: param.id,
        listId: param.listId,
        index: param.index
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});
*/

export const saveListObject = createAsyncThunk(
  'api/project/list/save/object',
  async (list: List ) => {    
    return await axiosPrivate.put('api/project/list/object',
      JSON.stringify(list),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const saveCard = createAsyncThunk(
  'api/project/card/save',
  async (card: Card ) => {    
    return await axiosPrivate.put('api/project/card',
      JSON.stringify(card),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const changeCardWork = createAsyncThunk(
  'api/project/card/work',
  async (param: ChangeCardWorkParams) => {    
    return await axiosPrivate.put('api/project/card/work',
      JSON.stringify({
        workType: param.workType, 
        work: param.work,
        cardId: param.cardId
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const changeCardOwner = createAsyncThunk(
  'api/project/card/owner',
  async (param: ChangeCardOwnerParams) => {    
    return await axiosPrivate.put('api/project/card/owner',
      JSON.stringify({
        ownerId: param.ownerId,
        cardId: param.cardId
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const changeCardStatus = createAsyncThunk(
  'api/project/card/status',
  async (param: ChangeCardStatusParams) => {    
    return await axiosPrivate.put('api/project/card/status',
      JSON.stringify({
        status: param.status,
        cardId: param.cardId
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const saveComment = createAsyncThunk(
  'api/project/comment',
  async (comment: MComment ) => {    
    return await axiosPrivate.put('api/project/comment',
      JSON.stringify(comment),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const getComments = createAsyncThunk(
  'api/get/comments',
  async (projectId: number) => {    
    return await axiosPrivate.get('api/project/' + projectId +'/comment',
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const saveLabel = createAsyncThunk(
  'api/project/label/save',
  async (label: Label ) => {    
    return await axiosPrivate.post('api/project/label',
      JSON.stringify(label),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const attachLabelToCard = createAsyncThunk(
  'api/project/label/attach',
  async (labelToCard: LabelToCard ) => {    
    return await axiosPrivate.post('api/project/label/attach',
      JSON.stringify(labelToCard),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const detachLabelFromCard = createAsyncThunk(
  'api/project/label/detach',
  async (labelToCard: LabelToCard ) => {    
    return await axiosPrivate.post('api/project/label/detach',
      JSON.stringify(labelToCard),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const updateLabel = createAsyncThunk(
  'api/label/update',
  async (label: Label) => {    
    return await axiosPrivate.put('api/project/label',
      JSON.stringify(label),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const acceptProjectInvite = createAsyncThunk(
  'api/project/invite',
  async (params: ProjectInviteParams) => {    
    return await axiosPrivate.put('api/project/invite',
      JSON.stringify(params),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const acceptProjectInvitePublic = createAsyncThunk(
  'api/invite/accept',
  async (params: ProjectInvitePublicParams) => {    
    return await axiosPublic.post('api/invite',
      JSON.stringify(params),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});


export const acceptContainerInvite = createAsyncThunk(
  'api/project/container/invite',
  async (params: ContainerInviteParams) => {    
    return await axiosPrivate.put('api/project/container/invite',
      JSON.stringify(params),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const acceptContainerInvitePublic = createAsyncThunk(
  'api/invite/container/accept',
  async (params: ContainerInvitePublicParams) => {    
    return await axiosPublic.post('api/invite/container',
      JSON.stringify(params),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const addList = createAsyncThunk(
  'api/project/list/add',
  async (params: NewListParams ) => {    
    return await axiosPrivate.post('api/project/list',
      JSON.stringify({
        projectId: params.projectId,
        name: params.name,
        index: params.index,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const unloadProject = createAsyncThunk(
  'api/project/unload',
  async () => {    
    return await axiosPrivate.post('api/project/unload',
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const loadContainer = createAsyncThunk(
  'api/project/container/load',
  async (id: number ) => {    
    return await axiosPrivate.post('api/project/container/load',
      JSON.stringify({
        id: id
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const addCard = createAsyncThunk(
  'api/project/card/add',
  async (params: NewCardParams ) => {    
    return await axiosPrivate.post('api/project/card',
      JSON.stringify({
        listId: params.listId,
        title: params.title,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const copyCard = createAsyncThunk(
  'api/project/card/copy',
  async (card: Card ) => {
    return await axiosPrivate.post('api/project/card/copy',
      JSON.stringify(card),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const getProjects = createAsyncThunk(
  'api/get/projects',
  async () => {    
    return await axiosPrivate.get('api/project',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const getContainers = createAsyncThunk(
  'api/get/containers',
  async () => {    
    return await axiosPrivate.get('api/project/container',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const getLists = createAsyncThunk(
  'api/get/lists',
  async (projectId: number) => {    
    return await axiosPrivate.get('api/project/' + projectId +'/list',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const getCards = createAsyncThunk(
  'api/get/cards',
  async (projectId: number) => {    
    return await axiosPrivate.get('api/project/' + projectId + '/card',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const getLabels = createAsyncThunk(
  'api/get/labels',
  async (projectId: number) => {    
    return await axiosPrivate.get('api/project/' + projectId + '/label',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});
/* public route */
export const getProjectMembersPublic = createAsyncThunk(
  'api/get/invite/members/public',
  async ( params: GetProjectMemberPublicParam) => {    
    return await axiosPublic.get('api/invite/' + params.projectId +'/' + params.link,
        {
          headers: { 'Content-Type': 'application/json' },
          //withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});
/* public route */
export const getProjectPublic = createAsyncThunk(
  'api/get/project/public',
  async (params: GetProjectPublicParam) => {    
    return await axiosPublic.get('api/invite/project/' + params.projectId +'/' + params.link,
        {
          headers: { 'Content-Type': 'application/json' },
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});
/* public route */
export const getContainerMembersPublic = createAsyncThunk(
  'api/get/invite/container/members/public',
  async ( params: GetContainerMemberPublicParam) => {    
    return await axiosPublic.get('api/invite/container/members/' + params.containerId +'/' + params.link,
        {
          headers: { 'Content-Type': 'application/json' },
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});
/* public route */
export const getContainerPublic = createAsyncThunk(
  'api/get/invite/container/public',
  async (params: GetContainerPublicParam) => {    
    return await axiosPublic.get('api/invite/container/' + params.containerId +'/' + params.link,
        {
          headers: { 'Content-Type': 'application/json' },
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});
/* private route, has email, etc */
export const getProjectMembers = createAsyncThunk(
  'api/get/project/members',
  async (projectId: number) => {    
    return await axiosPrivate.get('api/project/' + projectId +'/member',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const getContainerMembers = createAsyncThunk(
  'api/get/container/members',
  async (containerId: number) => {    
    return await axiosPrivate.get('api/project/container/' + containerId +'/member',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const getLabelsToCards = createAsyncThunk(
  'api/get/labeltocard',
  async (projectId: number) => {    
    return await axiosPrivate.get('api/project/' + projectId + '/labeltocard',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const getInvitesToProjects = createAsyncThunk(
  'api/get/invitetoproject',
  async (projectId: number) => {    
    return await axiosPrivate.get('api/project/' + projectId + '/invite',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const deleteProject = createAsyncThunk(
  'api/delete/project',
  async (id: number) => {    
    return await axiosPrivate.delete('api/project/' + id,
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const deleteContainer = createAsyncThunk(
  'api/delete/project/container',
  async (id: number) => {    
    return await axiosPrivate.delete('api/project/container/' + id,
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const deleteList = createAsyncThunk(
  'api/delete/list',
  async (id: number) => {    
    return await axiosPrivate.delete('api/project/list/' + id,
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const deleteCard = createAsyncThunk(
  'api/delete/card',
  async (id: number) => {    
    return await axiosPrivate.delete('api/project/card/' + id,
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const deleteComment = createAsyncThunk(
  'api/delete/comment',
  async (id: number) => {    
    return await axiosPrivate.delete('api/project/comment/' + id,
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const deleteLabel = createAsyncThunk(
  'api/delete/label',
  async (id: number) => {    
    return await axiosPrivate.delete('api/project/label/' + id,
      {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
      }
    ).then(
      (res) => {
          return res.data;
      }
    );
});

export const setSearchText = createAsyncThunk(
  'set/search/text',
  async (searchText: string) => {    
      return searchText;
});

export const setProjects = createAsyncThunk(
  'set/projects',
  async (projects: Array<Project>) => {    
      return projects;
});

export const setContainers = createAsyncThunk(
  'set/containers',
  async (containers: Array<Container>) => {    
      return containers;
});

export const setLists = createAsyncThunk(
  'set/lists',
  async (lists: Array<List>) => {    
      return lists;
});

export const setCards = createAsyncThunk(
  'set/cards',
  async (cards: Array<Card>) => {    
      return cards;
});
export const setLabels = createAsyncThunk(
  'set/labels',
  async (labels: Array<Label>) => {    
      return labels;
});
export const setComments = createAsyncThunk(
  'set/comments',
  async (comments: Array<MComment>) => {    
      return comments;
});
export const setLabelsToCards = createAsyncThunk(
  'set/labelstocards',
  async (labelsToCards: Array<LabelToCard>) => {    
      return labelsToCards;
});
/*
export const setActiveProject = createAsyncThunk(
  'set/project',
  async (project: Project) => {    
      return project;
});
*/
export const setStatus = createAsyncThunk(
  'set/project/status',
  async (status: projectStatus) => {    
      return status;
});

export const ProjectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveSharing.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveSharing.fulfilled, (state, action) => {
        state.status = 'shareLinkSaved';
      })
      .addCase(saveSharing.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(saveContainerSharing.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveContainerSharing.fulfilled, (state, action) => {
        state.status = 'shareLinkSaved';
      })
      .addCase(saveContainerSharing.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(deleteSharing.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteSharing.fulfilled, (state, action) => {
        state.status = 'shareLinkDeleted';
      })
      .addCase(deleteSharing.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(deleteContainerSharing.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteContainerSharing.fulfilled, (state, action) => {
        state.status = 'shareLinkDeleted';
      })
      .addCase(deleteContainerSharing.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(addProject.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addProject.fulfilled, (state, action) => {
        state.status = 'projectAdded';
        //state.projects.push(action.payload);
      })
      .addCase(addProject.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(addContainer.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addContainer.fulfilled, (state, action) => {
        state.status = 'containerAdded';
      })
      .addCase(addContainer.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(saveContainer.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveContainer.fulfilled, (state, action) => {
        state.status = 'containerSaved';
      })
      .addCase(saveContainer.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(deleteList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteList.fulfilled, (state, action) => {
        state.status = 'idle';
        //state.lists.push(action.payload);
        state.lists = [...action.payload];
      })
      .addCase(deleteList.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(addList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addList.fulfilled, (state, action) => {
        state.status = 'listAdded';
        //state.lists.push(action.payload);
        state.lists = [...action.payload];
      })
      .addCase(addList.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(addCard.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addCard.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cards.push(action.payload);
      })
      .addCase(addCard.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(saveLabel.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveLabel.fulfilled, (state, action) => {
        state.status = 'labelAdded';
        state.labels.push(action.payload);
      })
      .addCase(saveList.fulfilled, (state, action) => {
        state.status = 'idle';
        state.lists = action.payload;
      })
      .addCase(saveLabel.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(saveProject.pending, (state, action) => {
        //console.log(action.meta.arg);
        state.status = 'loading';
        if (action.meta.arg.event === "switch") {
          state.projectSwitchingStatus = true;
        }
      })
      .addCase(saveProject.fulfilled, (state, action) => {
        state.status = 'idle';
        //state.projectSwitchingStatus = false;
        //state.projects.push(action.payload);
      })
      .addCase(saveProject.rejected, (state, action) => {
        state.status = 'failed';
        state.projectSwitchingStatus = false;
        state.errorMessage = action.error.message as string;
      })
      .addCase(moveProject.fulfilled, (state, action) => {
        state.status = 'projectMoved';
        //state.projectSwitchingStatus = false;
        //state.projects.push(action.payload);
      })
      //.addCase(addContainer.fulfilled, (state, action) => {
        //state.status = 'idle';
        //state.containers.push(action.payload);
        //state.projectSwitchingStatus = false;
        //state.projects.push(action.payload);
      //})
      .addCase(attachLabelToCard.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(attachLabelToCard.fulfilled, (state, action) => {
        state.status = 'labelAttachedToCard';
        state.labelsToCards.push(action.payload);
      })
      .addCase(attachLabelToCard.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(loadContainer.fulfilled, (state, action) => {
        state.status = 'containerLoaded';
        state.containers = action.payload;
      })
      .addCase(unloadProject.fulfilled, (state, action) => {
        state.status = 'projectUnloaded';
        state.projects = action.payload;
      })
      .addCase(shareViaEmail.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(shareViaEmail.fulfilled, (state, action) => {
        state.status = 'sharedViaEmail';
        state.invitesToProjects.push(action.payload);
      })
      .addCase(shareViaEmail.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(shareContainerViaEmail.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(shareContainerViaEmail.fulfilled, (state, action) => {
        state.status = 'sharedViaEmail';
        state.invitesToContainers.push(action.payload);
      })
      .addCase(shareContainerViaEmail.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(removeMemberFromProject.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeMemberFromProject.fulfilled, (state, action) => {
        state.status = 'memberRemoved';
      })
      .addCase(removeMemberFromProject.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(removeMemberFromContainer.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeMemberFromContainer.fulfilled, (state, action) => {
        state.status = 'memberRemoved';
      })
      .addCase(removeMemberFromContainer.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(changeProjectMemberPermission.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(changeProjectMemberPermission.fulfilled, (state, action) => {
        state.status = 'memberChanged';
      })
      .addCase(changeProjectMemberPermission.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(changeContainerMemberPermission.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(changeContainerMemberPermission.fulfilled, (state, action) => {
        state.status = 'memberChanged';
      })
      .addCase(changeContainerMemberPermission.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(detachLabelFromCard.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(detachLabelFromCard.fulfilled, (state, action) => {
        state.status = 'idle';
        //state.labelsToCards.push(action.payload);
      })
      .addCase(detachLabelFromCard.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(saveComment.fulfilled, (state, action) => {
        state.status = 'idle';
        //state.comments = action.payload;
        let comment = action.payload;
        let commentExists = state.comments.findIndex(c => c.id === comment.id);
        if (commentExists !== -1) {
          // existing comment, update in place
          let comments = [...state.comments];
          comments[commentExists] = comment;
          state.comments = comments;
        } else {
          // newly added comment, add to beginning of array
          state.comments.unshift(action.payload);
        }

        
        //state.savedComment = action.payload;
        //state.savedComment.id = action.payload.id;
      })
      .addCase(changeCardOwner.fulfilled, (state, action) => {
        state.status = 'cardOwnerChanged';
        let card = action.payload;
        let cardExists = state.cards.findIndex(c => c.id === card.id);
        if (cardExists !== -1) {
          let cards = [...state.cards];
          cards[cardExists] = card;
          state.cards = cards;
        }
      })
      .addCase(changeCardWork.fulfilled, (state, action) => {
        //state.status = 'cardOwnerChanged';
        let card = action.payload;
        let cardExists = state.cards.findIndex(c => c.id === card.id);
        if (cardExists !== -1) {
          let cards = [...state.cards];
          cards[cardExists] = card;
          state.cards = cards;
        }
      })
      .addCase(changeCardStatus.fulfilled, (state, action) => {
        state.status = 'cardStatusChanged';
        let card = action.payload;
        let cardExists = state.cards.findIndex(c => c.id === card.id);
        if (cardExists !== -1) {
          let cards = [...state.cards];
          cards[cardExists] = card;
          state.cards = cards;
        }
      })
      .addCase(getProjects.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.status = 'idle';
        state.projects = [...action.payload];
      })
      .addCase(getProjects.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getContainers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getContainers.fulfilled, (state, action) => {
        state.status = 'containersFetched';
        state.containers = [...action.payload];
      })
      .addCase(getContainers.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(acceptProjectInvite.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(acceptProjectInvite.fulfilled, (state, action) => {
        state.status = 'inviteAccepted';
      })
      .addCase(acceptProjectInvite.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(acceptProjectInvitePublic.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(acceptProjectInvitePublic.fulfilled, (state, action) => {
        state.status = 'inviteAccepted';
      })
      .addCase(acceptProjectInvitePublic.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(acceptContainerInvite.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(acceptContainerInvite.fulfilled, (state, action) => {
        state.status = 'inviteAccepted';
      })
      .addCase(acceptContainerInvite.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(acceptContainerInvitePublic.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(acceptContainerInvitePublic.fulfilled, (state, action) => {
        state.status = 'inviteAccepted';
      })
      .addCase(acceptContainerInvitePublic.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getLists.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getLists.fulfilled, (state, action) => {
        state.status = 'idle';
        state.lists = [...action.payload];
      })
      .addCase(getLists.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getComments.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getComments.fulfilled, (state, action) => {
        state.status = 'idle';
        state.comments = [...action.payload];
      })
      .addCase(getComments.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getProjectMembers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProjectMembers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.projectMembers = [...action.payload];
      })
      .addCase(getProjectMembers.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getContainerMembers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getContainerMembers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.containerMembers = [...action.payload];
      })
      .addCase(getContainerMembers.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getProjectMembersPublic.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProjectMembersPublic.fulfilled, (state, action) => {
        state.status = 'idle';
        state.projectMembers = [...action.payload];
      })
      .addCase(getProjectMembersPublic.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getProjectPublic.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getProjectPublic.fulfilled, (state, action) => {
        state.status = 'idle';
        state.projects = [...action.payload];
      })
      .addCase(getProjectPublic.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getContainerMembersPublic.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getContainerMembersPublic.fulfilled, (state, action) => {
        state.status = 'idle';
        state.containerMembers = [...action.payload];
      })
      .addCase(getContainerMembersPublic.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getContainerPublic.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getContainerPublic.fulfilled, (state, action) => {
        state.status = 'idle';
        state.containers = [...action.payload];
      })
      .addCase(getContainerPublic.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getCards.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCards.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cards = [...action.payload];
        state.projectSwitchingStatus = false;
      })
      .addCase(getCards.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getLabels.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getLabels.fulfilled, (state, action) => {
        state.status = 'idle';
        state.labels = [...action.payload];
      })
      .addCase(getLabels.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getLabelsToCards.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getLabelsToCards.fulfilled, (state, action) => {
        state.status = 'idle';
        state.labelsToCards = [...action.payload];
      })
      .addCase(getLabelsToCards.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getInvitesToProjects.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getInvitesToProjects.fulfilled, (state, action) => {
        state.status = 'idle';
        state.invitesToProjects = [...action.payload];
      })
      .addCase(getInvitesToProjects.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(copyCard.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(copyCard.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cards = [...action.payload];
      })
      .addCase(copyCard.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(setSearchText.fulfilled, (state, action) => {
        state.status = 'idle';
        state.searchText =  action.payload;
      })
      .addCase(setProjects.fulfilled, (state, action) => {
        state.status = 'idle';
        state.projects = [...action.payload];
      })
      .addCase(setContainers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.containers = [...action.payload];
      })
      .addCase(setLists.fulfilled, (state, action) => {
        state.status = 'idle';
        state.lists = [...action.payload];
      })
      .addCase(setCards.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cards = [...action.payload];
      })
      .addCase(setLabels.fulfilled, (state, action) => {
        state.status = 'idle';
        state.labels = [...action.payload];
      })
      .addCase(setComments.fulfilled, (state, action) => {
        state.status = 'idle';
        state.comments = [...action.payload];
      })
      .addCase(setLabelsToCards.fulfilled, (state, action) => {
        state.status = 'idle';
        state.labelsToCards = [...action.payload];
      })
  },
});

export const getProject = (state: RootState) => state.project;
export default ProjectSlice.reducer;
