import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../Core/hooks';
import { setCurrentPage } from '../../Store/Auth';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';

function Products() {

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setCurrentPage("products"));
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navigation />
            <div className="pt-4" style={{ width: "80%", margin: "0px auto" }}>
                <h1 className="text-center">Products</h1>
                <div style={{ marginTop: "20px", marginBottom: "50px" }}>
                    We're busy with day jobs, but we'll get around to adding something at some point :P
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Products;