import React from 'react';
import { useEffect, useState, createElement } from 'react';
import { AddContainerContext } from '../Models/AddContainerContext';
import { AddContainerContextParam } from '../Models/Requests/AddContainerContextParam';
import { IconContext, IconType, IconBaseProps, IconBase } from "react-icons";
import { addContainer, saveContainer, getProject, getContainers } from "../Store/Project";
import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { AiOutlineClose } from "react-icons/ai";
import { FaRegSmile, FaList, FaAddressBook, FaAdversal, FaAngellist, FaArchive,
    FaAudible, FaBabyCarriage, FaBaseballBall, FaBasketballBall, FaBeer, FaBed,
    FaBinoculars, FaBiking, FaBlackTie, FaBuffer, FaBurn, FaCalendar, FaCamera,
    FaCar, FaCarrot, FaCat, FaCertificate, FaChartBar, FaChartArea, FaChartLine,
    FaChartPie, FaChess, FaCheese, FaCheck, FaChild, FaChurch, FaCity, FaClock,
    FaClipboard, FaCloud, FaCloudRain, FaCloudSun, FaCocktail, FaCode, FaCodeBranch,
    FaCoffee, FaCog, FaCoins, FaComment,FaCompactDisc, FaCompass, FaConciergeBell,
    FaCookie, FaCookieBite, FaCreditCard, FaCrosshairs, FaCrow, FaCrown, FaCube,
    FaCut, FaDatabase, FaDeezer, FaDesktop, FaDiceFive, FaDog, FaDollarSign, FaDoorClosed,
    FaDrum, FaDrumstickBite, FaEgg, FaEnvelope, FaEye, FaFaucet, FaFeather, FaFighterJet,
    FaFish, FaFlask, FaFolder, FaFootballBall, FaFutbol, FaGamepad, FaHammer, FaGuitar,
    FaHamburger, FaHeadphones, FaHiking, FaHospitalAlt, FaLaptop, FaMap, FaMapMarker,
    FaMarker, FaMicrophone, FaMobile, FaMugHot, FaPallet,  FaPaw, FaPills, FaPiggyBank,
    FaPizzaSlice, FaSocks, FaStamp, FaShoppingCart, FaStore, FaTruck, FaWrench,
    FaSchool

 } from "react-icons/fa";

interface Props {
    params: AddContainerContextParam | undefined;    
}

interface IIcon {
    type: IconType;
    size: string;
    color: string;
    name: string;
}

function AddContainerContextMenu(props: Props) {

    const dispatch = useAppDispatch();
    const project = useAppSelector(getProject);
    const [addContainerContext, setAddContainerContext] = useState<AddContainerContext>();
    const [icons, setIcons] = useState<Array<IIcon>>();
    const [containerName, setContainerName] = useState<string>("");
    const [iconStyle, setIconStyle] = useState<Array<React.CSSProperties | undefined>>([]);
    const [selectedIcon, setSelectedIcon] = useState<string>("");
    //const [icons, setIcons] = useState<Array<IconType>>();

    useEffect(() => {
        if (project.status === "containerAdded" || project.status === "containerSaved") {
            dispatch(getContainers());
            /* close window */
            let context = { id: 0, x: 0, y: 0, width: 0, height: 0 };
            setAddContainerContext(context);
            /* reset form state */
            setSelectedIcon("");
            setIconStyle([]);
            setContainerName("");
        }
    }, [project.status]);

    useEffect(() => {
        if (props.params) {
            let addContainerContext = { id: 0, x: 0, y: 0, width: 0, height: 0 };
            if ((addContainerContext && addContainerContext.id !== props.params.id) || !addContainerContext) {

                //let menuWidth = window.outerWidth * .5;
                //let menuHeight = window.outerHeight / 5;
                //let menuXStart = window.outerWidth * .25;
                //let menuYStart = 100; 

                /*if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }*/
                
                addContainerContext.id = props.params.id;
                //addContainerContext.x = menuXStart;
                //addContainerContext.y = menuYStart;
                //addContainerContext.width = menuWidth;
                //addContainerContext.width = menuHeight;
            }
            setAddContainerContext(addContainerContext);
        } else {
            setAddContainerContext(undefined);
        }
        loadIcons();
        if (props.params?.editing) {
            const containerBeingEdited = project.containers.find(c => c.id === props.params?.id);
            if (containerBeingEdited && icons) {
                setContainerName(containerBeingEdited.name);
                let iconIndex = icons.findIndex(i => i.name === containerBeingEdited.icon);
                if (iconIndex !== -1) {
                    toggleIconSelection(containerBeingEdited.icon, iconIndex);
                } else {
                    /* in case no icon */
                    setSelectedIcon("");
                    setIconStyle([]);
                }
            }
        }
    }, [props.params]);


    const loadIcons = () => {
        let icons: Array<IIcon> = [];
        let color = "#fff";
        let size = "25";
        icons[0] = { type: FaRegSmile, name: "FaRegSmile", size: size, color: color };
        icons[1] = { type: FaList, name: "FaList", size: size, color: color };
        icons[2] = { type: FaAddressBook, name: "FaAddressBook", size: size, color: color };
        icons[3] = { type: FaAngellist, name: "FaAngellist", size: size, color: color };
        icons[4] = { type: FaArchive, name: "FaArchive", size: size, color: color };
        icons[5] = { type: FaAudible, name: "FaAudible", size: size, color: color };
        icons[6] = { type: FaBabyCarriage, name: "FaBabyCarriage", size: size, color: color };
        icons[7] = { type: FaBaseballBall, name: "FaBaseballBall", size: size, color: color };
        icons[8] = { type: FaBasketballBall, name: "FaBasketballBall", size: size, color: color };
        icons[9] = { type: FaBeer, size: size, name: "FaBeer", color: color };
        icons[10] = { type: FaBed, size: size, name: "FaBed,", color: color };
        icons[11] = { type: FaBinoculars, name: "FaBinoculars", size: size, color: color };
        icons[12] = { type: FaBiking, name: "FaBiking", size: size, color: color };
        icons[13] = { type: FaBlackTie, name: "FaBlackTie", size: size, color: color };
        icons[14] = { type: FaBuffer, name: "FaBuffer", size: size, color: color };
        icons[15] = { type: FaBurn, name: "FaBurn", size: size, color: color };
        icons[16] = { type: FaCalendar, name: "FaCalendar", size: size, color: color };
        icons[17] = { type: FaCamera, name: "FaCamera", size: size, color: color };
        icons[18] = { type: FaCar, name: "FaCar", size: size, color: color };
        icons[19] = { type: FaCarrot, name: "FaCarrot", size: size, color: color };
        icons[20] = { type: FaCat, name: "FaCat", size: size, color: color };
        icons[21] = { type: FaCertificate, name: "FaCertificate", size: size, color: color };
        icons[22] = { type: FaChartArea, name: "FaChartArea", size: size, color: color };
        icons[23] = { type: FaChartLine, name: "FaChartLine", size: size, color: color };
        icons[24] = { type: FaChess, name: "FaChess", size: size, color: color };
        icons[25] = { type: FaCheese, name: "FaCheese", size: size, color: color };
        icons[26] = { type: FaCheck, name: "FaCheck", size: size, color: color };
        icons[27] = { type: FaChild, name: "FaChild", size: size, color: color };
        icons[28] = { type: FaChurch, name: "FaChurch", size: size, color: color };
        icons[29] = { type: FaCity, name: "FaCity", size: size, color: color };
        icons[30] = { type: FaClock, name: "FaClock", size: size, color: color };
        icons[31] = { type: FaClipboard, name: "FaClipboard", size: size, color: color };
        icons[32] = { type: FaCloud, name: "FaCloud", size: size, color: color };
        icons[33] = { type: FaCloudRain, name: "FaCloudRain", size: size, color: color };
        icons[34] = { type: FaCloudSun, name: "FaCloudSun", size: size, color: color };
        icons[35] = { type: FaCocktail, name: "FaCocktail", size: size, color: color };
        icons[36] = { type: FaCode, size: size, name: "FaCode", color: color };
        icons[37] = { type: FaCodeBranch, size: size, name: "FaCodeBranch", color: color };
        icons[38] = { type: FaCoffee, size: size, name: "FaCoffee", color: color };
        icons[39] = { type: FaCog, size: size, name: "FaCog", color: color };
        icons[40] = { type: FaCoins, size: size, name: "FaCoins", color: color };
        icons[41] = { type: FaComment, size: size, name: "FaComment", color: color };
        icons[42] = { type: FaCompactDisc, size: size, name: "FaCompactDisc", color: color };
        icons[43] = { type: FaCompass, size: size, name: "FaCompass", color: color };
        icons[44] = { type: FaConciergeBell, size: size, name: "FaConciergeBell", color: color };
        icons[45] = { type: FaConciergeBell, size: size, name: "FaBlog", color: color };
        //icons[45] = { type: IoMdSchool, size: "30", name: "IoMdSchool", color: color };
        icons[46] = { type: FaCookieBite, size: size, name: "FaCookieBite", color: color };
        icons[47] = { type: FaCreditCard, size: size, name: "FaCreditCard", color: color };
        icons[48] = { type: FaCrosshairs, size: size, name: "FaCrosshairs", color: color };
        icons[49] = { type: FaCrow, size: size, name: "FaCrow", color: color };
        icons[50] = { type: FaCrown, size: size, name: "FaCrown", color: color };
        icons[51] = { type: FaCube, size: size, name: "FaCube", color: color };
        icons[52] = { type: FaCut, size: size, name: "FaCut", color: color };
        icons[53] = { type: FaDatabase, size: size, name: "FaDatabase", color: color };
        icons[54] = { type: FaDeezer, size: size, name: "FaDeezer", color: color };
        icons[55] = { type: FaDesktop, size: size, name: "FaDesktop", color: color };
        icons[56] = { type: FaDiceFive, size: size, name: "FaDiceFive", color: color };
        icons[57] = { type: FaDog, size: size, name: "FaDog", color: color };
        icons[58] = { type: FaDollarSign, size: size, name: "FaDollarSign", color: color };
        icons[59] = { type: FaDoorClosed, size: size, name: "FaDoorClosed", color: color };
        icons[60] = { type: FaDrum, size: size, name: "FaDrum", color: color };
        icons[61] = { type: FaDrumstickBite, size: size, name: "FaDrumstickBite", color: color };
        icons[62] = { type: FaEgg, size: size, name: "FaEgg", color: color };
        icons[63] = { type: FaEnvelope, size: size, name: "FaEnvelope", color: color };
        icons[64] = { type: FaEye, size: size, name: "FaEye", color: color };
        icons[65] = { type: FaFaucet, size: size, name: "FaFaucet", color: color };
        icons[66] = { type: FaFeather, size: size, name: "FaFeather", color: color };
        icons[67] = { type: FaFighterJet, size: size, name: "FaFighterJet", color: color };
        icons[68] = { type: FaFish, size: size, name: "FaFish", color: color };
        icons[69] = { type: FaFlask, size: size, name: "FaFlask", color: color };
        icons[70] = { type: FaFolder, size: size, name: "FaFolder", color: color };
        icons[71] = { type: FaFootballBall, size: size, name: "FaFootballBall", color: color };
        icons[72] = { type: FaFutbol, size: size, name: "FaFutbol", color: color };
        icons[73] = { type: FaGamepad, size: size, name: "FaGamepad", color: color };
        icons[74] = { type: FaHammer, size: size, name: "FaHammer", color: color };
        icons[75] = { type: FaGuitar, size: size, name: "FaGuitar", color: color };
        icons[76] = { type: FaHamburger, size: size, name: "FaHamburger", color: color };
        icons[77] = { type: FaHeadphones, size: size, name: "FaHeadphones", color: color };
        icons[78] = { type: FaHiking, size: size, name: "FaHiking", color: color };
        icons[79] = { type: FaHospitalAlt, size: size, name: "FaHospitalAlt", color: color };
        icons[80] = { type: FaLaptop, size: size, name: "FaLaptop", color: color };
        icons[81] = { type: FaMap, size: size, name: "FaMap", color: color };
        icons[82] = { type: FaMapMarker, size: size, name: "FaMapMarker", color: color };
        icons[83] = { type: FaMarker, size: size, name: "FaMarker", color: color };
        icons[84] = { type: FaMicrophone, size: size, name: "FaMicrophone", color: color };
        icons[85] = { type: FaMobile, size: size, name: "FaMobile", color: color };
        icons[86] = { type: FaMugHot, size: size, name: "FaMugHot", color: color };
        icons[87] = { type: FaPallet, size: size, name: "FaPallet", color: color };
        icons[88] = { type: FaPaw, size: size,  name: "FaPaw", color: color };
        icons[89] = { type: FaPills, size: size, name: "FaPills", color: color };
        icons[90] = { type: FaPiggyBank, size: size, name: "FaPiggyBank", color: color };
        icons[91] = { type: FaPizzaSlice, size: size, name: "FaPizzaSlice", color: color };
        icons[92] = { type: FaSocks, size: size, name: "FaSocks", color: color };
        icons[93] = { type: FaStamp, size: size, name: "FaStamp", color: color };
        icons[94] = { type: FaShoppingCart, size: size, name: "FaShoppingCart", color: color };
        icons[95] = { type: FaStore, size: size, name: "FaStore", color: color };
        icons[96] = { type: FaTruck, size: size, name: "FaTruck", color: color };
        icons[97] = { type: FaWrench, size: size, name: "FaWrench", color: color };

        setIcons(icons);
    }

    const toggleIconSelection = (name: string, index: number) => {
        let orangeHighlight: React.CSSProperties = {
            backgroundColor: "#BA4A00",
            opacity: 1
        }
        let styles = [...iconStyle];
        styles[index] = (styles[index] !== undefined) ? undefined : orangeHighlight;

        for (let i = 0; i < styles.length; i++) {
            if ( i !== index ) {
                styles[i] = undefined;
            }
        }
        //styles[index] = (styles[index] !== undefined) ? undefined : orangeHighlight;

        setIconStyle(styles);
        setSelectedIcon(name);        
    }

    const closeAddContainerContext = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedIcon("");
        setIconStyle([]);
        setContainerName("");
        let context = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        setAddContainerContext(context);
    }

    const handleContainerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setContainerName(e.currentTarget.value);
    }

    const createContainer = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        //console.log(containerName);
        //console.log(selectedIcon);
        //alert("Container created!");
        dispatch(addContainer({ name: containerName, icon: selectedIcon }));
    }

    const editContainer = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (props.params) {
            dispatch(saveContainer({ id: props.params.id, name: containerName, icon: selectedIcon }));
        }
    }

    

/*#BA4A00
    const sAddContainerContext: React.CSSProperties = {
        top: (addContainerContext) ? addContainerContext.y : 0,
        left: (addContainerContext) ? addContainerContext.x : 0,
        width: (addContainerContext) ? addContainerContext.width : 0,
    }
*/

    let formattedIcons = icons?.map((icon, index) => {
        let element = createElement(
            icon.type,
            {
                size: icon.size,
                color: icon.color
            }
        )
        return (
            <div className="addContainerIcon" style={iconStyle[index]} 
                onClick={e =>  toggleIconSelection(icon.name, index)}>
                {element}
            </div>
        );
    });

    return (
        <>
        {addContainerContext && addContainerContext.id > 0 &&
            <div onClick={e => closeAddContainerContext(e)} className="popupContainer">
                <div className="addContainerPopup" onClick={e => { e.preventDefault(); e.stopPropagation()}}>  
                    {/*      
                    <div style={{ float: "right", cursor: "pointer" }} onClick={(e) => props.params?.closeHandler(e)}>
                        <AiOutlineClose color="#555" size="13" />
                    </div>
                    */}
                    <div>
                        <input value={containerName} onChange={e => handleContainerNameChange(e)}
                            className="addContainerInput" type="text" placeholder="Name" /> 
                    </div>
                    <div style={{ marginBottom: "10px", border: "1px solid #efefef", borderRadius: "5px" }}>
                        <div style={{ padding: "10px", color: "gray" }}>
                            Optionaly, choose an icon for your container
                        </div> 
                        <div style={{ padding: "0px 10px 10px 10px" }}>
                            {formattedIcons}
                        </div>
                    </div>
                    <div className="btn-orange" 
                        style={{ width: "200px", textAlign: "center" }}
                        onClick={e => {
                            if (props.params?.editing) {
                                editContainer(e);
                            } else {
                                createContainer(e);
                            }
                        }}    
                    >
                        {props.params?.editing ?
                            <>Save Container</>
                        :
                            <>Create Container</>
                        }
                    </div>
                </div>
            </div>
        }
        </>
    );
}

export default AddContainerContextMenu;