import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import userReducer from '../Store/Auth';
import projectReducer from '../Store/Project';
import settingsReducer from '../Store/Settings';

const combinedReducer = combineReducers({
  user: userReducer,
  project: projectReducer,
  settings: settingsReducer,
});

const rootReducer = (state: any, action: any) => {
  //console.log(action.type);
  if (action.type === 'api/auth/logout/fulfilled') {
    state = {};
  }
  return combinedReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer
});

/*
export const store = configureStore({
  reducer: {
    user: userReducer,
    project: projectReducer,
    settings: settingsReducer,
  },
});
*/
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
