import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { 
    getProject,
    setCards,
    saveCard
} from '../Store/Project';
import { ContextMenu } from '../Models/ContextMenu';
import { MoveCardContextMenuParam } from'../Models/Requests/MoveCardContextMenuParam';
import { AiOutlineClose } from "react-icons/ai";
import { Card } from '../Models/Card';

interface Props {
    params: MoveCardContextMenuParam | undefined;    
}

function MoveCardContextMenu(props: Props) {

    const dispatch = useAppDispatch();
    const projectState = useAppSelector(getProject);
    const [moveCardContextMenu, setMoveCardContextMenu] = useState<ContextMenu>();
    const [whereToMove, setWhereToMove] = useState<string>("");
    const [selectedListId, setSelectedListId] = useState<number>(0);

    useEffect(() => {
        if (props.params) {
            setWhereToMove("");
            setSelectedListId(0);
            let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
            if ((moveCardContextMenu && moveCardContextMenu.id !== props.params.cardId) || !moveCardContextMenu) {

                let menuWidth = window.outerWidth / 5;
                let menuHeight = 205; //window.outerHeight * .4;
                let menuXStart = props.params.event.clientX - menuWidth - 80;
                let menuYStart = 10;
                if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }
                
                contextMenu.id = props.params.cardId;
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                contextMenu.width = menuWidth;
                contextMenu.height = menuHeight;
            }
            setMoveCardContextMenu(contextMenu);
        }
    }, [props.params]);


    const closeContextMenu = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        setMoveCardContextMenu(contextMenu);
    }

    const handleMoveCard = (e: React.MouseEvent<HTMLAnchorElement>) => {

        e.preventDefault();
        e.stopPropagation();

        if (selectedListId !== 0 && whereToMove !== "") {

            let card = projectState.cards.find(c => c.id === props.params?.cardId);
            if (card !== undefined) {

                let cardId = card.id;
                let newListId = selectedListId;
                let oldIndex = card.index;
                let oldListId = card.listId;
                //let list = projectState.lists.find(l => l.id === newListId);

                let newIndex = 0; /* move to Top */

                if (whereToMove === "bottom") { /* move to bottom */
                    let lastCardIndexInList = projectState.cards.reduce((c, v) => {
                        return (c = c > v.index ? c : v.index);
                    }, 0);
                    newIndex = lastCardIndexInList + 1;
                }

                let cardIdsToUpdate: Array<number> = [];

                let cardsInOldList: Array<Card> = [];
                let cardsInNewList: Array<Card> = [];
                let cardsToUpdate: Array<Card> = [];

                /* Reshuffle the cards in the list the card is being dragged into */
                cardsInNewList = projectState.cards.filter(c => c.listId === newListId).sort(({ index :a }, { index :b }) => a-b );
                if (card) {
                    if (oldListId === newListId) {
                        cardsInNewList.splice(oldIndex, 1);
                    }
                    cardsInNewList.splice(newIndex, 0, card);
                    let sortedCardsInList = cardsInNewList.map((c, i) => {
                        return {...c, index: i, listId: newListId };
                    });

                    sortedCardsInList.forEach( c => {
                        cardsToUpdate.push(c);
                    }); 
                }

                if (oldListId !== newListId) {
                    cardsInOldList = projectState.cards.filter(c => c.listId === oldListId).sort(({ index :a }, { index :b }) => a-b );
                    if (card) {
                        cardsInOldList.splice(oldIndex, 1);
                        let sortedCardsInList = cardsInOldList.map((c, i) => {
                            return {...c, index: i };
                        });
                        sortedCardsInList.forEach( c => {
                            cardsToUpdate.push(c);
                        });
                    }
                }

                /* Update frontend */
                let cards = projectState.cards.map(card => {
                    let cardUpdated = cardsToUpdate.find(c => c.id === card.id);
                    if (cardUpdated) {
                        return cardUpdated;
                    }
                    return card;
                })
                cards.sort(({ index :a }, { index :b }) => a-b );
                dispatch(setCards(cards)); // update frontend

                /* update the backend */
                cardsToUpdate.forEach(card => {
                    dispatch(saveCard(card));
                }); 
                closeContextMenu(e);
            }
        }
    };

    const selectListId = (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedListId(parseInt(e.currentTarget.value));
    }

    const sContextMenu: React.CSSProperties = {
        top: (moveCardContextMenu) ? moveCardContextMenu.y : 0,
        left: (moveCardContextMenu) ? moveCardContextMenu.x : 0,
        width: (moveCardContextMenu) ? moveCardContextMenu.width : 0,
        height: (moveCardContextMenu) ? moveCardContextMenu.height : 0,
    }

    let bottomStyle = {
        border: (whereToMove === "bottom") ? "1px solid #BA4A00" : "1px solid #BFC9CA"
    }

    let topStyle = {
        border: (whereToMove === "top") ? "1px solid #BA4A00" : "1px solid #BFC9CA"
    }

    let currentCard = projectState.cards.find(c => c.id === props?.params?.cardId);
    let currentList = projectState.lists.find(l => l.id === currentCard?.listId);

    return (
        <>
            {moveCardContextMenu && moveCardContextMenu.id > 0 &&
                <div onClick={e => closeContextMenu(e)} className="popupContainer">
                    <div className="contextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation()}}>
                        <h1 style={{ marginBottom: "0px" }}>Move Card</h1>
                        <div className="divClose">
                            <Link to="" onClick={(e) => closeContextMenu(e)}>
                                <AiOutlineClose color="#555" size="13" />
                            </Link>
                        </div>
                        <h2 className="mt-2" style={{ fontSize: "12pt" }}>
                            To List
                        </h2>
                        <div>
                            <select className="moveCardList" value={selectedListId} onChange={(e) =>selectListId(e)}>
                                <option key={0} value={0}>-- select list --</option>
                                {projectState.lists.filter(l => l.id !== currentList?.id).map(l => {
                                    return (
                                        <option key={l.id} value={l.id}>{l.name}</option>
                                    );
                                })}
                            </select>
                        </div>
                        <div style={topStyle} className="moveCardToTopButton" onClick={() => setWhereToMove("top")}>
                           At Top
                        </div>
                        <div style={bottomStyle} className="moveCardToBottomButton" onClick={() => setWhereToMove("bottom")}>
                           At Bottom
                        </div>
                        <div style={{ marginTop: "12px" }}>
                            <Link to="" onClick={(e) => handleMoveCard(e)} className="btn-orange">
                                Move
                            </Link>
                        </div> 
                    </div>
                </div>
            }
        </>
    );
}

export default MoveCardContextMenu;