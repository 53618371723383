import React, { ReactText } from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
//detachLabelFromCard, setLabelsToCards
import { 
    getProject,
    saveLabel,
    updateLabel,
    setLabels,
    attachLabelToCard,
    detachLabelFromCard,
    setLabelsToCards,
    deleteLabel
} from '../Store/Project';
import { ContextMenu } from '../Models/ContextMenu';
import { LabelContextMenuParam } from'../Models/Requests/LabelContextMenuParam';
import { AiOutlineClose } from "react-icons/ai";
import { Card } from '../Models/Card';
import { Label, EmptyLabel } from '../Models/Label';
import { IColor, colors } from '../Models/Requests/Colors';
import { FaRegEdit } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { LabelToCard } from '../Models/LabelToCard';
import PopupContextMenu from './PopupContext';
import { PopupContextParam } from '../Models/Requests/PopupContextParam';

interface Props {
    params: LabelContextMenuParam | undefined;    
}

function LabelContextMenu(props: Props) {

    const C_COLOR = "#efefef";
    const C_FONT  = "#000";
    const C_TITLE = "";

    const dispatch = useAppDispatch();
    const projectState = useAppSelector(getProject);
    const [labelContextMenu, setLabelContextMenu] = useState<ContextMenu>();
    const [popupParams, setPopupParams] = useState<PopupContextParam>();
    const [newLabelWindow, setNewLabelWindow] = useState<boolean>(false);
    const [color, setColor] = useState<string>(C_COLOR);
    const [font, setFont] = useState<string>(C_FONT);
    const [title, setTitle] = useState<string>(C_TITLE);
    const [labelBeingEdited, setLabelBeingEdited] = useState<number>(0);
    const [labelSelected, setLabelSelected] = useState<Array<boolean>>([]);
    const [searchText, setSearchText] = useState<string>("");
    //const [visible, setVisible] = useState<boolean>(false);

    useEffect(() => {
        if (props.params) {
            let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
            if ((labelContextMenu && labelContextMenu.id !== props.params.cardId) || !labelContextMenu) {

                //let menuWidth = window.outerWidth / 5;
                let menuWidth = 300;
                //let menuHeight = window.outerHeight * .7;

                let numberOfLabels = projectState.labels.length;
                //let menuHeight = 145 + 50 * numberOfLabels;

                let menuXStart = props.params.event.clientX - menuWidth - 80;
                let menuYStart = 10;
                //alert(menuXStart + " : " + menuWidth + " : " + window.outerWidth);
                if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }
                
                contextMenu.id = props.params.cardId;
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                contextMenu.width = menuWidth;
                //contextMenu.height = menuHeight;
            }
            setLabelContextMenu(contextMenu);
            refreshLabelSelections();

        }
    }, [props.params]);

    useEffect(() => {
        if (projectState.status === "labelAdded") {
            handleAttachLabelToCard();
        }
    }, [projectState.labels]);

    useEffect(() => {
        if (projectState.status === "labelAttachedToCard") {
            refreshLabelSelections();
        }
    }, [projectState.labelsToCards]);

    const refreshLabelSelections = () => {
        /* Set checkboxes on labels */
        let selectedLabels: Array<boolean> = [];
        projectState.labelsToCards.forEach(lc => {
            if (lc.cardId === props.params?.cardId) {
                selectedLabels[lc.labelId] = true;
            }
        })
        setLabelSelected(selectedLabels);
    }

    const closeContextMenu = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        setLabelContextMenu(contextMenu);
        setNewLabelWindow(false);
        resetSelection();
    }

    const showNewLabelWindow = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setNewLabelWindow(true);
    }

    const selectColor = (e: React.MouseEvent<HTMLDivElement>, color: string, font: string, index: number) => {
        e.preventDefault();
        e.stopPropagation();
        setColor(color);
        setFont(font);
    } 

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setTitle(e.currentTarget.value);
    }

    const handleSaveLabel = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        
        let label = EmptyLabel;
        let project = projectState.projects.find(p => p.loaded);
        label.projectId = (project) ? project.id : 0;
        label.title = title;
        label.fontColor = font;
        label.backgroundColor = color;
        
        dispatch(saveLabel(label));
        resetSelection();
        setNewLabelWindow(false);
/*
        let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        if (labelContextMenu) {
            contextMenu.id = labelContextMenu.id;
            contextMenu.x = labelContextMenu.x;
            contextMenu.y = labelContextMenu.y;
            contextMenu.width = labelContextMenu.width;
            contextMenu.height = labelContextMenu.height + 50;
            setLabelContextMenu(contextMenu);
        }
        */
        //attachLabelToCard(e);
    }

    const handleAttachLabelToCard = (e?: React.MouseEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>, id?: number) => {
        /* This will come in with no e and no Id if a new label selected. If an
           exsting label is sselected, it will come in with both. */  
        if(e){
            e.preventDefault();
            e.stopPropagation();

        }

        if (id && labelSelected[id] === true) {
            //alert("Dettach");
            /* detaching the label */
            detachLabel(props.params?.cardId, id);
        } else {
            //alert("Attach");
            /* attaching a label */
            if (props.params?.cardId) {

                let cardId = props.params.cardId;

                let labelId = 0;
                if (id) {
                    let labelAlreadyExists = projectState.labelsToCards.find(l => l.labelId === id && l.cardId === cardId);
                    if (!labelAlreadyExists) {
                        labelId = id;
                    }
                } else {
                    let newestLabel = projectState.labels.reduce((a, b) => a.id > b.id ? a : b);
                    //console.log(newestLabel);
                    if (newestLabel) {
                        labelId = newestLabel.id;
                    }
                    //result = array.reduce((a, b) => a.date > b.date ? a : b);
                }

                if (labelId > 0) {
                    dispatch(attachLabelToCard({
                        id: 0,
                        labelId: labelId,
                        cardId: cardId,
                        created: new Date()
                    }));
                }
            }
        }
    }

    const detachLabel = (cardId: number | undefined, labelId: number) => {
        //alert("detach label");
        if (cardId !== undefined) {
            /* remove from frontend */
            
            /* uncheck it */
            let attachedLabels = [...labelSelected];
            attachedLabels[labelId] = false;
            setLabelSelected(attachedLabels);

            /* remove it from labelsToCards collection */
            let labelsToCards = [...projectState.labelsToCards];
            let index = labelsToCards.findIndex(lc => lc.labelId === labelId && lc.cardId === cardId);
            if (index > -1) {
                labelsToCards.splice(index, 1);
                dispatch(setLabelsToCards(labelsToCards));
            }

            /*
            let updatedLabelsToCards = labelsToCards.map((lc, index) => {
                if (lc.labelId === labelId && lc.cardId === cardId) {
                    let updatedLabel = { ...label, title: title, fontColor: font, backgroundColor: color };
                    dispatch(updateLabel(updatedLabel));
                    return (updatedLabel);
                } else {
                    return label
                }
            });
*/
            /* remove from backend */
            dispatch(detachLabelFromCard({
                id: 0,
                labelId: labelId,
                cardId: cardId,
                created: new Date()
            }));
        }        
    }

    const handleUpdateLabel = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        
        let labels = [...projectState.labels];
        //let labelBeingEditedIndex = labels.findIndex(l => l.id === labelBeingEdited);
        let updatedLabels = labels.map((label, index) => {
            if (label.id === labelBeingEdited) {
                let updatedLabel = { ...label, title: title, fontColor: font, backgroundColor: color };
                dispatch(updateLabel(updatedLabel));
                return (updatedLabel);
            } else {
                return label
            }
        });

        dispatch(setLabels(updatedLabels));

        resetSelection();
        setNewLabelWindow(false);
    }

    const closeDeleteLabelPopup = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        /* Key here is to zero-out label */
        setPopupParams({
            id: 0,
            event: e,
            buttonClass: "",
            buttonText: "",
            closeHandler: closeDeleteLabelPopup,
            buttonHandler: deleteLabelConfirmed,
            message: "",
            headingText: "",
            backButton: true,
        });
    }

    const deleteLabelConfirmed = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(deleteLabel(labelBeingEdited));

        /* Remove label from master list */
        let labels = [...projectState.labels];
        let removedLabelIndex = labels.findIndex(l => l.id === labelBeingEdited);
        if (removedLabelIndex !== -1) {
            labels.splice(removedLabelIndex, 1);
            dispatch(setLabels(labels));
            /* Remove label from LabelsToCards */
            let labelsToCards = [...projectState.labelsToCards];
            let updatedLabelsToCards = labelsToCards.filter(lc => {
                return lc.labelId !== labelBeingEdited;
            })
            dispatch(setLabelsToCards(updatedLabelsToCards));
            setLabelBeingEdited(0);
            setNewLabelWindow(false);
/*
            let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
            if (labelContextMenu) {
                contextMenu.id = labelContextMenu.id;
                contextMenu.x = labelContextMenu.x;
                contextMenu.y = labelContextMenu.y;
                contextMenu.width = labelContextMenu.width;
                contextMenu.height = labelContextMenu.height - 50;
                setLabelContextMenu(contextMenu);
            }
            */
        }
        closeDeleteLabelPopup(e);
    }

    const handleDeleteLabel = (e: React.MouseEvent<HTMLAnchorElement>, labelId: number) => {
        e.preventDefault();
        e.stopPropagation();

        /* Show the delete confirmation window */
        setPopupParams({
            id: labelId,
            event: e,
            buttonClass: "btnDelete",
            buttonText: "Delete",
            closeHandler: closeDeleteLabelPopup,
            buttonHandler: deleteLabelConfirmed,
            message: "This will remove this label from all cards. There is no undo.",
            headingText: "Delete Label",
            backButton: true,
        });

        /*
        let label = EmptyLabel;
        let project = projectState.projects.find(p => p.loaded);
        label.projectId = (project) ? project.id : 0;
        label.title = title;
        label.fontColor = font;
        label.backgroundColor = color;
        
        dispatch(saveLabel(label));
        
        resetSelection();
        setNewLabelWindow(false);
        */
    }

    const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        e.preventDefault();
        e.stopPropagation();
        let labelSelections = [...labelSelected];

        if (labelSelections[index] !== true) {
            handleAttachLabelToCard(e, index);
        }

        labelSelections[index] = (labelSelections[index]) ? false : true;
        setLabelSelected(labelSelections);

        if (labelSelections[index] !== true) {
            //alert("detach");
            detachLabel(props.params?.cardId, index); 
        }

    };

    const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setSearchText(e.currentTarget.value);
    }

    const resetSelection = () => {
        setColor(C_COLOR);
        setFont(C_FONT);
        setTitle(C_TITLE);
        setLabelBeingEdited(0);
    }

    const resetColor = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setColor(C_COLOR);
        setFont(C_FONT);
    }

    const editingLabel = (e: React.MouseEvent<HTMLAnchorElement>, label: Label) => {
        e.preventDefault();
        e.stopPropagation();
        setLabelBeingEdited(label.id);
        setColor(label.backgroundColor);
        setFont(label.fontColor);
        setTitle(label.title);
        setNewLabelWindow(true);
    }

    const sContextMenu: React.CSSProperties = {
        top: (labelContextMenu) ? labelContextMenu.y : 0,
        left: (labelContextMenu) ? labelContextMenu.x : 0,
        width: (labelContextMenu) ? labelContextMenu.width : 0,
        height: "auto",
        paddingBottom: "15px",
        //height: (labelContextMenu) ? labelContextMenu.height : 0,
    }

    let sortedColors = colors.sort(({ sort :a }, { sort :b }) => a-b );
    let mappedColors = sortedColors.map((c, i) => {

        const style= {
            backgroundColor: c.hex,
            border: (color === c.hex) ? "2px solid #fff" : "none",
            outline: (color === c.hex) ? "2px solid #2874A6" : "none",
            color: c.font,
        }

        if (i % 4 === 0) {
            return (
                <>
                    <div className="colorContainer"></div>
                    <div className="col1" onClick={(e) => selectColor(e, c.hex, c.font, i)} style={style}></div>
                </>
            );
        } else {
            return (<div className="col2-4" onClick={(e) => selectColor(e, c.hex, c.font, i)} style={style}></div>);
        }
    });
    
    let labels = (searchText === "") ? projectState.labels : projectState.labels.filter(l => l.title.toLowerCase().includes(searchText.toLowerCase()));
    //console.log(sortedColor)

    return (
        <>
            {labelContextMenu && labelContextMenu.id > 0 &&
                <div onClick={e => closeContextMenu(e)} className="popupContainer">
                    <div className="contextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation()}}>
                        <>
                        {newLabelWindow ?
                            <>
                                {labelBeingEdited === 0 ? <h1>Create Label</h1> : <h1>Edit Label</h1>}
                                {newLabelWindow && 
                                    <div className="divBack">
                                        <Link to="" onClick={(e) => { setNewLabelWindow(false);resetSelection() } }>
                                            <IoIosArrowBack color="#555" size="13" />
                                        </Link>
                                    </div>
                                }
                                <div className="divClose">
                                    <Link to="" onClick={(e) => closeContextMenu(e)}>
                                        <AiOutlineClose color="#555" size="13" />
                                    </Link>
                                </div>
                                <div className="inputLabel">
                                    Title
                                </div>
                                <div>
                                    <input className="input" type="text" value={title} onChange={(e) => handleTitleChange(e)} />
                                </div>
                                <div className="inputLabel">
                                    Select a color
                                </div>
                                {mappedColors} 
                                <div style={{clear: "both", }}></div>
                                {color !== "#efefef" &&
                                    <div className="divCardOption mt-2 text-center" onClick={(e) => resetColor(e)}>
                                        <span className="label">
                                            <MdClose /> Remove color
                                        </span>
                                    </div>
                                }
                                {labelBeingEdited === 0 ?
                                    <div style={{ marginTop: "10px" }}>
                                        <Link to="" onClick={(e) => handleSaveLabel(e)} className="btn-orange">
                                            Create
                                        </Link>  
                                    </div> 
                                :
                                    <div>
                                        <hr />
                                        <div style={{ float: "left" }}>
                                            <Link to="" onClick={(e) => handleUpdateLabel(e)} className="btn-orange">
                                                Save
                                            </Link>  
                                        </div> 
                                        <div style={{ float: "right" }}>
                                            <Link to="" onClick={(e) => handleDeleteLabel(e, labelBeingEdited)} className="btn-black">
                                                Delete
                                            </Link>  
                                        </div> 
                                        <div style={{ clear: "both"}}></div>
                                    </div>
                                }
                            </>
                        :
                            <>
                                <h1 style={{ marginBottom: "0px" }}>Labels</h1>
                                <div className="divClose">
                                    <Link to="" onClick={(e) => closeContextMenu(e)}>
                                        <AiOutlineClose color="#555" size="13" />
                                    </Link>
                                </div>
                                <div>
                                    <input className="input" type="text" placeholder="Search labels..." 
                                        autoFocus
                                        onChange={(e) => handleSearchText(e)}
                                        value={searchText}
                                    />
                                </div>
                                {labels.map((l, i) => {
                                    let style = {
                                        backgroundColor: l.backgroundColor,
                                        color: l.fontColor,
                                        borderRadius: "2px",
                                        cursor: "pointer",
                                    }

                                    let checked = (labelSelected[l.id]) ? true : false;
                                    
                                    return (
                                        <>
                                            {i === 0 && <h1 className="mt-2 mb-2">Existing</h1>}
                                            <div className="row mt-1 g-0" key={i}>
                                                <div className="col-2 text-center">
                                                    <input type="checkbox"
                                                        key={Math.random()}
                                                        checked={checked}
                                                        onChange={(e) => handleLabelChange(e, l.id)}
                                                        style={{
                                                            position: "relative", top:"3px", border: "1px solid #efefef" 
                                                        }} 
                                                    />
                                                </div>
                                                <div className="divColorLabel col-8" style={style}
                                                    onClick={(e) => handleAttachLabelToCard(e, l.id)}
                                                >
                                                    <span className="label" style={{ cursor: "pointer"}}>
                                                        {l.title}
                                                    </span>
                                                </div>
                                                <div className="col-2 text-center">
                                                    <Link to="" onClick={(e) => editingLabel(e, l)}>
                                                        <FaRegEdit color="#5D6D7E" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                                <div className="divCardOption mt-2 text-center" onClick={(e) => showNewLabelWindow(e)}>
                                    <span className="label" style={{ cursor: "pointer" }}>
                                        Create a new label
                                    </span>
                                </div>
                                {/*
                                <div className="divCardOption mt-2 text-center">
                                    <span className="label">
                                        Show more labels
                                    </span>
                                </div>
                                */}
                            </>
                        }
                        </>
                    </div>
                </div>
            }
            <PopupContextMenu params={popupParams} />
        </>
    );
}

export default LabelContextMenu;